////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React from "react";
import { Link } from "react-router-dom";
import { useRef } from 'react';
import { toast } from 'wc-toast'
import { checkAuthToken } from '../checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faTv, faCog, faExternalLinkAlt, faServer, faWrench, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';
import ToggleButton from 'terra-toggle-button';
import notify from "../notify.js"
import i18n from '../i18n.js';
import { TransverseLoading } from 'react-loadingg';
import { deleteContainer } from '../containerControll.js';
import { IntroBoxHeading, IntroBoxContent } from '../components/IntroBox.js'
import { ClockLoader } from 'react-spinners';
import { FaPlus, FaPlay, FaCheck } from "react-icons/fa";
import { FaX } from "react-icons/fa6";

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const sysgridVpnReverseproxyApiUrl = process.env.REACT_APP_sysgridVpnReverseproxyApiUrl || "http://localhost:9002/vpnReverseproxyApi"
const sysgridBindApiUrl = process.env.REACT_APP_sysgridBindApiUrl || "http://localhost:9002/bindApi"
const refreshInterval = process.env.REACT_APP_refreshDataInterval || 5000
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"
const t = i18n.t;

////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}

// const deleteContainer = async (Id, projectID, templateName) => {
//   const url = `${apiReverseproxyUrl}/containers/${Id}?force=true`
//
//   try {
//     const result = await fetch(url, {
//                                       method: 'DELETE', // or 'PUT'
//                                       headers: {
//                                           'Authorization': `${apiToken}`,
//                                           "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",                                          "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",                                          'Accept': 'application/json',
//                                           'Content-Type': 'application/json'
//                                       }
//                                   })
//     const logMessage = await result
//     console.log(logMessage)
//     notify("success", t("Container deleted"))
//   } catch (e) {
//     console.log(e)
//   }
// };

class AdminSecurityAide extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            logItems: [],
            dockerItems: [],
            runningChecks: [],
            DataisLoaded: true,
            apiError: null,
            rolloutStarted: false,
            setIntervalId: "",
            deleteDataStates: {},
            allSystems: []
        };
    }



    ////////////////////////////////////////////////////
    // COMPONENT DID AND WILL MOUNT
    ////////////////////////////////////////////////////
    componentDidMount() {
        checkAuthToken()
        // need to make the initial call to getData() to populate
        // data right away
        this.getData();
        // Now we need to make it run at a specified interval
        // var setIntervalId = setInterval(this.getData, refreshInterval); // runs every 5 seconds.
        // this.setState({
        //     setIntervalId: setIntervalId
        // })
    }

    getAllData = async () => {
        await this.getData();
    }

    componentWillUnmount() {
        clearInterval(this.state.setIntervalId)
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getData
    ////////////////////////////////////////////////////
	getData = async () => {
        await fetch(`${sysgridApiUrl}/security/aide/logs`, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((res) => {
            if ( res.status != "200") {
                throw res.statusText
            }
            else {
                return res.json()
            }
        })
        .then((array) => {
            //json to array for every object in object enVariables
            this.setState({
                logItems: array,
                DataisLoaded: true
            })
        })
        .catch( err => {
            this.setState({
                DataisLoaded: false,
                apiError: err.toString()
            })
        })
        this.setState({
                rolloutStarted: false,
                rolloutError: false,
        })
    }

    handleStartAideCheck = async(item) => {
        const projectFolderName=item.projectFolderName

        this.setState(prevState => ({
            runningChecks: {
              ...prevState.runningChecks, // Spread the previous state
              [projectFolderName]: { "running": true } // Add the new key-value pair
            }
          }));

        await fetch(`${sysgridApiUrl}/security/aide/startCheck`, {
            method: "POST",
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'projectFolderName': projectFolderName
            })
        })
        .then((res) => {
            if ( res.status != "200") {
                let status="error"

                this.setState(prevState => ({
                    runningChecks: {
                      ...prevState.runningChecks, // Spread the previous state
                      [projectFolderName]: { "running": status } // Add the new key-value pair
                    }
                  }));
            }
            else {
                return res.json()
            }
        })
        .then((json) => {
            let status = ""
            if(json.message){
                status="success"

                this.setState(prevState => ({
                    runningChecks: {
                      ...prevState.runningChecks, // Spread the previous state
                      [projectFolderName]: { "running": status } // Add the new key-value pair
                    }
                  }));
            }
            else {
                status="error"

                this.setState(prevState => ({
                    runningChecks: {
                      ...prevState.runningChecks, // Spread the previous state
                      [projectFolderName]: { "running": status } // Add the new key-value pair
                    }
                  }));
            }
        })
        .catch( err => {
            return(err)
        })
    }
    ////////////////////////////////////////////////////
    // RENDER COMPONENT
    ////////////////////////////////////////////////////
    render() {
        const { logItems, DataisLoaded, AllSystemDataIsLoaded, apiError, runningChecks } = this.state;
        const { t } = this.props

        if (!DataisLoaded && !AllSystemDataIsLoaded && (apiError !== null)) {
            return (
                <div>
                    <h1> {t("Error while retrieving data from API")}:</h1>
                    <h2>{apiError}</h2>
                </div>
            )
        }
        if (!DataisLoaded && !AllSystemDataIsLoaded && (apiError === null)) {
            return (
                <div className="loaderContainer">
                    <div className="loaderArea1">
                        <TransverseLoading />
                    </div>
                </div>
            )
        }

        return (
            <>
                <wc-toast></wc-toast>
                <IntroBoxHeading>
                    {t("Informations about this Menupoint")}
                </IntroBoxHeading>
                <IntroBoxContent>
                    {t("Here you can create configure Systemsettings and run special commands.")}
                </IntroBoxContent>

                <div className="panel"><h2 className={'headingLg'}><FontAwesomeIcon icon={faShieldAlt} />  {t("AIDE")}</h2></div>
                        {logItems.length > 0 && logItems.map((item) => (
                            <div className="securityAideContainer panel">
                                <div className="securityAideArea1">
                                    <span><strong>{t("Project ID")}:</strong><br/>{item.projectID}</span>
                                </div>
                                <div className="securityAideArea2">
                                    <span><strong>{t("Template Name")}:</strong><br/>{item.templateName}</span>
                                </div>              
                                <div className="securityAideArea3">
                                    <span><strong>{t("Project Folder Name")}:</strong><br/>{item.projectFolderName}</span>
                                </div> 
                                <div className="securityAideArea4">
                                    <span>
                                        <strong>{t("Last Check Status")}:</strong><br/>
                                        {item.lastCheck == item.lastFailedCheck ? ( <span><div className={`statusDotRed`}></div>{t("Failed")}</span> ) : <span><div className={`statusDotGreen`}></div>{t("Passed")}</span>}
                                    </span>
                                </div>      
                                <div className="securityAideArea5">
                                    <span>
                                        <button onClick={(e) => {e.preventDefault(); this.handleStartAideCheck(item)}} className="buttonGreen">{
                                            runningChecks[item.projectFolderName] && runningChecks[item.projectFolderName].running && runningChecks[item.projectFolderName].running == true ? 
                                                <ClockLoader
                                                color="#4bf624"
                                                size={20}
                                                />
                                            : 
                                            runningChecks[item.projectFolderName] && runningChecks[item.projectFolderName].running && runningChecks[item.projectFolderName].running == "success" ?
                                                <FaCheck/>
                                            : 
                                            runningChecks[item.projectFolderName] && runningChecks[item.projectFolderName].running && runningChecks[item.projectFolderName].running == "error" ?
                                                <FaX/>
                                            :
                                                <FaPlay/>
                                        }&nbsp;&nbsp;{t("Start Check")}</button>
                                    </span>
                                    <span>
                                    <Link to={`/admin/security/aide/logs/?projectFolderName=${item.projectFolderName}`}><button class="buttonBlue">{t("Show Logs")}</button></Link>
                                    </span>
                                </div>                                       
                            </div>
                        ))}
            </>
        );
    }
}

export default withTranslation("translations")(AdminSecurityAide);
