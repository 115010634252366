////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../Layout";
import { toast } from 'wc-toast'
import { checkAuthToken } from '../checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faPlayCircle, faCog, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';
import notify from "../notify.js"
import { stopContainer, startContainer, deleteContainer } from '../containerControll';
import { MeteorRainLoading } from 'react-loadingg';
import { checkRoles } from '../checkRoles.js'
import roles from "../config/roles.json"
import { IntroBoxHeading, IntroBoxContent } from '../components/IntroBox'

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/dockerApi"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const refreshInterval = process.env.REACT_APP_refreshDataInterval || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"


////////////////////////////////////////////////////
// DEFINE REACT COMPONENT
////////////////////////////////////////////////////
class AdminContainers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            DataisLoaded: false,
            apiError: null,
            setIntervalId: "",
            roleCheck: false
        };
    }

    ////////////////////////////////////////////////////
    // COMPONENT DID AND WILL MOUNT
    ////////////////////////////////////////////////////
    async componentDidMount() {
        checkAuthToken()
        // need to make the initial call to getData() to populate
        // data right away
        this.setState({
            roleCheck: await checkRoles(roles.admin.containers)
        })
        this.getData();
        // Now we need to make it run at a specified interval
            var setIntervalId = setInterval(this.getData, refreshInterval); // runs every 5 seconds.
            this.setState({
                setIntervalId: setIntervalId,
            })
    }

    componentWillUnmount() {
        clearInterval(this.state.setIntervalId)
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getData
    ////////////////////////////////////////////////////
    getData = async () => {
        await fetch(`${dockerApiUrl}/containers/json?all=true`, {
                  mode: 'cors',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `${apiToken}`,
                      "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                  }
                })
                            .then((res) => {
                if ( res.status != "200") {
                    throw res.statusText
                }
                else {
                    return res.json()
                }
            })
            .then((json) => {
                this.setState({
                    items: json,
                    DataisLoaded: true,
                    apiError: null
                })
            })
            .catch( err => {
                this.setState({
                    DataisLoaded: false,
                    apiError: err.toString()
                })
            })
    }


    ////////////////////////////////////////////////////
    // RENDER COMPONENT
    ////////////////////////////////////////////////////
    render() {
        const { items, DataisLoaded, apiError, roleCheck} = this.state;
        const { t } = this.props


        if (roleCheck == true) {
            if (!DataisLoaded && (apiError !== null)) {
                return (
                    <div>
                    <h1> {t("Error while retrieving data from API")}:</h1>
                    <h2>{apiError}</h2>
                    </div>
                )
            }

            if (!DataisLoaded && (apiError === null)) {
                return (
                    <div className="loaderContainer">
                    <div className="loaderArea1">
                    <MeteorRainLoading />
                    </div>
                    </div>
                )
            }
            return (
                <>
                    <wc-toast></wc-toast>
                    <IntroBoxHeading>
                        {t("Informations about this Menupoint")}
                    </IntroBoxHeading>
                    <IntroBoxContent>
                        {t("The Apps and Databases are running in Containers on the Host Server. Here you find an overview of all running Containers with there technical name, and you are able to Start or Stop them, aswell as delete them.")}
                    </IntroBoxContent>

                    <div className="panelRed">
                        <h2 className={'headingLg'}><FontAwesomeIcon icon={faDocker} />  {t("Containers")}</h2>
                    </div>
                        {items.map((item) => (
                            <>
                                    <div className="containersContainer panel">
                                        <div className="containersArea1">
                                            {(item.State.toLowerCase() === 'created' &&
                                                <div className={`statusDotRed`}></div>
                                            )}
                                            {(item.State.toLowerCase() === 'running' &&
                                                <div className={`statusDotGreen`}></div>
                                            )}
                                            {(item.State.toLowerCase() === 'restarting' &&
                                                <div className={`statusDotYellow`}></div>
                                            )}
                                            {(item.State.toLowerCase() === 'exited' &&
                                                <div className={`statusDotRed`}></div>
                                            )}
                                            {(item.State.toLowerCase() === 'paused' &&
                                                <div className={`statusDotYellow`}></div>
                                            )}
                                            {(item.State.toLowerCase() === 'dead' &&
                                                <div className={`statusDotBlack`}></div>
                                            )}
                                        </div>
                                        <div className="containersArea2">
                                                <Link to={`/container?containerID=${item.Id}`}><a><h3 className={`headingSmall title`}>{item.Names[0].substring(1)}</h3></a></Link>
                                        </div>
                                        <div className="containersArea3">
                                                <Link to={`/container?containerID=${item.Id}`}><a><h3 className={`headingSmall title`}>{item.State}</h3></a></Link>
                                        </div>
                                        <div className="containersArea4">
                                            <td><button className="buttonGreen" onClick={e => startContainer(item.Id)}>{t("Start")}</button></td>
                                            <td><button className="buttonRed" onClick={e => stopContainer(item.Id)}>{t("Stop")}</button></td>
                                            <td><button className="buttonRed"  onClick={e => deleteContainer(item.Id)}>{t("Delete")}</button></td>
                                        </div>
                                    </div>
                            </>
                        ))}
                </>

        );
    }
    else {
        return(
            <>
                <h1>No permission</h1>
            </>
        )
    }
}
}

export default withTranslation("translations")(AdminContainers);
