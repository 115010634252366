import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Tooltip = ({ text, children }) => {
const [isVisible, setIsVisible] = useState(false);

return (
  <>
    <FontAwesomeIcon icon={faInfoCircle} onClick={isVisible ? () => setIsVisible(false) : () => setIsVisible(true)}/>
    <div className={isVisible ? "" : "hide"}>
      <span>{children}</span>
    </div>
  </>
);
};

export default Tooltip;
