////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import  { useState, useEffect, useRef } from 'react';
import * as React from 'react';

import { sha256 } from 'js-sha256';
import { toast } from 'wc-toast'
import { useTranslation } from 'react-i18next';
import notify from './notify';
const platform = require('platform');
const os = require('os');

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const sha256Secret = process.env.REACT_APP_sha256Secret || "e41cc1c2d1a14b98e9987618db5cdda61247d7a3fe147828a6f8522bca155f6ee219056a6645394ac900877a3768bb4d4a5f9e13cedbf84f1a9ffb2fb0a0a0d8"
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"

const browser = platform.parse(navigator.userAgent); // Parse user-agent for browser information
let userDeviceMetadata = {
	"screenWidth": window.screen.width,
	"screenHeight": window.screen.height,
	"platform": platform,
	"Browser": browser
}

export default function Login({ authToken }) {
	////////////////////////////////////////////////////
	// DEFINE STATE
	////////////////////////////////////////////////////
	const [username, setUserName] = useState(undefined);
	const [password, setPassword] = useState(undefined);
	const [otpRequest, setOtpRequest] = useState(false);
	const [otpCode, setOtpCode] = useState(undefined);
	const [message, setMessage] = useState();
	const [intervalID, setIntervalID] = useState();
	const { t, i18n } = useTranslation();

	////////////////////////////////////////////////////
	// DEFINE FUNCTION handleSubmit
	////////////////////////////////////////////////////
	const handleLogin = async (e) => {
		e.preventDefault();

		if(otpRequest == false) {
			await fetch(`${sysgridApiUrl}/users/list`, {
				method: 'POST',
				headers: {
				'Content-Type': 'application/json',
				'Authorization': `${apiToken}`,
				"Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
				},
				body: JSON.stringify({
					"username": username
				})
			})
			.then(data => data.json())
			.then(data => {
				setOtpRequest(data[0].otpActivated)
			})
		}
		else {
			if(username == undefined || username.trim() == "" || password == undefined || password.trim() == ""){
				notify("error", t("Please fill in Username and Password"))
			}
			else {
				const passwordHash = sha256.hmac(sha256Secret, password)
				await fetch(`${sysgridApiUrl}/login/createAuthToken`, {
					method: 'POST',
					headers: {
					'Content-Type': 'application/json',
					'Authorization': `${apiToken}`,
					"Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
					},
					body: JSON.stringify({
						"username": username, 
						"passwordHash": passwordHash,
						"otpCode": otpCode,
						"userDeviceMetadata": userDeviceMetadata
					})
				})
				.then(data => data.json())
				.then(data => {
					if ( data.message ) {
						setMessage(data.message)
						toast.error(`${data.message}`, { duration: toastDuration})
		
					}
					else if ( !data.message && data.authToken) {
						localStorage.setItem("username", username);
						localStorage.setItem("authToken", data.authToken);
						toast.success(`${t("Login sucessfull")}`, { duration: toastDuration})
						window.location.replace(`/`);
					}
				})
			}

		}
    }


	////////////////////////////////////////////////////
	// RENDER COMPONENT
	////////////////////////////////////////////////////
	return(
		<div className="loginContainer">
			<wc-toast></wc-toast>
			<div className="loginArea1 panelTransparent">
				<div className="logoLogin" align="middle"/>
				<form onSubmit={handleLogin}>
				{!otpRequest && 
				<>
					<input className="loginInput" type="text" placeholder={t("Username")} onChange={e => setUserName(e.target.value)}/>
					<input className="loginInput" type="password" placeholder={t("Password")} onChange={e => setPassword(e.target.value)}/>
					<button className="buttonGreen" onClick={(e) => handleLogin(e)}>{t("Login")}</button>
				</>
				}
				{otpRequest && 
				<>
					<br/>
					<span style={{"textAlign": "center"}}>{t("2FA not activated? Just click Login.")}</span>
					<br/>
					<input className="loginInput" type="number" placeholder={t("2FA Code")} onChange={e => setOtpCode(e.target.value)}/>
					<button className="buttonGreen" onClick={(e) => handleLogin(e)}>{t("Login")}</button>
				</>	
				}
				</form>
			</div>
			<div className="loginArea2"></div>
			<div className="loginArea3"></div>
			<div className="loginArea4"></div>
			<div className="loginArea5"></div>
			<div className="loginArea6"></div>
			<div className="loginArea7"></div>
		</div>
	)

}
