////////////////////////////////////////////////////
// RENDER COMPONENT
////////////////////////////////////////////////////
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from 'wc-toast'
import { sha256 } from 'js-sha256';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faPlayCircle, faUser, faExclamationCircle, faPencilAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { checkAuthToken } from '../checkAuthToken.js'
import queryString from "query-string"
import { TransverseLoading } from 'react-loadingg';
import { checkRoles } from '../checkRoles.js'
import roles from "../config/roles.json"
////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const sha256Secret = process.env.REACT_APP_sha256Secret || "e41cc1c2d1a14b98e9987618db5cdda61247d7a3fe147828a6f8522bca155f6ee219056a6645394ac900877a3768bb4d4a5f9e13cedbf84f1a9ffb2fb0a0a0d8"
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"

const queryParams = queryString.parse(window.location.search)

////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}

class UsersEdit extends Component {
	constructor(props) {
		super(props);
		this.state =
		{
			userData: [],
            DataisLoaded: false,
            apiError: null,
            roleCheck: false
		}

	}


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION createUser
    ////////////////////////////////////////////////////
    createUser = item => event => {
        event.preventDefault();
        const username = event.target.elements[0].value
        const firstname = event.target.elements[1].value
        const lastname = event.target.elements[2].value
        const email = event.target.elements[3].value
        const password = event.target.elements[4].value
        const passwordHash = sha256.hmac(sha256Secret, password)

        try {
            toast.loading("Create new User", { duration: toastDuration})
            fetch(`${sysgridApiUrl}/users/createUser`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${apiToken}`,
                    "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                },
                body: JSON.stringify({
                    "username": username,
                    "firstname": firstname,
                    "lastname": lastname,
                    "email": email,
                    "passwordHash": passwordHash,
                })
            })
            .then((res) => {
                return res.json()
            })
            .then(res => {
                    toast.success(`${res.message}`, { duration: toastDuration})
            })
            .catch((err) => {
                err = JSON.parse(err)
                console.log(err)
                toast.error(err.err, { duration: toastDuration})
            })
        }
        catch (e) {
            console.log(e)
        }
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION editUser
    ////////////////////////////////////////////////////
    editUser = item => event => {
        event.preventDefault();
        const userData = this.state.userData
        const userID = queryParams.id
        const username = event.target.elements[0].value
        const firstname = event.target.elements[1].value
        const lastname = event.target.elements[2].value
        const email = event.target.elements[3].value
        const roles = event.target.elements[4].value
        const showIntro = event.target.elements[5].value
        const password = event.target.elements[6].value
        let passwordHash = ""
        console.log("INPUT")
        console.log(event.target.elements[4].value)
        if(password == "") {
            passwordHash = userData[0].passwordHash
            console.log("PASSWORD")
            console.log(userData[0].passwordHash)
        }
        else if (password !== "") {
            passwordHash = sha256.hmac(sha256Secret, password)
        }

        try {
            toast.loading("Edit User Data", { duration: toastDuration})
            console.log("DEBUG")
            console.log(userData)

            fetch(`${sysgridApiUrl}/users/editUser`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${apiToken}`,
                    "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                },
                body: JSON.stringify({
                    "userID": userID,
                    "username": username,
                    "firstname": firstname,
                    "lastname": lastname,
                    "email": email,
                    "roles": roles,
                    "showIntro": showIntro,
                    "newPasswordHash": passwordHash,
                })
            })
            .then((res) => {
                return res.json()
            })
            .then(res => {
                    toast.success(`${res.message}`, { duration: toastDuration})
            })
            .catch((err) => {
                err = JSON.parse(err)
                console.log(err)
                toast.error(err.err, { duration: toastDuration})
            })
        }
        catch (e) {
            console.log(e)
        }
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getUserData
    ////////////////////////////////////////////////////
    getUserData = async () => {
        await fetch(`${sysgridApiUrl}/users/list`, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${apiToken}`,
                    "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                },
                body: JSON.stringify({
                    'userID': `${queryParams.id}`
                })
            })
            .then((res) => {
                if ( res.status != "200") {
                    throw res.statusText
                }
                else {
                    return res.json()
                }
            })
            .then((json) => {
                this.setState({
                    userData: json,
                    apiError: null
                })
            })
            .then(() => {
                this.setState({
                    DataisLoaded: true,
                })
            })
            .catch( err => {
                this.setState({
                    DataisLoaded: false,
                    apiError: err.toString()
                })
            })
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION componentDidMount
    ////////////////////////////////////////////////////
    async componentDidMount() {
        checkAuthToken();
        this.setState({
            roleCheck: await checkRoles(roles.admin.userEdit)
        })
        this.getUserData();
    }


	render() {
		const { DataisLoaded, apiError, items, userData, roleCheck} = this.state;

        if (roleCheck == true) {
            if (!DataisLoaded && (apiError !== null)) {
                return (
                    <div>
                        <h1> Error while retrieving data from API:</h1>
                        <h2>{apiError}</h2>
                    </div>
                )
            }

            if (!DataisLoaded && (apiError === null)) {
                return (
                    <div className="loaderContainer">
                        <div className="loaderArea1">
                            <TransverseLoading />
                        </div>
                    </div>
                )
            }

        	return (
                <>
                    <wc-toast></wc-toast>
                    <div className="userContainer">
                    <div className="userArea1 panelRed"><h2 className={'headingLg'}><FontAwesomeIcon icon={faExclamationCircle} />  Administration</h2></div>
                    <div style={{height: "10px"}}></div>
                    <div className="userArea2 panel"><h2 className={'headingLg'}><FontAwesomeIcon icon={faUser} /> User: {userData.map((user) => (user.username))}</h2></div>
                        <div className="userArea3 panel">
                            <form className="editUserForm" onSubmit={this.editUser()}>
                                {userData.map((user) => (
                                    <>
                                        <label>Username: <input value={`${user.username}`} onChange={(e) => {this.setState({userData: [{username: e.target.value, firstname: `${user.firstname}`, lastname: `${user.lastname}`, email: `${user.email}`, roles: `${user.roles}`, showIntro: `${user.showIntro}`, password: `${user.password}`, passwordHash: `${user.passwordHash}` }]})}}></input></label>
                                        <label>Firstname: <input value={`${user.firstname}`} onChange={(e) => {this.setState({userData: [{username: `${user.username}`, firstname: e.target.value, lastname: `${user.lastname}`, email: `${user.email}`, roles: `${user.roles}`, showIntro: `${user.showIntro}`, password: `${user.password}`, passwordHash: `${user.passwordHash}` }]})}}></input></label>
                                        <label>Lastname: <input value={`${user.lastname}`} onChange={(e) => {this.setState({userData: [{username: `${user.username}`, firstname: `${user.firstname}`, lastname: e.target.value, email: `${user.email}`, roles: `${user.roles}`, showIntro: `${user.showIntro}`, password: `${user.password}`, passwordHash: `${user.passwordHash}` }]})}}></input></label>
                                        <label>E-Mail: <input value={`${user.email}`} onChange={(e) => {this.setState({userData: [{username: `${user.username}`, firstname: `${user.firstname}`, lastname: `${user.lastname}`, email: e.target.value, roles: `${user.roles}`, showIntro: `${user.showIntro}`, password: `${user.password}`, passwordHash: `${user.passwordHash}` }]})}}></input></label>
                                        <label>Role: <input value={`${user.roles}`} onChange={(e) => {this.setState({userData: [{username: `${user.username}`, firstname: `${user.firstname}`, lastname: `${user.lastname}`, email: `${user.email}`, roles: e.target.value, showIntro: `${user.showIntro}`, password: `${user.password}`, passwordHash: `${user.passwordHash}` }]})}}></input></label>
                                        <label>Show Intro: <input value={`${user.showIntro}`} onChange={(e) => {this.setState({userData: [{username: `${user.username}`, firstname: `${user.firstname}`, lastname: `${user.lastname}`, email: `${user.email}`, roles: `${user.roles}`, showIntro: e.target.value, password: `${user.password}`, passwordHash: `${user.passwordHash}` }]})}}></input></label>
                                        <label>Set new Password: <input placeholder='Password' onChange={(e) => {this.setState({userData: [{username: `${user.username}`, firstname: `${user.firstname}`, lastname: `${user.lastname}`, email: `${user.email}`, roles: `${user.roles}`, showIntro: `${user.showIntro}`, password: e.target.value, passwordHash: `${user.passwordHash}` }]})}}></input></label>
                                        <label>&nbsp;
                                            <button className="buttonSave"><FontAwesomeIcon icon={faSave} /></button>
                                        </label>
                                    </>
                                ))}
                            </form>
                        </div>
                    </div>
                </>
        	);
        }
        else {
            return(
                <>
                    <h1>No permission</h1>
                </>
            )
        }
	}
}

export default UsersEdit;
