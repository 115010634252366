////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from 'wc-toast'
import { withTranslation } from 'react-i18next';
import { TransverseLoading } from 'react-loadingg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Spin } from "react-cssfx-loading";
import { useTranslation } from 'react-i18next';
import i18n from './i18n';

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const databasePassword = process.env.REACT_APP_databasePassword
const apiToken = process.env.REACT_APP_apiToken || "123"

////////////////////////////////////////////////////
// DEFINE FUNCTION json2array
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}


////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
// function installDBContainer() {
//     fetch(`${sysgridApiUrl}/installDBContainer`, {
//         method: 'POST',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'Authorization': `${apiToken}`,
//             "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
//         },
//         body: JSON.stringify({
//                 'templateName': 'sysgridDB',
//                 'envVariables': {
//                     'projectID': 'sysgrid',
//                     'DB_PASSWORD': `${databasePassword}`
//                 }
//         })
//     })
//     .then((res) => res.text())
//     .then((text) => {
//         toast.success("Database created", { duration: toastDuration})
//     })
//     .catch( err => {
//         console.log(err)
//     })
// }

// function addDBContainerToProjects() {
//     fetch(`${sysgridApiUrl}/addDBContainerToProjects`, {
//         method: 'POST',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'Authorization': `${apiToken}`,
//             "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
//         },
//         body: JSON.stringify({
//                 'templateName': 'sysgridDB',
//                 'envVariables': {
//                     'projectID': 'sysgrid'
//                 }
//         })
//     })
//     .then((res) => res.text())
//     .then((text) => {
//         toast.success("Database created", { duration: toastDuration})
//     })
//     .catch( err => {
//         console.log(err)
//     })
// }




////////////////////////////////////////////////////
// RENDER COMPONENT
////////////////////////////////////////////////////
class Install extends Component {
	constructor(props) {
		super(props);

		this.state =
		{
            DataisLoaded: false,
            apiError: null,
            dbInstalled: "false",
            tablesInstalled: "false",
            adminUserCreated: "false",
            defaultDomainCreated: "false",
            adminUsername: "",
            adminPassword: "",
            defaultDomain: ""
		}
	}
    
    install = async() => {
        this.setState({
            dbInstalled: "loading",
            tablesInstalled: "loading",
            adminUserCreated: "loading",
            defaultDomainCreated: "loading"
        })
        await this.installDB()
        await this.installTables()
        await this.createAdminUser()
        await this.createDefaultDomain()
    }
    
    installDB = async () => {
        await fetch(`${sysgridApiUrl}/installDB`, {
            headers:{
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
            }
        })
        .then((res) => res.json())
        .then((json) => {
            console.log(json)
            if(json.message) {
                toast.success("Database created", { duration: toastDuration})
                this.setState({
                    dbInstalled: "true"
                })
            }
            else if (json.error) {
                toast.error("Database NOT created. Error: " + json.error, { duration: toastDuration})
                this.setState({
                    dbInstalled: "false"
                })
            }
        })
        .catch( err => {
            console.log(err)
        })
    }
    
    installTables = async () => {
        await fetch(`${sysgridApiUrl}/installTables`, {
            headers:{
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
            }
        })
        .then((res) => res.json())
        .then((json) => {
            if(json.message) {
                toast.success("Tables created", { duration: toastDuration})
                this.setState({
                    tablesInstalled: "true"
                })
            }
            else if (json.error) {
                toast.error("Tables NOT created. Error: " + json.error, { duration: toastDuration})
                this.setState({
                    tablesInstalled: "false"
                })
            }
        })
        .catch( err => {
            console.log(err)
        })
    }

    createAdminUser = async () => {
        await fetch(`${sysgridApiUrl}/users/createAdmin`, {
            headers:{
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
            }
        })
        .then((res) => res.json())
        .then((json) => {
            if(json.message) {
                toast.success("Admin User created", { duration: toastDuration})
                this.setState({
                    adminUserCreated: "true",
                    adminUsername: json.username,
                    adminPassword: json.password
                })
                console.log("json")
                console.log(json)
            }
            else if (json.error) {
                toast.error("Admin User NOT created. Error: " + json.error, { duration: toastDuration})
                this.setState({
                    adminUserCreated: "false"
                })
            }
        })
        .catch( err => {
            console.log(err)
        })
    }

    createDefaultDomain = async () => {
        const fulldomain = window.location.hostname.split('.').slice(-2);
        const secondLevelDomain = fulldomain[0]
        const topLevelDomain = fulldomain[1]

        fetch(`${sysgridApiUrl}/domains/createDomain`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
            },
            body: JSON.stringify({
                "subDomains": "",
                "secondLevelDomain": secondLevelDomain,
                "topLevelDomain": topLevelDomain,
            })
        })
        .then((res) => res.json())
        .then((json) => {
            if(json.message) {
                toast.success("Domain created/configured", { duration: toastDuration})
                this.setState({
                    defaultDomainCreated: "true",
                    defaultDomain: fulldomain.join(".")
                })
            }
            else if (json.error) {
                toast.error("Default Domain NOT created/configured. Error: " + json.error, { duration: toastDuration})
                this.setState({
                    defaultDomainCreated: "false"
                })
            }
        })
        .catch( err => {
            console.log(err)
        })
    }

    handleLangChange = (evt) => {
        const lang = evt.target.value;
        i18n.changeLanguage(lang);
        console.log(i18n.language)
    };

	render() {
		const { DataisLoaded, apiError, items } = this.state;
        const { t } = this.props

	return (
        <>
            <wc-toast></wc-toast>
            <div className="installContainer">
                <div className="installModal">
                <div className="installModalInner">
                    <div className="installModalInnerInner">
                        <span>
                            {t("Language")}:
                            <select className="layoutLangSelect" onChange={this.handleLangChange} value={i18n.language}>
                                <option value="en-EN">EN</option>
                                <option value="de-DE">DE</option>
                            </select>
                        </span>
                        <h1>{t("Welcome to sysgrid")}</h1>
                        <span>{t("Sysgrid Commander is your AppStore for installing any WebApp you need to start a successfull and organized Business!")}</span>
                        <br/>
                        <span>{t('In the following we will install the "sysgrid Commander" which contains the AppStore, an overview of all installed Apps and many more usefull features.')}</span>
                        <br/>
                        <br/>
                            <h2>{
                                this.state.dbInstalled == "false" ? (<span className="xMark">X</span>) 
                                : 
                                this.state.dbInstalled == "loading" ? (<Spin className="installSpinner"/>) 
                                : 
                                this.state.dbInstalled == "true" ? (<FontAwesomeIcon className="installDoneCheckMark" icon={faCheck} />) 
                                : 
                                null
                            }&nbsp;
                            {t("Install Database")}
                            </h2>

                            <h2>{
                                this.state.tablesInstalled === "false" ? (<span className="xMark">X</span>) 
                                : 
                                this.state.tablesInstalled == "loading" ? (<Spin className="installSpinner"/>) 
                                : 
                                this.state.tablesInstalled === "true" ? (<FontAwesomeIcon className="installDoneCheckMark" icon={faCheck} />) 
                                : 
                                null
                            }&nbsp;
                            {t("Install Tables")}
                            </h2>
                            
                            <h2>{
                                this.state.adminUserCreated === "false" ? (<span className="xMark">X</span>) 
                                : 
                                this.state.adminUserCreated == "loading" ? (<Spin className="installSpinner"/>) 
                                : 
                                this.state.adminUserCreated === "true" ? (<FontAwesomeIcon className="installDoneCheckMark" icon={faCheck} />) 
                                : 
                                null
                            }&nbsp;
                            {t("Create Admin User")}
                            </h2>

                            {
                                this.state.adminUserCreated === "true" ? 
                                <div className="installAdminUserCredentials">
                                    <span>
                                        {t("The Credentials of the new Admin User are")}:
                                        <br/>
                                        {t("Username")}:&nbsp;&nbsp;
                                        {this.state.adminUsername}
                                        <br/>
                                        {t("Password")}:&nbsp;&nbsp;
                                        {this.state.adminPassword}
                                        <br/>
                                        <br/>
                                        {t("Please write them down since they are only displayed once!")}
                                    </span>
                                </div>
                                : 
                                null
                            }

                            <h2>{
                                this.state.defaultDomainCreated === "false" ? (<span className="xMark">X</span>) 
                                : 
                                this.state.defaultDomainCreated == "loading" ? (<Spin className="installSpinner"/>) 
                                : 
                                this.state.defaultDomainCreated === "true" ? (<FontAwesomeIcon className="installDoneCheckMark" icon={faCheck} />) 
                                : 
                                null
                            }&nbsp;
                            {t("Configure default Domain")}
                            </h2>

                            {
                                this.state.adminUserCreated === "true" ? 
                                <div className="installAdminUserCredentials">
                                    <span>
                                        {t("The configured default Domain is")}:
                                        <br/>
                                        {this.state.defaultDomain}
                                        <br/>
                                        <br/>
                                        {t("You can change this or add new ones later in the Admin Menu")}
                                    </span>
                                </div>
                                : 
                                null
                            }&nbsp;

                            {
                                this.state.dbInstalled === "true" && this.state.tablesInstalled === "true" && this.state.adminUserCreated === "true" ? 
                                <button className="buttonGreen" onClick={() => window.location.replace(`/`)}>{t("Go to Login")}</button>
                                :
                                <button className="buttonGreen" onClick={() => this.install()}>{t("Start Installation")}</button>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
	);
    }
}

export default withTranslation("translations")(Install);
