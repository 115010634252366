////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "./Layout";
import { toast } from 'wc-toast'
import GaugeChart from 'react-gauge-chart'
import { checkAuthToken } from './checkAuthToken.js'
import { withTranslation } from 'react-i18next';
import notify from "./notify";
import { stopContainer, startContainer, deleteContainer } from "./containerControll.js"
import { TransverseLoading } from 'react-loadingg';
const { Buffer } = require('buffer');

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl = process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/dockerApi"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const refreshDataInterval = process.env.REACT_APP_refreshDataInterval || 10000
const refreshStatsInterval = process.env.REACT_APP_refreshStatsInterval || 10000
const apiToken = process.env.REACT_APP_apiToken || "123"

let gaugeTextColor = ""



////////////////////////////////////////////////////
// CHANGE GAUGE TEXT COLOR ACCORDING TO SYSTEM
// DARK OR LIGHT MODE
////////////////////////////////////////////////////
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
    gaugeTextColor = "#000000"
}
else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    gaugeTextColor = "#ffffff"
}


////////////////////////////////////////////////////
// DEFINE REACT COMPONENT
////////////////////////////////////////////////////
class Container extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            containerInspect: {},
            containerStats: {},
            cpuPercentage: 0,
            memoryPercentage: 0,
			containerLog: "",
            DataisLoaded: false,
            apiError: null,
            setDataIntervalId: "",
            setStatsIntervalId: ""
        };
    }


    ////////////////////////////////////////////////////
    // COMPONENT DID AND WILL MOUNT
    ////////////////////////////////////////////////////
    componentDidMount() {
        // check if authenticated
        checkAuthToken()
        // need to make the initial call to getData() to populate data right away
        this.getData();
        // Now we need to make it run at a specified interval
        var setDataIntervalId = setInterval(this.getData, refreshDataInterval); // runs every 5 seconds.
        var setStatsIntervalId = setInterval(this.getData, refreshStatsInterval); // runs every 5 seconds.
        this.setState({
            setDataIntervalId: setDataIntervalId,
            setStatsIntervalId: setStatsIntervalId
        })
    }

    componentWillUnmount() {
        clearInterval(this.state.setDataIntervalId)
        clearInterval(this.state.setStatsIntervalId)
    }

    ////////////////////////////////////////////////////
    // DEFINE FUNCTION decodeLog
    ////////////////////////////////////////////////////
    decodeLog = (data) => {
        const decoder = new TextDecoder();
        const decoded = decoder.decode(data);
      
        return decoded
          
      }

    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getData
    ////////////////////////////////////////////////////
	getData = async() => {
		const queryParams = new URLSearchParams(window.location.search)
		const containerID = queryParams.get("containerID")
        const templateName = queryParams.get("templateName")
        const projectID = queryParams.get("projectID")

        // load container
        await fetch(`${dockerApiUrl}/containers/${containerID}/json`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
            }
        })
            .then((res) => {
                if ( res.status != "200") {
                    throw res.statusText
                }
                else {
                    return res.json()
                }
            })
            .then((json) => {
                this.setState({
                    containerInspect: json,
                    apiError: null
                })
            })
            .catch( err => {
                this.setState({
                    DataisLoaded: false,
                    apiError: err.toString()
                })
            })

            // load container logs
			await fetch(`${dockerApiUrl}/containers/${containerID}/logs?stdout=true&stderr=true&tail=100`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${apiToken}`,
                    "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                }
            })
				.then((res) => {
					if ( res.status != "200") {
						throw res.statusText
					}
					else {
						var resText=res.text()
						return resText
					}
				})
				.then((text) => {
                    //get the first 8 bytes of the string 
                    const buf = Buffer.from(text); // convert string to buffer
                    const first8Bytes = buf.slice(0, 8); // get the first 8 bytes

                    const textArray = text.split('\n')
                    let logString = ""
                    for (let line of textArray) {
                        //get the first 8 bytes of the line 
                        const buf = Buffer.from(line); // convert line to buffer
                        const first8Bytes = buf.slice(0, 8); // get the first 8 bytes
                        
                        logString = logString + "\n" + line.slice(8)
                    }

					this.setState({
						containerLog: logString,
						apiError: null
					})
				})
				.catch( err => {
					this.setState({
						DataisLoaded: false,
						apiError: err.toString()
					})
				})

                // load logo
                await fetch(`${sysgridApiUrl}/project`,  {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `${apiToken}`,
                            "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                        },
                        body: JSON.stringify({
                            "projectID": `${projectID}`,
                            "templateName": `${templateName}`,
                        })
                })
                .then((res) => {
                    if ( res.status != "200") {
                        throw res.statusText
                    }
                    else {
                        return res.json()
                    }
                })
                .then((res) => {
                    this.setState({
                        logo: res,
                    })
                })
                .catch( err => {
                    console.log(err)
                })
                this.setState({
                        rolloutStarted: false,
                        rolloutError: false,
                })

                this.getStats()
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getStats
    ////////////////////////////////////////////////////
    getStats = async() => {
    const queryParams = new URLSearchParams(window.location.search)
    const containerID = queryParams.get("containerID")

    await fetch(`${dockerApiUrl}/containers/${containerID}/stats?stream=false`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `${apiToken}`,
            "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
        }
    })
    .then((res) => {
        if ( res.status != "200") {
            throw res.statusText
        }
        else {
            return res.json()
        }
    })
    .then((json) => {
        this.setState({
            containerStats: json,
            DataisLoaded: true,
            apiError: null
        })
        if ( this.state.containerStats.cpu_stats.cpu_usage.total_usage > 0.000000000000000000000000000000000 ){
            const stats = this.state.containerStats;
            const cpuDelta = stats.cpu_stats.cpu_usage.total_usage - stats.precpu_stats.cpu_usage.total_usage;
            const systemCpuDelta = stats.cpu_stats.system_cpu_usage - stats.precpu_stats.system_cpu_usage;
            let numCpus = stats.cpu_stats.cpu_usage.percpu_usage.length || stats.cpu_stats.online_cpus;
            if(stats.cpu_stats.cpu_usage.percpu_usage.length !== undefined){
                numCpus = stats.cpu_stats.cpu_usage.percpu_usage.length
            }
            else{
                numCpus = stats.cpu_stats.online_cpus
            }
            const cpuUsagePercent = (cpuDelta / systemCpuDelta) // * numCpus * 100;
            console.log(`Current CPU usage: ${cpuUsagePercent.toFixed(2)}%`);


            cpuPercentage = cpuUsagePercent
        }
        else{
            var cpuPercentage = 0
        }
        if(Object.keys(this.state.containerStats.memory_stats).length > 0) {
            var usedMemory = this.state.containerStats.memory_stats.usage - this.state.containerStats.memory_stats.stats.cache
            var availableMemory = this.state.containerStats.memory_stats.limit
            memoryPercentage = (100 / availableMemory * usedMemory) / 100

	}
        else{
            var memoryPercentage = 0
        }
        this.setState({
            cpuPercentage: cpuPercentage,
            memoryPercentage: memoryPercentage
        })
    })
    .catch( err => {
        this.setState({
            DataisLoaded: false,
            apiError: err.toString()
        })
    })
}


    ////////////////////////////////////////////////////
    // RENDER COMPONENT
    ////////////////////////////////////////////////////
    render() {
        const { containerInspect, containerStats, cpuPercentage, memoryPercentage, DataisLoaded, apiError } = this.state;
        const { t } = this.props

        if (!DataisLoaded && (apiError !== null)) {
            return (
                <div>
                    <h1> {t("Error while retrieving data from API")}:</h1>
                    <h2>{apiError}</h2>
                </div>
        	)
        }

        if (!DataisLoaded && (apiError === null)) {
            return (
                <div className="loaderContainer">
                    <div className="loaderArea1">
                        <TransverseLoading />
                    </div>
                </div>
            )
        }

		const ReadMore = ({ children }) => {
		  const text = children;
		  const [isReadMore, setIsReadMore] = useState(true);
		  const toggleReadMore = () => {
		    setIsReadMore(!isReadMore);
		  };
		  return (
		    <p className="text">
		      {isReadMore ? text.slice(0, 2000) : text}
		      <span onClick={toggleReadMore} className="readOrHide">
		        {isReadMore ? "...read more" : " show less"}
		      </span>
		    </p>
		  );
		};

        return (
            <>
                <wc-toast></wc-toast>
                <div className="panel">
                    <div class="containerPanelVerticalAlignHelper">
                        <img className="containerLogo" src={`data:image/png;base64,${this.state.logo}`} />
                        <h2 className={'headingLg'}>{this.state.containerInspect.Name.substring(1)}</h2>
                    </div>
                </div>
                <div className={`containerContainer`}>
                    <div className={`containerArea1 panel`}>
                        {(this.state.containerInspect.State["Status"].toLowerCase() === 'created' &&
                            <td><div className={`statusDotRed`}></div></td>
                        )}
                        {(this.state.containerInspect.State["Status"].toLowerCase() === 'running' &&
                            <td><div className={`statusDotGreen`}></div></td>
                        )}
                        {(this.state.containerInspect.State["Status"].toLowerCase() === 'restarting' &&
                            <td><div className={`statusDotYellow`}></div></td>
                        )}
                        {(this.state.containerInspect.State["Status"].toLowerCase() === 'exited' &&
                            <td><div className={`statusDotRed`}></div></td>
                        )}
                        {(this.state.containerInspect.State["Status"].toLowerCase() === 'paused' &&
                            <td><div className={`statusDotYellow`}></div></td>
                        )}
                        {(this.state.containerInspect.State["Status"].toLowerCase() === 'dead' &&
                            <td><div className={`statusDotBlack`}></div></td>
                        )}
                        &nbsp;&nbsp;<h3 className={`headingSmall title`}>{this.state.containerInspect.Name.substring(1)}</h3>
                    </div>
                    <div className={`containerArea2 panel`}>
                        <button className="buttonGreen" onClick={e => startContainer(this.state.containerInspect.Id)}>{t("Start")}</button>
                        <button className="buttonRed" onClick={e => stopContainer(this.state.containerInspect.Id)}>{t("Stop")}</button>
                        <button className="buttonRed"  onClick={e => deleteContainer(this.state.containerInspect.Id)}>{t("Delete")}</button>
                    </div>
                    <div className={`containerArea3 panel`}>
                        ID: {this.state.containerInspect.Id} <br/>Status: {this.state.containerInspect.State["Status"]} <br/> {t("Project")}: {this.state.containerInspect["Config"]["Labels"]["com.docker.compose.project"]}
                    </div>
                    <div className={`containerArea4 panel`}>
                        <h4>{t("CPU Usage")}:</h4>
                        <GaugeChart id="gauge-chart2"
                        nrOfLevels={8}
                        percent={cpuPercentage}
                        animate={false}
                        textColor={gaugeTextColor}
                        />
                    </div>
                    <div className={`containerArea5 panel`}>
                        <h4>{t("RAM Usage")}:</h4>
                        <GaugeChart id="gauge-chart2"
                        nrOfLevels={8}
                        percent={memoryPercentage}
                        animate={false}
                        textColor={gaugeTextColor}
                        />
                    </div>
                    <div className={`containerArea6 panel`}>
                        <h4>{t("Logs")}:</h4>
                        <div className='codePanel'>
                            <span style={{whiteSpace: "pre-line"}}>
                                <ReadMore>
                                    {this.state.containerLog}
                                </ReadMore>
                            </span>
                        </div>
                    </div>
                </div>
            </>
    );
}
}

export default withTranslation("translations")(Container);
