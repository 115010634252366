////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React from "react";
import { Link } from "react-router-dom";
import { useRef } from 'react';
import { toast } from 'wc-toast'
import { checkAuthToken } from '../checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faTv, faCog, faExternalLinkAlt, faServer, faWrench } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';
import ToggleButton from 'terra-toggle-button';
import notify from "../notify.js"
import i18n from '../i18n.js';
import { TransverseLoading } from 'react-loadingg';
import { deleteContainer } from '../containerControll.js';
import { IntroBoxHeading, IntroBoxContent } from '../components/IntroBox.js'

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const sysgridVpnReverseproxyApiUrl = process.env.REACT_APP_sysgridVpnReverseproxyApiUrl || "http://localhost:9002/vpnReverseproxyApi"
const sysgridBindApiUrl = process.env.REACT_APP_sysgridBindApiUrl || "http://localhost:9002/bindApi"
const refreshInterval = process.env.REACT_APP_refreshDataInterval || 5000
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"
const t = i18n.t;

////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}

// const deleteContainer = async (Id, projectID, templateName) => {
//   const url = `${apiReverseproxyUrl}/containers/${Id}?force=true`
//
//   try {
//     const result = await fetch(url, {
//                                       method: 'DELETE', // or 'PUT'
//                                       headers: {
//                                           'Authorization': `${apiToken}`,
//                                           "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",                                          "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",                                          'Accept': 'application/json',
//                                           'Content-Type': 'application/json'
//                                       }
//                                   })
//     const logMessage = await result
//     console.log(logMessage)
//     notify("success", t("Container deleted"))
//   } catch (e) {
//     console.log(e)
//   }
// };

class AdminSystem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            databaseItems: [],
            dockerItems: [],
            DataisLoaded: true,
            apiError: null,
            rolloutStarted: false,
            setIntervalId: "",
            deleteDataStates: {},
            allSystems: []
        };
    }



    ////////////////////////////////////////////////////
    // COMPONENT DID AND WILL MOUNT
    ////////////////////////////////////////////////////
    componentDidMount() {
        checkAuthToken()
        // need to make the initial call to getData() to populate
        // data right away
        this.getData();
        // Now we need to make it run at a specified interval
        // var setIntervalId = setInterval(this.getData, refreshInterval); // runs every 5 seconds.
        // this.setState({
        //     setIntervalId: setIntervalId
        // })
    }

    getAllData = async () => {
        await this.getData();
    }

    componentWillUnmount() {
        clearInterval(this.state.setIntervalId)
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getData
    ////////////////////////////////////////////////////
	getData = async () => {
        // await fetch(`${sysgridApiUrl}/projects`, {
        //     headers: {
        //         'Authorization': `${apiToken}`,
        //         "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     }
        // })
        // .then((res) => {
        //     if ( res.status != "200") {
        //         throw res.statusText
        //     }
        //     else {
        //         return res.json()
        //     }
        // })
        // .then((array) => {
        //     //json to array for every object in object enVariables
        //     this.setState({
        //         databaseItems: array,
        //         DataisLoaded: true
        //     })
        // })
        // .catch( err => {
        //     this.setState({
        //         DataisLoaded: false,
        //         apiError: err.toString()
        //     })
        // })
        // this.setState({
        //         rolloutStarted: false,
        //         rolloutError: false,
        // })
    }

    ////////////////////////////////////////////////////
	// DEFINE FUNCTION recreateBindConfig
	////////////////////////////////////////////////////
    recreateBindConfig = (event) => {
        event.preventDefault();
        fetch(`${sysgridBindApiUrl}/createConfig`, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            notify("success", t("Successfully recreated Bind DNS Config"))
        })
        .catch(error => {
            notify("error", "Error recreating bind config: " + error)
        });
    }

    ////////////////////////////////////////////////////
	// DEFINE FUNCTION recreateVpnReverseproxyConfig
	////////////////////////////////////////////////////
    recreateVpnReverseproxyConfig = (event) => {
        event.preventDefault();
        fetch(`${sysgridVpnReverseproxyApiUrl}/createConfig`, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            notify("success", t("Successfully recreated Reverseproxy Config"))

        })
        .catch(error => {
            notify("error", "Error recreating reverseproxy config: " + error)
        });
    }

    recreateVpnReverseproxyConfig = (event) => {
        event.preventDefault();
        fetch(`${sysgridVpnReverseproxyApiUrl}/createConfig`, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            notify("success", t("Successfully recreated Reverseproxy Config"))

        })
        .catch(error => {
            notify("error", "Error recreating reverseproxy config: " + error)
        });
    }
    
    ////////////////////////////////////////////////////
    // RENDER COMPONENT
    ////////////////////////////////////////////////////
    render() {
        const { databaseItems, DataisLoaded, AllSystemDataIsLoaded, apiError } = this.state;
        const { t } = this.props

        if (!DataisLoaded && !AllSystemDataIsLoaded && (apiError !== null)) {
            return (
                <div>
                    <h1> {t("Error while retrieving data from API")}:</h1>
                    <h2>{apiError}</h2>
                </div>
            )
        }
        if (!DataisLoaded && !AllSystemDataIsLoaded && (apiError === null)) {
            return (
                <div className="loaderContainer">
                    <div className="loaderArea1">
                        <TransverseLoading />
                    </div>
                </div>
            )
        }

        return (
            <>
                <wc-toast></wc-toast>
                <IntroBoxHeading>
                    {t("Informations about this Menupoint")}
                </IntroBoxHeading>
                <IntroBoxContent>
                    {t("Here you can create configure Systemsettings and run special commands.")}
                </IntroBoxContent>

                <div className="panel"><h2 className={'headingLg'}><FontAwesomeIcon icon={faWrench} />  {t("System Settings")}</h2></div>
                        <>
                            <div className="systemsContainer panel">
                            <button className="buttonRed" onClick={(e) => this.recreateBindConfig(e)}>{t("Recreate Bind (DNS) Config")}</button>
                            <button className="buttonRed" onClick={(e) => this.recreateVpnReverseproxyConfig(e)}>{t("Recreate VPN Reverseproxy Config")}</button>
                            </div>
                        </>
            </>

    );
}
}

export default withTranslation("translations")(AdminSystem);
