////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const apiToken = process.env.REACT_APP_apiToken || "123"

////////////////////////////////////////////////////
// DEFINE FUNCTION checkAuthToken
////////////////////////////////////////////////////
export async function checkRoles(role) {
	const username = localStorage.getItem("username");
	const authToken = localStorage.getItem('authToken');

	try {
      const data = await fetch(`${sysgridApiUrl}/users/roles`, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
		  'Authorization': `${apiToken}`,
		  "Access-Control-Request-Headers": "Authorization, Content-Type, Origin",
		  "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify({"username": username, "authToken": authToken})
      })

	  const dataJson = await data.json()

      if ( dataJson.message ) {
		  return false;
      }
      else if ( !dataJson.message) {
		  const rolesArray = dataJson.roles.split(',')
		  if(rolesArray.includes(role)) {
			  return true;
		  }
		  else {
			  return false;
		  }
      }
	  else if (dataJson.error) {
		  return false;
      }
    } catch (error) {
		return false;
		console.error(error);
    }
}
