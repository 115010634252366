////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React from "react";
import { Link } from "react-router-dom";
import { useRef } from 'react';
import { toast } from 'wc-toast'
import { checkAuthToken } from './checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faTv, faCog, faShieldAlt, faExternalLinkAlt, faServer } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';
import ToggleButton from 'terra-toggle-button';
import notify from "./notify.js"
import i18n from './i18n.js';
import { TransverseLoading } from 'react-loadingg';
import { deleteContainer } from './containerControll.js';
import { IntroBoxHeading, IntroBoxContent } from './components/IntroBox.js'
import QRCode from "react-qr-code";
import wireguardAppIcon from "./media/wireguardAppIcon.png"
import windowsLogo from "./media/windowsLogo.png"
import appleLogo from "./media/appleLogo.png"

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const refreshInterval = process.env.REACT_APP_refreshDataInterval || 5000
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"
const t = i18n.t;
const username = localStorage.getItem("username")

////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}

// const deleteContainer = async (Id, projectID, templateName) => {
//   const url = `${apiReverseproxyUrl}/containers/${Id}?force=true`
//
//   try {
//     const result = await fetch(url, {
//                                       method: 'DELETE', // or 'PUT'
//                                       headers: {
//                                           'Authorization': `${apiToken}`,
//                                           "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",                                          "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",                                          'Accept': 'application/json',
//                                           'Content-Type': 'application/json'
//                                       }
//                                   })
//     const logMessage = await result
//     console.log(logMessage)
//     notify("success", t("Container deleted"))
//   } catch (e) {
//     console.log(e)
//   }
// };

class VPN extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            vpnClientConfigs: [],
            dockerItems: [],
            DataisLoaded: false,
            apiError: null,
            rolloutStarted: false,
            setIntervalId: "",
            deleteDataStates: {},
            allBackups: []
        };
    }



    ////////////////////////////////////////////////////
    // COMPONENT DID AND WILL MOUNT
    ////////////////////////////////////////////////////
    componentDidMount() {
        checkAuthToken()
        // need to make the initial call to getClientConfigs() to populate
        // data right away
        this.getAllData();
        // Now we need to make it run at a specified interval
        // var setIntervalId = setInterval(this.getClientConfigs, refreshInterval); // runs every 5 seconds.
        // this.setState({
        //     setIntervalId: setIntervalId
        // })
    }

    getAllData = async () => {
        await this.getClientNames();
        await this.getClientConfigs();
        await this.getAllBackups();
    }

    componentWillUnmount() {
        clearInterval(this.state.setIntervalId)
    }

	getClientConfig = async (clientName) => {
        const clientConfig = await fetch(`${sysgridApiUrl}/vpn/getClientConfigByUser`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "username": `${username}`,
                "clientName": `${clientName}`
            })
        })
        .then((res) => {
            if ( res.status != "200") {
                throw res.statusText
            }
            else {
                return res.json()
            }
        })
        .then((array) => {
            //json to array for every object in object enVariables
            this.setState({
                vpnConfigs: array,
                DataisLoaded: true
            })
            return(array)
        })
        .catch( err => {
            return([{"error": err.toString()}])
        })

        console.log(clientConfig)
        return(clientConfig)
    }

    getClientConfigs = async (clientName) => {
        const clientNames = this.state.vpnClientNames
        let clientConfigs = []
        console.log("clientNames")
        console.log(clientNames)
        for(let clientName of clientNames){
            const clientConfig = await this.getClientConfig(clientName)
            console.log("clientConfig")
            console.log(clientConfig)
            clientConfigs.push(clientConfig)
        }
        this.setState({
            "vpnClientConfigs": clientConfigs
        })
    }

    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getClientConfigs
    ////////////////////////////////////////////////////
	getClientNames = async () => {
        const clientNames = await fetch(`${sysgridApiUrl}/vpn/getClientsByUser`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "username": `${username}`
            })
        })
        .then((res) => {
            if ( res.status != "200") {
                throw res.statusText
            }
            else {
                return res.json()
            }
        })
        .then((array) => {
            //json to array for every object in object enVariables
            this.setState({
                vpnClientNames: array.clientNames,
                DataisLoaded: true
            })
            return(array)
        })
        .catch( err => {
            this.setState({
                DataisLoaded: false,
                apiError: err.toString()
            })
            return([])
        })


        console.log("clientNames")
        console.log(clientNames)
    }

      ////////////////////////////////////////////////////
      // DEFINE FUNCTION createBackup
      ////////////////////////////////////////////////////
      getAllBackups = async () => {
        var fetchURL=`${sysgridApiUrl}/backups/list`

        await fetch(fetchURL, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((res) => {
            if ( res.status != "200") {
                throw res.statusText
            }   
            else {
                return res.json()
            }
        })
        .then((array) => {
            //json to array for every object in object enVariables
            this.setState({allBackups: array})
            this.setState({
                AllBackupDataIsLoaded: true,
            })
        })
        .catch( err => {
            this.setState({
                AllBackupDataIsLoaded: false,
                apiError: err.toString()
            })
        })
      }


    ////////////////////////////////////////////////////
	// DEFINE FUNCTION deleteVpnConfig
	////////////////////////////////////////////////////
    deleteVpnConfig = (clientName) => {
        var jsonVariables = {}
        var jsonResult = {}
        var fetchURL=`${sysgridApiUrl}/vpn/deleteConfig`
        const { t } = this.props

        if(window.confirm(t("Do you really want to delete this VPN Config?"))) {
            try {
                notify("loading", t("Delete Config for") + " " + clientName)
                fetch(`${fetchURL}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `${apiToken}`,
                        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                    },
                    body: JSON.stringify({
                        "username": username,
                        "clientName": clientName
                    })
                })
                .then((res) => {
                    return res.json()
                })
                .then(res => {
                    if ( res.error ) {
                        notify("error", `${res.error}`)
                    }
    
                    else if ( res.message ) {
                    notify("success", t("Config created for Client" + ": " + clientName))
                    this.getAllData();
                    }
                })
                .catch((err) => {
                    err = JSON.parse(err)
                    console.log(err)
                    notify("error", `${err.err}`)
                })
            }
            catch (e) {
                console.log(e)
            }
        }
        else {
            notify("error", t("No VPN Configuration created!"))
        }
    }

    ////////////////////////////////////////////////////
	// DEFINE FUNCTION handleSubmit
	////////////////////////////////////////////////////
    handleSubmit = item => event => {
        event.preventDefault();
        var fetchURL=`${sysgridApiUrl}/vpn/createConfig`
        var jsonVariables = {}
        var jsonResult = {}
        const { t } = this.props
        const clientName = event.target.elements[0].value
        if (clientName == undefined){
            notify("error", t("Clientname must not be empty"))
        }
        else {
            // Show loading notification before the fetch
            notify("promise", { loading: `${t("Create VPN Client Config")}: ${clientName}` }, {}, async () => {
                    const result = await fetch(`${fetchURL}`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `${apiToken}`,
                            'Access-Control-Request-Headers': 'Authorization, Content-Type, Accept',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "username": username
                        })
                    });

                    const responseData = await result.json();

                    // Dismiss loading notification after fetch response
                    if (responseData.error) {
                        return { type: "error", message: `${t("Client Config not created")} \nError:\n${responseData.error}` };
                    } else {
                        return { type: "success", message: `${t("Client Config created")}` };
                    }
            });


            // fetch(`${fetchURL}`, {
            //     method: 'POST', // or 'PUT'
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //         'Authorization': `${apiToken}`,
            //         "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
            //     },
            //     body: JSON.stringify({
            //         "clientName": clientName,
            //         "username": username
            //     })
            // })
            // .then((res) => {
            //     return res.json()
            // })
            // .then(res => {
            //     if ( res.error ) {
            //         notify("error", `${res.error}`)
            //     }
    
            //     else if ( res.message ) {
            //     notify("success", t("Config successfully created"))
            //     this.getAllData();
            //     }
            // })
            // .catch((err) => {
            //     err = JSON.parse(err)
            //     console.log(err)
            //     notify("error", `${err.err}`)
            // })
        }
    }

    handleDownloadClick = (clientName, clientConfig) => {
        const file = new Blob([clientConfig], { type: 'text/plain' });
        const fileUrl = URL.createObjectURL(file);
        const downloadLink = document.createElement('a');
        downloadLink.href = fileUrl;
        downloadLink.download = `${clientName}.conf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    ////////////////////////////////////////////////////
    // RENDER COMPONENT
    ////////////////////////////////////////////////////
    render() {
        const { vpnClientConfigs, DataisLoaded, AllBackupDataIsLoaded, apiError } = this.state;
        const { t } = this.props

        if (!DataisLoaded && !AllBackupDataIsLoaded && (apiError !== null)) {
            return (
                <div>
                    <h1> {t("Error while retrieving data from API")}:</h1>
                    <h2>{apiError}</h2>
                </div>
            )
        }
        if (!DataisLoaded && !AllBackupDataIsLoaded && (apiError === null)) {
            return (
                <div className="loaderContainer">
                    <div className="loaderArea1">
                        <TransverseLoading />
                    </div>
                </div>
            )
        }
    
        ////////////////////////////////////////////////////
        // DEFINE FUNCTION deleteBackups
        ////////////////////////////////////////////////////
        const deleteBackup = (backupID) => {
            var fetchURL=`${sysgridApiUrl}/backups/delete`

            if(window.confirm(t("Do you really want to DELETE the Backup with ID: ") + backupID)) {
                fetch(`${fetchURL}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `${apiToken}`,
                        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                    },
                    body: JSON.stringify({
                        "id": backupID
                    })
                })
                .then((res) => {
                    return res.json()
                })
                .then(res => {
                    if ( res.error ) {
                        notify("error", `${res.error}`)
                    }
    
                    else if ( res.message ) {
                    notify("success", t("Backup successfully deleted"))
                    this.getAllData();
                    }
                })
                .catch((err) => {
                    err = JSON.parse(err)
                    console.log(err)
                    notify("error", `${err.err}`)
                })
            }
            else {
                notify("error", t("Backup NOT deleted"))
            }
        }

        return (
            <>
                <wc-toast></wc-toast>
                <IntroBoxHeading>
                    {t("Informations about this Menupoint")}
                </IntroBoxHeading>
                <IntroBoxContent>
                    {t("Here you can configure your VPN Certificates.")}
                </IntroBoxContent>
                <div className="panel inline">
                    <img src={wireguardAppIcon} style={{borderRadius: "22.5%", height: "40px"}}/>
                    &nbsp;&nbsp;
                    <h3>"Wireguard" VPN Client Download</h3>
                </div>

                <div className="spacer10px"></div>
                
                <div className="grid">
                    <div className="panel gridArea">
                        <a href="https://download.wireguard.com/windows-client/wireguard-installer.exe">
                            <div className="download-button">
                                <img src={windowsLogo} />
                                <div className="download-text">
                                    <span className="download-text1">Get it for</span>
                                    <span className="download-text2">Windows</span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="panel gridArea">
                        <a href="https://itunes.apple.com/us/app/wireguard/id1451685025?ls=1&mt=12">
                            <div className="download-button">
                                <img src={appleLogo} />
                                <div className="download-text">
                                    <span className="download-text1">Get it for</span>
                                    <span className="download-text2">Mac OS</span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="panel gridArea">
                        <a href="https://apps.apple.com/de/app/wireguard/id1441195209"> 
                            <img src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg" alt="Download im App Store" />
                        </a>
                    </div>
                    <div className="panel gridArea">
                        <a href="https://play.google.com/store/apps/details?id=com.wireguard.android">
                            <img alt="Google Play Store App" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" height="60" />
                        </a>
                    </div>
                    <div className="panel gridArea">
                        <a href="https://www.wireguard.com/install/">
                            <div className="download-button">
                                <div className="download-text">
                                    <span className="download-text1">Get it for</span>
                                    <span className="download-text2">other Systems</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="spacer20px"></div>
                
                <div className="headingPanelWithButton">
                    <h2 className={'headingLg'}><FontAwesomeIcon icon={faShieldAlt} />  {t("VPN")} </h2>
                    <button className="buttonGreen buttonInstall"  onClick={() => document.getElementById(`modal`).className = "modal"}>{t("New Config")}</button>
                </div>
                    {vpnClientConfigs.map((item) => (
                        <>
                            <div className="vpnContainer panel">
                                        <div className="vpnArea1">
                                            <h3>{item.clientName}</h3>
                                        </div>
                                        <div className="vpnArea2">
                                        </div>
                                        <div className="vpnArea3">
                                            <button className="buttonRed miniButton" onClick={e => this.deleteVpnConfig(item.clientName)}>Delete</button>
                                        </div>
                                        <div className="vpnArea4">
                                            <ToggleButton className="toggleButtonSmall" isAnimated closedButtonText={t('VPN Configuration')}>
                                                <div className="vpnArea4Inner">
                                                    <div className="vpnArea4QrCode">
                                                        <QRCode value={item.clientConfig} />
                                                        <button className="buttonBlue" onClick={(e) => this.handleDownloadClick(item.clientName, item.clientConfig)}>Download config</button>
                                                    </div>
                                                    <div className="vpnArea4Certinfo">
                                                        <h3>{t("Wireguard Config")}</h3>
                                                        <p style={{ whiteSpace: 'pre-wrap' }}>{item.clientConfig}</p>
                                                    </div>
                                                </div>
                                            </ToggleButton>
                                        </div>
                            </div>
                        </>
                    ))}
                    <div id="modal" className="hide">
                    <div className="modalInner">
                        <div>
                            <form className="vpnForm" onSubmit={this.handleSubmit()}>
                                <h1>{t("Create new VPN Config")}</h1>
                                <input name="clientName" placeholder="Client Name"></input>
                                <button name="create" className="buttonGreen">{t("Create")}</button>
                                <button className="buttonRed"  onClick={(e) => {e.preventDefault(); document.getElementById(`modal`).className = document.getElementById(`modal`).className.replace("modal", "hide")}}>{t("Close")}</button>
                            </form>
                            <br/>
                            </div>
                        </div>
                    </div>

            </>

    );
}
}

export default withTranslation("translations")(VPN);
