////////////////////////////////////////////////////
// RENDER COMPONENT
////////////////////////////////////////////////////
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from 'wc-toast'
import { sha256 } from 'js-sha256';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faPlayCircle, faUser, faExclamationCircle, faPencilAlt, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { checkAuthToken } from '../checkAuthToken.js'
import { checkRoles } from '../checkRoles.js'
import { withTranslation } from 'react-i18next';
import notify from "../notify.js"
import roles from "../config/roles.json"
import { IntroBoxHeading, IntroBoxContent } from '../components/IntroBox'

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const sha256Secret = process.env.REACT_APP_sha256Secret || "e41cc1c2d1a14b98e9987618db5cdda61247d7a3fe147828a6f8522bca155f6ee219056a6645394ac900877a3768bb4d4a5f9e13cedbf84f1a9ffb2fb0a0a0d8"
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"

////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}

class AdminDomains extends Component {
	constructor(props) {
		super(props);
		this.state =
		{
			domains: [],
            roleCheck: false,
            roleCheckEdit: false,
            DataisLoaded: false,
            apiError: null,
		}
	}


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION componentDidMount
    ////////////////////////////////////////////////////
    async componentDidMount() {
        this.setState({
            roleCheck: await checkRoles(roles.admin.domains),
            roleCheckEdit: await checkRoles(roles.admin.domainsEdit)
        })
        checkAuthToken()
        this.getDomains();
    }

    ////////////////////////////////////////////////////
    // DEFINE FUNCTION createDomain
    ////////////////////////////////////////////////////
    createDomain = item => event => {
       const { t } = this.props

       event.preventDefault();
       const secondLevelDomain = event.target.elements[0].value
       const topLevelDomain = event.target.elements[1].value

       try {
        notify("loading", t("Create new Domain"))
        fetch(`${sysgridApiUrl}/domains/createDomain`, {
               method: 'POST', // or 'PUT'
               headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': `${apiToken}`,
                   "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
               },
               body: JSON.stringify({
                   "subDomains": "",
                   "secondLevelDomain": secondLevelDomain,
                   "topLevelDomain": topLevelDomain,
               })
           })
           .then((res) => {
               return res.json()
           })
           .then(res => {
                   notify("success", `${res.message}`)
                   this.getDomains()
           })
           .catch((err) => {
               err = JSON.parse(err)
               console.log(err)
               notify("error", `${err.err}`)
           })
       }
       catch (e) {
           console.log(e)
       }
     }

     ////////////////////////////////////////////////////
     // DEFINE FUNCTION getDomains
     ////////////////////////////////////////////////////
     getDomains = async () => {
         await fetch(`${sysgridApiUrl}/domains/list`, {
                   mode: 'cors',
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json',
                       'Authorization': `${apiToken}`,
                       "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                   }
                 })
                 .then((res) => {
                     if ( res.status != "200") {
                         throw res.statusText
                     }
                     else {
                         return res.json()
                     }
                 })
                 .then((json) => {
                     this.setState({
                         domains: json,
                         DataisLoaded: true,
                         apiError: null
                     })
                 })
                 .catch( err => {
                     this.setState({
                         DataisLoaded: false,
                         apiError: err.toString()
                     })
                 })
     }

     ////////////////////////////////////////////////////
     // DEFINE FUNCTION deleteDomain
     ////////////////////////////////////////////////////
     deleteDomain = async (id) => {
         const { t } = this.props
         if(window.confirm(t("Do you really want to delete this Domain?"))) {
             notify("loading", t("Delete Domain with ID ") + ` ${id}`)
             await fetch(`${sysgridApiUrl}/domains/deleteDomain`, {
                       mode: 'cors',
                       method: "POST",
                       headers: {
                           'Accept': 'application/json',
                           'Content-Type': 'application/json',
                           'Authorization': `${apiToken}`,
                           "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                       },
                       body: JSON.stringify({
                           'id': `${id}`,
                       })
                     })
                     .then((res) => {
                         if ( res.status != "200") {
                             notify("error", `${res.statusText}`)
                             throw res.statusText
                         }
                         else {
                             notify("success", `Domain with ID ${id} deleted`)
                             this.getDomains()
                             return res.json()
                         }
                     })
                     .catch( err => {
                         this.setState({
                             DataisLoaded: false,
                             apiError: err.toString()
                         })
                     })
                     this.getDomains()
                 }
                 else {
                   notify("error", t("Domain not deleted!"))
                 }
     }
    render() {
        const { DataisLoaded, apiError, items, domains, roleCheck, roleCheckEdit} = this.state;
        const { t } = this.props


        if (roleCheck == true) {
            return (
                <>
                <wc-toast></wc-toast>
                <IntroBoxHeading>
                    {t("Informations about this Menupoint")}
                </IntroBoxHeading>
                <IntroBoxContent>
                    {t('This are the Domains which should be available when installing a new App. A Domain is the Webaddress under which a Website/Webapp is reachable. For example: sysgrid.de. A Subdomain is the part before the domain. For example: "cloud.sysgrid.de". In this Case "cloud" is the Subdomain and "sysgrid.de" is the Domain. The Toplevel Domain is the ending. For Example ".de" or ".com".')}
                </IntroBoxContent>

                <div className="adminUserContainer">
                    <div className="adminUserArea1 panelRed"><h2 className={'headingLg'}><FontAwesomeIcon icon={faUser} /> {t("Domain Administration")}</h2></div>
                    <div style={{height: "10px"}}></div>
                    <div className="adminUserArea2 panel"><h2 className={'headingLg'}>{t("Create New Domain")}</h2></div>
                    <div className="adminUserArea3 panel">
                        <form className="createUserForm" onSubmit={this.createDomain()}>
                            <input placeholder={t("Second Level Domain") + " (" + t("Example") + ': "google", "bing", "wikipedia")'}></input>
                            <input placeholder={t("Top Level Domain") + " (" + t("Example") + ': ".de", ".com", ".info")'}></input>
                            <button className="buttonGreen"><FontAwesomeIcon icon={faSave} /></button>
                        </form>
                    </div>
                    <div className="adminUserArea4 panel"><h2 className={'headingLg'}>{t("All Users")}</h2></div>

                    <div className="adminUserArea5 panel">
                        <table>
                            <th>Id</th>
                            <th>{t("Second Level Domain")}</th>
                            <th>{t("Top Level Domain")}</th>
                            {domains.map((domain) => (
                                <tr>
                                    <td>{domain.id}</td>
                                    <td>{domain.secondLevelDomain}</td>
                                    <td>{domain.topLevelDomain}</td>
                                    {roleCheckEdit == true &&
                                        <>
                                            <td><FontAwesomeIcon onClick={() => { this.deleteDomain(domain.id) }} icon={faTrash} /></td>
                                        </>
                                    }
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
                </>
            );
        }
        else {
            return(
                <>
                    <h1>No permission</h1>
                </>
            )
        }
	}
}
export default withTranslation("translations")(AdminDomains);
