////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const apiToken = process.env.REACT_APP_apiToken || "123"
const t = i18n.t;
const showIntroBox = localStorage.getItem("showIntroBox");

////////////////////////////////////////////////////
// DEFINE FUNCTION checkAuthToken
////////////////////////////////////////////////////

export function IntroBoxHeading({children}) {
    if(showIntroBox == "true") {
        return(
            <>
                <div className="introPanelHeading">
                <h2><FontAwesomeIcon icon={faInfoCircle}/> {children}</h2>
                </div>
            </>
        )
    }
    else {
        return(null)
    }
}

export function IntroBoxContent({children}) {
    if(showIntroBox == "true") {
        return(
            <>
                <div className="introPanelContent">
                    <span>{children}</span>
                </div>
            </>
        )
    }
    else {
        return(null)
    }
}
