////////////////////////////////////////////////////
// RENDER COMPONENT
////////////////////////////////////////////////////
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from 'wc-toast'
import { sha256 } from 'js-sha256';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faPlayCircle, faUser, faExclamationCircle, faPencilAlt, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { checkAuthToken } from '../checkAuthToken.js'
import { checkRoles } from '../checkRoles.js'
import { withTranslation } from 'react-i18next';
import notify from "../notify.js"
import roles from "../config/roles.json"
import { IntroBoxHeading, IntroBoxContent } from '../components/IntroBox'

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const sha256Secret = process.env.REACT_APP_sha256Secret || "e41cc1c2d1a14b98e9987618db5cdda61247d7a3fe147828a6f8522bca155f6ee219056a6645394ac900877a3768bb4d4a5f9e13cedbf84f1a9ffb2fb0a0a0d8"
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"

////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}

class AdminUsers extends Component {
	constructor(props) {
		super(props);
		this.state =
		{
			users: [],
            roleCheck: false,
            roleCheckEdit: false,
            DataisLoaded: false,
            apiError: null,
		}
	}


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION componentDidMount
    ////////////////////////////////////////////////////
    async componentDidMount() {
        this.setState({
            roleCheck: await checkRoles(roles.admin.users),
            roleCheckEdit: await checkRoles(roles.admin.userEdit)
        })
        checkAuthToken()
        this.getUsers();
    }

    ////////////////////////////////////////////////////
    // DEFINE FUNCTION createUser
    ////////////////////////////////////////////////////
    createUser = item => event => {
       const { t } = this.props

       event.preventDefault();
       const username = event.target.elements[0].value
       const firstname = event.target.elements[1].value
       const lastname = event.target.elements[2].value
       const email = event.target.elements[3].value
       const password = event.target.elements[4].value
       const passwordHash = sha256.hmac(sha256Secret, password)

       try {
        notify("loading", t("Create new User"))
        fetch(`${sysgridApiUrl}/users/createUser`, {
               method: 'POST', // or 'PUT'
               headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': `${apiToken}`,
                   "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
               },
               body: JSON.stringify({
                   "username": username,
                   "firstname": firstname,
                   "lastname": lastname,
                   "email": email,
                   "passwordHash": passwordHash,
               })
           })
           .then((res) => {
               return res.json()
           })
           .then(res => {
                   notify("success", `${res.message}`)
                   this.getUsers()
           })
           .catch((err) => {
               err = JSON.parse(err)
               console.log(err)
               notify("error", `${err.err}`)
           })
       }
       catch (e) {
           console.log(e)
       }
     }

     ////////////////////////////////////////////////////
     // DEFINE FUNCTION getUsers
     ////////////////////////////////////////////////////
     getUsers = async () => {
         await fetch(`${sysgridApiUrl}/users/list`, {
                   mode: 'cors',
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json',
                       'Authorization': `${apiToken}`,
                       "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                   }
                 })
                 .then((res) => {
                     if ( res.status != "200") {
                         throw res.statusText
                     }
                     else {
                         return res.json()
                     }
                 })
                 .then((json) => {
                     this.setState({
                         users: json,
                         DataisLoaded: true,
                         apiError: null
                     })
                 })
                 .catch( err => {
                     this.setState({
                         DataisLoaded: false,
                         apiError: err.toString()
                     })
                 })
     }

     ////////////////////////////////////////////////////
     // DEFINE FUNCTION deleteUser
     ////////////////////////////////////////////////////
     deleteUser = async (id, username) => {
         const { t } = this.props
         if(window.confirm(t("Do you really want to delete this User?"))) {
         notify("loading", t("Delete User ") + ` ${username}`)
         await fetch(`${sysgridApiUrl}/users/deleteUser`, {
                   mode: 'cors',
                   method: "POST",
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json',
                       'Authorization': `${apiToken}`,
                       "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                   },
                   body: JSON.stringify({
                       'id': `${id}`,
                       'username': `${username}`
                   })
                 })
                 .then((res) => {
                     if ( res.status != "200") {
                         notify("error", `${res.statusText}`)
                         throw res.statusText
                     }
                     else {
                         notify("success", `User ${username} deleted`)
                         this.getUsers()
                         return res.json()
                     }
                 })
                 .catch( err => {
                     this.setState({
                         DataisLoaded: false,
                         apiError: err.toString()
                     })
                 })
                 this.getUsers()
             }
             else {
               notify("error", t("User not deleted!"))
             }
     }
    render() {
        const { DataisLoaded, apiError, items, users, roleCheck, roleCheckEdit} = this.state;
        const { t } = this.props


        if (roleCheck == true) {
            return (
                <>
                <wc-toast></wc-toast>
                <IntroBoxHeading>
                    {t("Informations about this Menupoint")}
                </IntroBoxHeading>
                <IntroBoxContent>
                    {t("Here you are able to create new Users, Edit existing ones, set new Passwords for Users or edit the User Roles.")}
                </IntroBoxContent>

                <div className="adminUserContainer">
                <div className="adminUserArea1 panelRed"><h2 className={'headingLg'}><FontAwesomeIcon icon={faUser} /> {t("User Administration")}</h2></div>
                <div style={{height: "10px"}}></div>
                <div className="adminUserArea2 panel"><h2 className={'headingLg'}>{t("Create New User")}</h2></div>
                <div className="adminUserArea3 panel">
                    <form className="createUserForm" onSubmit={this.createUser()}>
                        <input placeholder={t("Username")}></input>
                        <input placeholder={t("Firstname")}></input>
                        <input placeholder={t("Lastname")}></input>
                        <input placeholder={t("E-Mail")}></input>
                        <input type="password" placeholder={t("Password")}></input>
                        <button className="buttonGreen"><FontAwesomeIcon icon={faSave} /></button>
                    </form>
                </div>
                <div className="adminUserArea4 panel"><h2 className={'headingLg'}>{t("All Users")}</h2></div>

                <div className="adminUserArea5 panel">
                <table>
                <th>Id</th>
                <th>{t("Username")}</th>
                <th>{t("Firstname")}</th>
                <th>{t("Lastname")}</th>
                <th>{t("E-Mail")}</th>
                <th>{t("Roles")}</th>
                <th>{t("Show Intro")}</th>
                {users.map((user) => (
                    <tr>
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.email}</td>
                    <td>{user.roles}</td>
                    <td>{user.showIntro}</td>
                    {roleCheckEdit == true &&
                        <>
                            <td><Link to={{pathname: "/admin/users/edit/", search: `?id=${user.id}`}}><FontAwesomeIcon icon={faPencilAlt} /></Link></td>
                            <td><FontAwesomeIcon onClick={() => { this.deleteUser(user.id, user.username) }} icon={faTrash} /></td>
                        </>
                    }
                    </tr>
                ))}
                </table>
                </div>
                </div>
                </>
            );
        }
        else {
            return(
                <>
                    <h1>No permission</h1>
                </>
            )
        }
	}
}
export default withTranslation("translations")(AdminUsers);
