import { toast } from 'wc-toast'

const toastDuration = process.env.REACT_APP_toastDuration || 5000

function createID(length) {
    var result           = '';
    var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
 charactersLength));
   }
   return result;
}

const getDateTime = ()=> {
  let date_ob = new Date();

  // current date
  // adjust 0 before single digit date
  let date = ("0" + date_ob.getDate()).slice(-2);
  // current month

  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

  // current year
  let year = date_ob.getFullYear();

  // current hours
  let hours = date_ob.getHours();

  // current minutes
  let minutes = date_ob.getMinutes();

  // current seconds
  let seconds = date_ob.getSeconds();

  let currentDateGerman = date + '.' + month + '.' + year
  let currentTimeGerman = hours + ':' + minutes + ':' + seconds

  let currentDateTimeGerman =  currentDateGerman + ' ' + currentTimeGerman

  return currentDateTimeGerman
}

const notify = async (type, message, options, promiseFunction) => {
    let promiseLoadingMessage = "loading"
    let promiseSuccessMessage = ""
    let promiseErrorMessage = ""

    if(promiseFunction){
        promiseLoadingMessage = message.loading
        promiseSuccessMessage = message.success
        promiseErrorMessage = message.error
    }
    if(!options) {
        options = { "duration": toastDuration }
    }
    switch (type) {
        case 'loading': toast.loading(message, options)
            break;
        case 'success': toast.success(message, options)
            break;
        case 'error': toast.error(message, options)
            break;
        case 'special': toast(message, {
            icon: {
            type: "custom",
            content: "🌟",
            },
        });
        break;
        case 'promise':
            const id = toast.loading(promiseLoadingMessage);
            new Promise(async (resolve, reject) => {
                let result = await promiseFunction()
                if(result.type == "error"){
                    reject(result)
                }
                else if (result.type == "success"){
                    resolve(result)
                }
            })
            .then((result) => {
                toast.dismiss(id);
                toast.success(promiseSuccessMessage || result.message, options);
            })
            .catch((error) => {
                toast.dismiss(id);
                toast.error(promiseErrorMessage || error.message, options);
            });
            break;
            // How to use promise:
            // in the function you want to use promise, you have to implement the function you want to run as a callback for the notify function.
            // For example:
            //           notify("promise", {loading: t("Installation started")}, {}, (resolve, reject) => {
            //           })
            // In the case of using "promise" as type with notify, the message argument has to be an object with these keys: {loading: <string // necessary> <success // optional> <error // optional>}
            // If you dont set the arguments success or error, the notification will display the return of the callback function as the notification text.
            // The return of the callback function has to be an object with the keys type and message like this: 
            // return({type: <"error" | "success">, message: <message>})
            // depending of the type of notification you want to display, you set the value of the key type to "success" or "error"
            // the empty json is for the notification options. for example: { "duration": toastDuration }
            // if the notification is related to a specific app or something, then insert the app name in the return message. for example:
            // return({type: "error", message: `${t("Error Installing App:")} ${item}\nError:\n${res.error}`})


    }
    let notifications
    let currentDateTime = getDateTime();

    if(localStorage.getItem('notifications')) {
        notifications = localStorage.getItem('notifications')
        let id=createID(6)
        notifications = notifications + ',' + `{ "type": "${type}", "id": "${id}", "message": "${message}", "readStatus": false, "dateTime": "${currentDateTime}"}`
    }
    else {
        let id=createID(6)
        notifications = `{ "type": "${type}", "id": "${id}", "message": "${message}", "readStatus": false, "dateTime": "${currentDateTime}"}`
    }
    localStorage.setItem("notifications", notifications);
}

export default notify
