
////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React from "react";
import { Link } from "react-router-dom";
import { useRef } from 'react';
import { toast } from 'wc-toast'
import { checkAuthToken } from './checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAppStore } from '@fortawesome/free-brands-svg-icons'
import { faInfoCircle, faShieldAlt, faCog, faSearch, faTv, faNewspaper, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import ToggleButton from 'terra-toggle-button';
import { withTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import notify from "./notify"
import i18next from 'i18next';
import { IntroBoxHeading, IntroBoxContent } from './components/IntroBox'
import Tooltip from './components/Tooltip';

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const newsUrl = process.env.newsApiUrl || "https://test.debrit.de/"
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/dockerApi"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const refreshInterval = process.env.REACT_APP_refreshDataInterval || 5000
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"

const appStoreAttributes = ['templates', 'envVariables'];

////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}


////////////////////////////////////////////////////
// DEFINE FUNCTION getData
////////////////////////////////////////////////////
class Search extends React.Component {
    // Constructor
    constructor(props) {
        super(props);
		const queryParams = new URLSearchParams(window.location.search)

        this.state = {
			searchValue: queryParams.get("searchValue"),
            domains: [],
            newsItems: [],
            newsSearchResults: [],
            newsDataIsLoaded: false,
            newsApiError: null,

            appStoreItems: [],
            appStoreDataIsLoaded: false,
            appStoreApiError: null,
			appStoreSearchResults: [],
			appStoreSearchItemsEnvVariables: {},
			appStoreSearchItemsLogos: {},
            appDescriptionClass: localStorage.getItem("appDescriptionClass"),
            envCheckBoxes: [],
			appsItems: [],
			appsSearchResults: [],
			appsDataIsLoaded: false,
			appsApiError: null,
			dockerItems: [],

            deleteDataStates: {}
        };
        this.myRef = React.createRef();

    }


    ////////////////////////////////////////////////////
	// COMPONENT DID AND WILL MOUNT
	////////////////////////////////////////////////////
    componentDidMount() {
        checkAuthToken()
        this.getNewsData();
        this.getAppStoreData();
		this.getAppsData();
        this.getDomains();
    }

    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getDomains
    ////////////////////////////////////////////////////
    getDomains = async () => {
        await fetch(`${sysgridApiUrl}/domains/list`, {
                  mode: 'cors',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `${apiToken}`,
                      "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                  }
                })
                .then((res) => {
                    if ( res.status != "200") {
                        throw res.statusText
                    }
                    else {
                        return res.json()
                    }
                })
                .then((json) => {
                    this.setState({
                        domains: json,
                        DataisLoaded: true,
                        apiError: null
                    })
                })
                .catch( err => {
                    this.setState({
                        DataisLoaded: false,
                        apiError: err.toString()
                    })
                })
    }

    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getNewsData
    ////////////////////////////////////////////////////
    getNewsData = async () => {
        await fetch(`${newsUrl}/wp-json/wp/v2/posts`, {
                  mode: 'cors',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `${apiToken}`,
                      "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                  }
                })
                            .then((res) => {
                if ( res.status != "200") {
                    throw res.statusText
                }
                else {
                    return res.json()
                }
            })
            .then((json) => {
                let newsItemsArray = json2array(json)
                this.setState({
                    newsItems: newsItemsArray,
                    apiError: null
                })
            })
            .catch( err => {
                this.setState({
                    newsDataIsLoaded: false,
                    apiError: err.toString()
                })
            })

            for(let result of this.state.newsItems){
                if(result.title.rendered.toLowerCase().includes(this.state.searchValue.toLowerCase()) || result.content.rendered.toLowerCase().includes(this.state.searchValue.toLowerCase())){
                    this.setState({
                        "newsSearchResults": [...this.state.newsSearchResults, result]
                    })
                }
            }

            this.setState({
                newsDataIsLoaded: true,
            })
    }

	////////////////////////////////////////////////////
    // DEFINE FUNCTION getAppsData
    ////////////////////////////////////////////////////
	getAppsData = async () => {
        await fetch(`${sysgridApiUrl}/projects`, {
            headers: {
                'Authorization': `${apiToken}`,
                'Accept': 'application/json',
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
            }
        })
            .then((res) => {
                if ( res.status != "200") {
                    throw res.statusText
                }
                else {
                    return res.json()
                }
            })
            .then((array) => {
                this.setState({
                    appsItems: array,
                })
            })
            .catch( err => {
                this.setState({
                    appsDataIsLoaded: false,
                    appsApiError: err.toString()
                })
            })
            this.setState({
                    rolloutStarted: false,
                    rolloutError: false,
            })

            fetch(`${dockerApiUrl}/containers/json?all=true`, {
                headers: new Headers({
                    'Authorization': `${apiToken}`,
                    "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                }),
            })
            .then((res) => {
                if ( res.status != "200") {
                    throw res.statusText
                }
                else {
                    return res.json()
                }
            })
            .then((allContainers) => {
				let allContainersArray = json2array(allContainers)
                this.setState({
                    dockerItems: allContainersArray,
                })
            })
            .catch( err => {
                console.log(err)
            })

			for(let result of this.state.appsItems){
				if( result.projectID.toLowerCase().includes(this.state.searchValue.toLowerCase()) || result.templateName.toLowerCase().includes(this.state.searchValue.toLowerCase()) ){
					let appsSearchResultsArray = [...this.state.appsSearchResults, `${result}`]

					this.setState({
						appsSearchResults: [...this.state.appsSearchResults, result],
					})
				}
			}
			this.setState({
				appsDataIsLoaded: true,
			})
    }

    getProjectDomain(composeProject, projectID, templateName){
        var allContainers = []
        var allContainersStates = []
        for (var container of this.state.dockerItems) {
            if (composeProject && composeProject.toLowerCase() === container.Labels["com.docker.compose.project"]) {
                allContainers.push(container);
            }
        }
        let domains = ""
        allContainers.map((container) => {
            console.log(`${projectID}_${templateName}`)
            console.log(`${container.Labels[`traefik.http.routers.${projectID}_${templateName}.rule`]}`)
            if (container.Labels[`traefik.enable`] == "true") {
                if(`${container.Labels[`traefik.http.routers.${projectID}_${templateName}.rule`]}`.includes(',')) {
                    domains = domains + `, ${container.Labels[`traefik.http.routers.${projectID}_${templateName}.rule`].split('(').pop().split(')')[0]}`
                }
                else {
                    domains = `${container.Labels[`traefik.http.routers.${projectID}_${templateName}.rule`].split('(').pop().split(')')[0]}`

                }
            } else if (`${container.Labels[`traefik.http.routers.${projectID}_${templateName}.rule`]}` == "undefined") {
                domains = "keine"
            }
        })
        return(domains)
    }
    
    ////////////////////////////////////////////////////
	// DEFINE FUNCTION getAppStoreData
	////////////////////////////////////////////////////
	getAppStoreData = async () => {
        await fetch(`${sysgridApiUrl}/templates`, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
            }
        })
            .then((res) => {
                if ( res.status != "200") {
                    throw res.statusText
                }
                else {
                    return res.json()
                }
            })
            .then((json) => {
                //json to array for every object in object enVariables
                var envVariablesArray = json2array(json.envVariables)
                var categorys = []
                
                for (let object of envVariablesArray) {
                    if (object["# categoryName"] != undefined && !categorys.includes(object["# categoryName"])) {
                      categorys.push(object["# categoryName"]);
                    }
                  }
    
                this.setState({
                    appStoreSearchResults: json.templates,
                    appStoreSearchItemsEnvVariablesArray: json2array(json.envVariables),
                    appStoreSearchItemsEnvVariables: json.envVariables,
                    categorys: categorys,
                    appStoreSearchItemsLogos: json.logos,
                    DataisLoaded: true
                })
    
                for(let result of this.state.appStoreSearchResults){
                    if(result.toLowerCase().includes(this.state.searchValue.toLowerCase())){
                        this.setState({
                            "appStoreSearchResults": [...this.state.appStoreSearchResults, `${result}`]
                        })
                    }
                }
                
                this.setState({
                    appStoreDataIsLoaded: true,
                })
            })
            .catch( err => {
                this.setState({
                    DataisLoaded: false,
                })
            })

    }


    ////////////////////////////////////////////////////
	// DEFINE FUNCTION handleSubmit
	////////////////////////////////////////////////////
    handleSubmit = item => event => {
        console.log(item)
        console.log(this.state.envCheckBoxes)
        event.preventDefault();
        var jsonVariables = {}
        var jsonResult = {}
        var fetchURL=`${sysgridApiUrl}/templates`

        let projectID = ""

        const { t } = this.props

        for (var element of event.target.elements) {
            var key = element.name
            var value = element.value
            if (key == "projectID") {
                projectID = value
            }
        }

        console.log(event.target.elements)
        notify("promise", {loading: `${t("Install App")}: ${projectID}`}, {}, () => {

            if (event.nativeEvent.submitter.name == "install") {
                var fetchURL=`${sysgridApiUrl}/templates`
            }
            else if (event.nativeEvent.submitter.name == "update") {
                var fetchURL=`${sysgridApiUrl}/templates/update`
            }
            if(event.nativeEvent.submitter.name == "install" || event.nativeEvent.submitter.name == "update") {
                for (var element of event.target.elements) {
                    var key = element.name
                    var value = element.value
                    if(element.className === "input" && key.startsWith("value_")){
                        if(this.state.envCheckBoxes[item] && this.state.envCheckBoxes[item][["checkbox__" + key.split("__")[1]]] && this.state.envCheckBoxes[item]["checkbox__" + key.split("__")[1]] == true) {
                            if( element.name.toLowerCase().includes("vpn_domain") ){
                                for(let domain of this.state.domains){
                                    if( element.value.split('.').slice(-2).join('.').toLowerCase() == domain.secondLevelDomain.toLowerCase() + "." + domain.topLevelDomain.toLowerCase() ) {
                                        const errorMessage = t("VPN Domain and Top Level Domain must not be the same as an Domain configured in the System")
                                        return({type: "error", message: errorMessage})
                                    }
                                }
                            }
                            jsonVariables[`${key}`] = value.toString()
                    }
                }
                else if (element.className && element.className === "input" && !key.startsWith("value_")) {
                    var value = element.value
                    jsonVariables[`${key}`] = value.toString()
                }
                else if (element.className && element.className === "input" && !key.startsWith("# templateName")) {
                    var value = element.value
                    jsonVariables[`${key}`] = value.toString()
                }
                else if (element.className && element.className === "checkbox") {
                    var key = element.name
                    var value = element.value
                    console.log("checkedicheck")
                    jsonVariables[`${key}`] = this.state.envCheckBoxes[item] && this.state.envCheckBoxes[item][["checkbox__" + key.split("__")[1]]] && this.state.envCheckBoxes[item]["checkbox__" + key.split("__")[1]] == true
                }
                else if (element && element.className && element.className === "templatesFormSubdomain") {
                        var subdomain = element.value
                        var domain = ""
                        var key = element.name

                        for (var element of event.target.elements) {
                            if (element.className === "templatesFormDomain") {
                                domain = element.value
                            }
                        }
                        let value
                        if(subdomain == ""){
                            value = domain

                        }
                        else {
                            value = subdomain + "." + domain
                        }

                        jsonVariables[`${key}`] = value
                }
            }
            jsonVariables["# templateName"] = item
            jsonResult[`${item}`] = jsonVariables
            
            try {
                return fetch(`${fetchURL}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `${apiToken}`,
                        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                    },
                    body: JSON.stringify({
                        "templateName": item,
                        "envVariables": jsonVariables,
                        "logo": this.state.appStoreSearchItemsLogos[`${item}`]
                    })
                })
                .then((res) => {
                    return res.json()
                })
                .then(res => {
                    if ( res.error ) {
                        return({type: "error", message: `${t("Error Installing App")}: ${item}\nError:\n${res.error}`})
                    }
                    else if ( res.message ) {
                        return({type: "success", message: `${t("App installed")}: ${projectID} (${item})`})
                    }
                })
                .catch((err) => {
                    console.log(err)
                    return({type: "error", message: `${t("Error Installing App")}: ${projectID} (${item})\nError:\n${err}`})
                })
            }
            catch (e) {
                console.log(e)
                return({type: "error", message: `${t("Error Installing App")}: ${projectID} (${item})\nError:\n${e}`})
            }
            }
        })
    }


    ////////////////////////////////////////////////////
  	// showVars Function
  	////////////////////////////////////////////////////
    // showVars = () => {
    //     if(this.state.showVars) {
    //       this.myRef.style="display: none"
    //         this.setState({
    //           showVars: false
    //         })
    //     }
    //     else if(!this.state.showVars) {
    //       this.myRef.style="display: block"
    //       this.setState({
    //         showVars: true
    //       })        }
    // }

    ////////////////////////////////////////////////////
   	// RENDER COMPONENT
   	////////////////////////////////////////////////////
    render() {
        const { domains, newsDataIsLoaded, appStoreDataIsLoaded, appsDataIsLoaded, appsApiError, items, appDescriptionClass, appStoreSearchItemsEnvVariables} = this.state;
        const { t } = this.props

		const getContainer = (projectID, templateName) => {
            var allContainers = []
            var allContainersStates = []
            
            if (this.state.dockerItems.length > 0) {
                for (var container of this.state.dockerItems) {
                    if (projectID != undefined && `${projectID}`.replace(".","").toLowerCase() + "_" + `${templateName}`.toLowerCase() === container.Labels["com.docker.compose.project"].toLowerCase()) {
                        allContainers.push(container);
                    }
                }
            }
            return(
                <>

                <table className="projectsContainerlistTable">
                    {allContainers.map((container) => (
                        <tr className="projectsContainerlistContainer">
                            {(container.State.toLowerCase() === 'created' &&
                                <td className="statusDotTd"><div className={`statusDotRed`}></div></td>
                            )}
                            {(container.State.toLowerCase() === 'running' &&
                                <td className="statusDotTd"><div className={`statusDotGreen`}></div></td>
                            )}
                            {(container.State.toLowerCase() === 'restarting' &&
                                <td className="statusDotTd"><div className={`statusDotYellow`}></div></td>
                            )}
                            {(container.State.toLowerCase() === 'exited' &&
                                <td className="statusDotTd"><div className={`statusDotRed`}></div></td>
                            )}
                            {(container.State.toLowerCase() === 'paused' &&
                                <td className="statusDotTd"><div className={`statusDotYellow`}></div></td>
                            )}
                            {(container.State.toLowerCase() === 'dead' &&
                                <td className="statusDotTd"><div className={`statusDotBlack`}></div></td>
                            )}
                            <td>
                                <Link to={`/container?containerID=${container.Id}&templateName=${templateName}&projectID=${projectID}`}><a>{container.Names[0].substring(1)}</a></Link>
                            </td>
                        </tr>
                    ))}
                    </table>
                </>
            )
        }

        const deleteProject = (composeProject, projectID, templateName) => {
            if(window.confirm(t("Do you really want to delete this app?"))) {
                var allContainers = []
                var allContainersStates = []
                for (var container of this.state.dockerItems) {
                    if(container.Labels["com.docker.compose.project"]){
                        if (composeProject && composeProject.toLowerCase() === container.Labels["com.docker.compose.project"].toString().toLowerCase()) {
                            //deleteContainer(container.Id)
                            deleteContainer(container.Id)
                        }
                    }
                }
                if (this.state.deleteDataStates[`${projectID}_${templateName}`] == true) {
                    deleteProjectData(projectID, templateName)
                    deleteProjectDB(projectID, templateName)
                    this.getData();
                }
                else {
                    deleteProjectDB(projectID, templateName)
                    this.getData();
                }
            }
            else {
                notify("error", t("App not deleted!"))
            }
        }

        const deleteProjectData = async (projectID, templateName) =>  {
            try {
                const result = await fetch(`${sysgridApiUrl}/projects/deleteData`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Authorization': `${apiToken}`,
                        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "projectID": `${projectID}`,
                        "templateName": `${templateName}`,
                    })
                })
                const logMessage = await result
                notify("success", t("App Data deleted"))
            } catch (e) {
                console.log(e)
            }
        };

		const deleteProjectDB = async (projectID, templateName) =>  {
		        try {
		        const result = await fetch(`${sysgridApiUrl}/projects/delete`, {
		            method: 'POST', // or 'PUT'
		            headers: {
                        'Authorization': `${apiToken}`,
                        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                        'Accept': 'application/json',
		                'Content-Type': 'application/json'
		            },
		            body: JSON.stringify({
		                "projectID": `${projectID}`,
		                "templateName": `${templateName}`,
		            })
		        })
		        const logMessage = await result
            notify("success", t("App deleted"))
		    } catch (e) {
		        console.log(e)
		    }
		};

		const deleteContainer = async (Id, projectID, templateName) => {
		  const url = `${dockerApiUrl}/containers/${Id}?force=true`

		  try {
		    const result = await fetch(url, {
		                                      method: 'DELETE', // or 'PUT'
		                                      headers: {
                                                  'Authorization': `${apiToken}`,
                                                  "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                                                  'Accept': 'application/json',
		                                          'Content-Type': 'application/json'
		                                      },
		                                      body: JSON.stringify(''),
		                                    })
		    const logMessage = await result
        notify("success", t("Container deleted"))
		  } catch (e) {
		    console.log(e)
		  }
		};

        const changeDeleteDataState = (projectID, templateName) => {
            let deleteDataStates = this.state.deleteDataStates

            if(Object.keys(deleteDataStates).length < 1 ) {
                console.log("checked first time")
                deleteDataStates[`${projectID}_${templateName}`] = true
                console.log(`was NOT checked. is now ` + deleteDataStates[`${projectID}_${templateName}`])
            }
            else if (deleteDataStates[`${projectID}_${templateName}`] == undefined) {
                deleteDataStates[`${projectID}_${templateName}`] = true
                console.log(`was NOT CHECKED. is now ` + deleteDataStates[`${projectID}_${templateName}`])
            }
            else if (deleteDataStates[`${projectID}_${templateName}`] == true) {
                deleteDataStates[`${projectID}_${templateName}`] = false
                console.log(`was CHECKED. is now ` + deleteDataStates[`${projectID}_${templateName}`])
            }
            else if (deleteDataStates[`${projectID}_${templateName}`] == false) {
                deleteDataStates[`${projectID}_${templateName}`] = true
                console.log(`was NOT checked. is now ` + deleteDataStates[`${projectID}_${templateName}`])
            }
            this.setState(prevstate => ({
                ...prevstate,
                deleteDataStates: deleteDataStates
            }))
        }

        return (
            <>
                <wc-toast></wc-toast>
                <IntroBoxHeading>
                    {t("Informations about this Menupoint")}
                </IntroBoxHeading>
                <IntroBoxContent>
                    {t("Here you can Search for whatever you want. The Search includes News Items, Apps from the AppStore and Apps you have installed.")}
                </IntroBoxContent>

				<div className="searchContainer">
					<div className="searchArea1">
						<div className="panel"><h2 className={'headingLg'}><FontAwesomeIcon icon={faSearch} />  {t("Search Results")}</h2></div>
					</div>

                    <div className="searchArea2">
						<div className="panel"><h3 className={'headingLg'}><FontAwesomeIcon icon={faNewspaper} />  {t("News")}</h3></div>
					</div>
                    {!newsDataIsLoaded ?
						<div>
							<h1> News loading... </h1>
						</div>
					:
						<div className="searchArea3">
                            <div className="homeNewsContainer homeNewsOverwriteMargin">
                                <div className='homeNewsArea2 homeNewsOverwriteMargin'>
                                    {this.state.newsSearchResults.map((newsItem) => (
                                        <>
                                            <div className="panel homeNewsArea2Panel">
                                                <p className="homeNewsArea2Meta">{newsItem.date.replace(/T/g, ' ')}</p>
                                                <h2>{newsItem.title.rendered}</h2>
                                                <div dangerouslySetInnerHTML={{__html: `${newsItem.content.rendered}`}} />
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
						</div>
					}
                    {
                        newsDataIsLoaded && this.state.newsSearchResults.length < 1 ?
                            <div className="searchArea3">
                            <div className="homeNewsContainer homeNewsOverwriteMargin">
                                <div className='homeNewsArea2 homeNewsOverwriteMargin'>
                                        <>
                                            <div className="panel homeNewsArea2Panel">
                                                <p>{t("No Search Results found")}</p>
                                            </div>
                                        </>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

					<div className="searchArea4">
						<div className="panel"><h3 className={'headingLg'}><FontAwesomeIcon icon={faAppStore} />  {t("AppStore")}</h3></div>
					</div>
					{!appStoreDataIsLoaded ?
						<div>
							<h1> AppStore loading... </h1>
						</div>
					:
                    <div className="searchArea5">
                    <div className="templatesContainer">
                        {this.state.appStoreSearchResults.map((appStoreItem) => (
                            <>
                                <div className="templatesArea1 panel">
                                    <div className="templatesArea1Area1">
                                        <div className="badge" style={{backgroundColor: `${this.state.appStoreSearchItemsEnvVariables[appStoreItem]['# categoryColor']}`, color: `${this.state.appStoreSearchItemsEnvVariables[appStoreItem]['# categoryTextColor']}`}}>{this.state.appStoreSearchItemsEnvVariables[appStoreItem]['# categoryName']}</div>
                                    </div>
                                    <div className="templatesArea1Area2">
                                        <img className="templateLogo" src={`data:image/png;base64,${this.state.appStoreSearchItemsLogos[`${appStoreItem}`]}`} alt=""/>
                                    </div>
                                    <div className="templatesArea1Area3">
                                        <h3>{appStoreItem}</h3>
                                    </div>
                                    <div className="templatesArea1Area4">
                                        <p className={`${appDescriptionClass}`}>{this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`]["# appDescription" + "_" + i18next.language] ? <FontAwesomeIcon icon={faInfoCircle} /> : ""}&nbsp;
                                        {this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`]["# appDescription" + "_" + i18next.language]}</p>
                                    </div>
                                    <button className="buttonGreen buttonInstall"  onClick={() => document.getElementById(`${appStoreItem}`).className = "modal"}>{t("Install")}</button>
                                    </div>
                                    <div id={appStoreItem} className="hide">
                                    <div className="modalInner">
                                        <form spellcheck="false" className="templatesForm" onSubmit={this.handleSubmit(`${appStoreItem}`)}>
                                            <div className="spacer20px"></div>

                                            <h2>{appStoreItem} {t("Configuration")}</h2>

                                            <div className="spacer20px"></div>
                                            <p>{this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`]["# appDescription" + "_" + i18next.language] ? <FontAwesomeIcon icon={faInfoCircle} /> : ""}&nbsp;
                                            {this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`]["# appDescription" + "_" + i18next.language]}</p>

                                            <div className="spacer20px"></div>

                                            {Object.keys(this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`]).map((keyName, i) => (
                                                (keyName.toLowerCase().split("__")[0] === "domain" ?
                                                    <>
                                                        {this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                            <>
                                                                <Tooltip style="display: inline">
                                                                    {this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                </Tooltip>
                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                        <input className="templatesFormSubdomain" name={`${keyName}`} placeholder="Subdomain"/>
                                                        .<select key={`${appStoreItem}_${keyName}`} name={`${keyName}`} className="templatesFormDomain">
                                                            {domains.map((domain) => (
                                                                <option key={`${appStoreItem}_${keyName}`} name={`${keyName}`} value={domain.secondLevelDomain != "" ? domain.secondLevelDomain + "." + domain.topLevelDomain : domain.topLevelDomain} className="templatesFormDomainDropDownContent">{domain.secondLevelDomain}.{domain.topLevelDomain}</option>
                                                            ))}
                                                        </select>
                                                    </>
                                                :
                                                keyName.toLowerCase().startsWith("checkbox__") ?
                                                    <>
                                                        {!keyName.toLowerCase().startsWith("#") ?
                                                            <>
                                                                <div>
                                                                    {this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                        <>
                                                                            <Tooltip style="display: inline">
                                                                                {this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                            </Tooltip>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                    }
                                                                    <label htmlFor={`${appStoreItem}_${keyName}`}>
                                                                        {keyName.replace("checkbox__", "").replace(/_/g, " ")}: 
                                                                    </label>
                                                                    <input onClick={
                                                                        () => {
                                                                            this.setState(prevState => ({
                                                                                envCheckBoxes: {
                                                                                    ...prevState.envCheckBoxes,
                                                                                    [appStoreItem]: {
                                                                                        ...prevState.envCheckBoxes[appStoreItem],
                                                                                        [keyName]: this.state.envCheckBoxes[appStoreItem] && this.state.envCheckBoxes[appStoreItem][keyName] ? false : true
                                                                                    } 
                                                                                }
                                                                            }))
                                                                        }
                                                                    } 
                                                                    value={this.state.envCheckBoxes[appStoreItem] && this.state.envCheckBoxes[appStoreItem][keyName] || false}
                                                                    checked={this.state.envCheckBoxes[appStoreItem] && this.state.envCheckBoxes[appStoreItem][keyName] || false}
                                                                    type="checkbox" key={`${appStoreItem}_${keyName}`} name={`${keyName}`} className="checkbox" placeholder={keyName.replace(/_/g, " ")}
                                                                    />
                                                                </div>
                                                            </>
                                                        :
                                                        <></>
                                                        }
                                                    </>
                                                :
                                                    this.state.envCheckBoxes[appStoreItem] && this.state.envCheckBoxes[appStoreItem][["checkbox__" + keyName.split("__")[1]]] && this.state.envCheckBoxes[appStoreItem]["checkbox__" + keyName.split("__")[1]] == true && keyName.toLowerCase().startsWith("value_") ?
                                                    <>
                                                        {!keyName.toLowerCase().startsWith("#") ?
                                                            (keyName.toLowerCase().split("__")[2] === "domain" ?
                                                                <>
                                                                    {this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                        <>
                                                                            <Tooltip style="display: inline">
                                                                                {this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                            </Tooltip>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                    }
                                                                    <input className="templatesFormSubdomain" name={`${keyName}`} placeholder="Subdomain"/>
                                                                    .<select key={`${appStoreItem}_${keyName}`} name={`${keyName}`} className="templatesFormDomain">
                                                                        {domains.map((domain) => (
                                                                            <option key={`${appStoreItem}_${keyName}`} name={`${keyName}`} value={domain.secondLevelDomain != "" ? domain.secondLevelDomain + "." + domain.topLevelDomain : domain.topLevelDomain} className="templatesFormDomainDropDownContent">{domain.secondLevelDomain}.{domain.topLevelDomain}</option>
                                                                        ))}
                                                                    </select>
                                                                </>
                                                            :
                                                                <>
                                                                    <div>
                                                                        {this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                            <>
                                                                                <Tooltip style="display: inline">
                                                                                    {this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                                </Tooltip>
                                                                            </>
                                                                            :
                                                                            <>
                                                                            </>
                                                                        }
                                                                        <input key={`${appStoreItem}_${keyName}`} name={`${keyName}`} className="input" placeholder={keyName.split("__")[2].replace(/_/g, " ")}/>
                                                                    </div>
                                                                </>
                                                            )
                                                        :
                                                        <></>
                                                        }
                                                    </>
                                                :
                                                keyName.toLowerCase() == "db_password" ?
                                                    <><input type="hidden" key={`${appStoreItem}_${keyName}`} name={`${keyName}`} className="input" placeholder={keyName.replace(/_/g, " ")} value={"%RANDOM%"}/></>
                                                :
                                                !keyName.toLowerCase().startsWith("value_") ?
                                                    <>
                                                        {!keyName.toLowerCase().startsWith("#") ?
                                                            <>
                                                                <div>
                                                                    {this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                        <>
                                                                            <Tooltip style="display: inline">
                                                                                {this.state.appStoreSearchItemsEnvVariables[`${appStoreItem}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                            </Tooltip>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                    }
                                                                        <input key={`${appStoreItem}_${keyName}`} name={`${keyName}`} className="input" placeholder={keyName.replace(/_/g, " ")} value={this.state.appStoreSearchItemsEnvVariables[`${keyName}`]}/>
                                                                </div>
                                                            </>
                                                        :
                                                        <></>
                                                        }
                                                    </>
                                                :
                                                <></>
                                                )
                                            ))}
                                            <br/>
                                            <button name="install" className="buttonGreen">{t('Install')}</button>
                                            <button name="update" className="buttonBlue">{t('Reconfigure')}</button>
                                            <button className="buttonRed"  onClick={(e) => {e.preventDefault(); document.getElementById(`${appStoreItem}`).className = document.getElementById(`${appStoreItem}`).className.replace("modal", "hide")} }>{t("Close")}</button>
                                        </form>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
					}
                    {
                        appStoreDataIsLoaded && this.state.appStoreSearchResults.length < 1 ?
                            <div className="searchArea5">
                            <div className="homeNewsContainer homeNewsOverwriteMargin">
                                <div className='homeNewsArea2 homeNewsOverwriteMargin'>
                                        <>
                                            <div className="panel homeNewsArea2Panel">
                                                <p>{t("No Search Results found")}</p>
                                            </div>
                                        </>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

					<div className="searchArea6">
						<div className="panel"><h3 className={'headingLg'}><FontAwesomeIcon icon={faTv} />  {t("Apps")}</h3></div>
					</div>
					{!appsDataIsLoaded ?
						<div>
							<h1> Apps loading... </h1>
						</div>
					:

						<div className="searchArea7">
							<div className="templatesContainer">
							{this.state.appsSearchResults.map((item) => (
								<>
                                    <div className="projectsArea1 panel">
                                        <div className="projectsArea1Area1">
                                            {console.log(`${item.logo}`)}
                                            <img className="templateLogo" src={`data:image/png;base64,${item.logo}`} alt=""/>
                                        </div>
                                        <div className="projectsArea1Area2">
                                            <h3>{item.projectID}</h3>
                                        </div>
                                        <div className="projectsArea1Area3">
                                            <span><strong>{t("App Name")}:</strong>&nbsp;{item.templateName}</span>
                                            {item.publicDomains.map((publicDomain) => {
                                                return (
                                                    <span><strong>{t("Domain")}:</strong>&nbsp;<a href={`https://${publicDomain}`}>{publicDomain}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></a></span>
                                                )
                                            })}
                                            {item.vpnDomains.map((vpnDomain) => {
                                                return (
                                                    <span><strong>{t("VPN Domain")}:</strong>&nbsp;<a href={`https://${vpnDomain}`}>{vpnDomain}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></a></span>
                                                )
                                            })}
                                        </div>
                                        <ToggleButton className="toggleButtonText" isAnimated closedButtonText={t('More')}>
                                            <div className="projectsArea1Area4">
                                                {getContainer(item.projectID, item.templateName)}
                                            </div>
                                            <div className="projectsArea1Area5">
                                                <div className="projectsArea1Area5CheckboxArea" onClick={e => changeDeleteDataState(item.projectID, item.templateName)}>
                                                    <input type="checkbox" id={`${item.projectID}_${item.templateName}`} checked={this.state.deleteDataStates[`${item.projectID}_${item.templateName}`]} />
                                                    <label>{t("Delete App Data")}</label>
                                                </div>
                                                <div style={{ width: "100%" }}></div>
                                                <button className={`buttonRed containerProjectsButton`} onClick={e => deleteProject(item.projectID + "_" + item.templateName, item.projectID, item.templateName)}>{t("Delete")}</button>
                                            </div>
                                        </ToggleButton>

                                        <div className="projectsArea1Area5">
                                            {item.publicDomains.map((publicDomain) => {
                                                return (
                                                        <a href={`https://${publicDomain}`} target="_blank"><button className="buttonGreen">{t("Open App")}</button></a>
                                                )
                                            })}
                                            {item.vpnDomains.map((vpnDomain) => {
                                                return (
                                                        <a href={`http://${vpnDomain}`} target="_blank"><button className="buttonGreen"><FontAwesomeIcon icon={faShieldAlt} /> {t("Open App in VPN")}</button></a>
                                                    )
                                            })}                                        </div>
                                    </div>
								</>
							))}
			                </div>
						</div>
					}
                    {
                        appsDataIsLoaded && this.state.appsSearchResults.length < 1 ?
                            <div className="searchArea7">
                            <div className="homeNewsContainer homeNewsOverwriteMargin">
                                <div className='homeNewsArea2 homeNewsOverwriteMargin'>
                                        <>
                                            <div className="panel homeNewsArea2Panel">
                                                <p>{t("No Search Results found")}</p>
                                            </div>
                                        </>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>


            </>
    );
}
}

export default withTranslation("translations")(Search);
