////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React from "react";
import { Link } from "react-router-dom";
import { useRef } from 'react';
import { toast } from 'wc-toast'
import { checkAuthToken } from '../checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faTv, faCog, faExternalLinkAlt, faServer, faWrench, faBan, faSave } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';
import ToggleButton from 'terra-toggle-button';
import notify from "../notify.js"
import i18n from '../i18n.js';
import { TransverseLoading } from 'react-loadingg';
import { deleteContainer } from '../containerControll.js';
import { IntroBoxHeading, IntroBoxContent } from '../components/IntroBox.js'
import { ClockLoader } from 'react-spinners';
import { FaPlus, FaPlay, FaCheck } from "react-icons/fa";
import { FaX } from "react-icons/fa6";

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const sysgridVpnReverseproxyApiUrl = process.env.REACT_APP_sysgridVpnReverseproxyApiUrl || "http://localhost:9002/vpnReverseproxyApi"
const sysgridBindApiUrl = process.env.REACT_APP_sysgridBindApiUrl || "http://localhost:9002/bindApi"
const refreshInterval = process.env.REACT_APP_refreshDataInterval || 5000
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"
const t = i18n.t;

////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}

// const deleteContainer = async (Id, projectID, templateName) => {
//   const url = `${apiReverseproxyUrl}/containers/${Id}?force=true`
//
//   try {
//     const result = await fetch(url, {
//                                       method: 'DELETE', // or 'PUT'
//                                       headers: {
//                                           'Authorization': `${apiToken}`,
//                                           "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",                                          "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",                                          'Accept': 'application/json',
//                                           'Content-Type': 'application/json'
//                                       }
//                                   })
//     const logMessage = await result
//     console.log(logMessage)
//     notify("success", t("Container deleted"))
//   } catch (e) {
//     console.log(e)
//   }
// };

class AdminSecurityIpBlacklist extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            blacklistItems: [],
            dockerItems: [],
            runningChecks: [],
            DataisLoaded: true,
            apiError: null,
            rolloutStarted: false,
            setIntervalId: "",
            deleteDataStates: {},
            allSystems: [],
            newEntryIpAddress: "",
            newEntryReason: "",
            newEntryBlockedDays: ""
        };
    }



    ////////////////////////////////////////////////////
    // COMPONENT DID AND WILL MOUNT
    ////////////////////////////////////////////////////
    componentDidMount() {
        checkAuthToken()
        // need to make the initial call to getData() to populate
        // data right away
        this.getData();
        // Now we need to make it run at a specified interval
        // var setIntervalId = setInterval(this.getData, refreshInterval); // runs every 5 seconds.
        // this.setState({
        //     setIntervalId: setIntervalId
        // })
    }

    getAllData = async () => {
        await this.getData();
    }

    componentWillUnmount() {
        clearInterval(this.state.setIntervalId)
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getData
    ////////////////////////////////////////////////////
	getData = async () => {
        await fetch(`${sysgridApiUrl}/security/ipBlacklist`, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((res) => {
            if ( res.status != "200") {
                throw res.statusText
            }
            else {
                return res.json()
            }
        })
        .then((array) => {
            //json to array for every object in object enVariables
            this.setState({
                blacklistItems: array,
                DataisLoaded: true
            })
        })
        .catch( err => {
            this.setState({
                DataisLoaded: false,
                apiError: err.toString()
            })
        })
        this.setState({
                rolloutStarted: false,
                rolloutError: false,
        })
    }

    handleDeleteIpBlock = async(item) => {
        const id = item.id
        notify("promise", {loading: `${t("Deleting IP Blacklist Entry")}`}, {}, () => {
            return fetch(`${sysgridApiUrl}/security/ipBlacklist/delete`, {
                method: "POST",
                headers: {
                    'Authorization': `${apiToken}`,
                    "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'id': id
                })
            })
            .then((res) => {
                return(res.json())
            })
            .then((res) => {
                if ( res.error ) {
                    this.getData()
                    return({type: "error", message: `${t("Error deleting IP Blacklist Entry")}`})
                }
                else if ( res.message ) {
                    this.getData()
                    return({type: "success", message: `${t("Successfully deleted IP Blacklist Entry")}`})
                }
            })
            .catch( err => {
                return(err)
            })
        })
    }

    handleAddIpBlock = async(e) => {
        e.preventDefault()

        notify("promise", {loading: `${t("Deleting IP Blacklist Entry")}`}, {}, () => {
            return fetch(`${sysgridApiUrl}/security/ipBlacklist/add`, {
                method: "POST",
                headers: {
                    'Authorization': `${apiToken}`,
                    "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "ipAddress": this.state.newEntryIpAddress,
                    "reason": this.state.newEntryReason,
                    "blockedDurationsDays": this.state.newEntryBlockedDays
                })
            })
            .then((res) => {
                return(res.json())
            })
            .then((res) => {
                if ( res.error ) {
                    this.getData()
                    return({type: "error", message: `${t("Error adding IP Blacklist Entry")}`})
                }
                else if ( res.message ) {
                    this.getData()
                    return({type: "success", message: `${t("Successfully added IP Blacklist Entry")}`})
                }
            })
            .catch( err => {
                return(err)
            })
        })
    }
    ////////////////////////////////////////////////////
    // RENDER COMPONENT
    ////////////////////////////////////////////////////
    render() {
        const { blacklistItems, DataisLoaded, AllSystemDataIsLoaded, apiError, runningChecks } = this.state;
        const { t } = this.props

        if (!DataisLoaded && !AllSystemDataIsLoaded && (apiError !== null)) {
            return (
                <div>
                    <h1> {t("Error while retrieving data from API")}:</h1>
                    <h2>{apiError}</h2>
                </div>
            )
        }
        if (!DataisLoaded && !AllSystemDataIsLoaded && (apiError === null)) {
            return (
                <div className="loaderContainer">
                    <div className="loaderArea1">
                        <TransverseLoading />
                    </div>
                </div>
            )
        }

        return (
            <>
                <wc-toast></wc-toast>
                <IntroBoxHeading>
                    {t("Informations about this Menupoint")}
                </IntroBoxHeading>
                <IntroBoxContent>
                    {t("Here you can create configure Systemsettings and run special commands.")}
                </IntroBoxContent>

                <div className="panel"><h2 className={'headingLg'}><FontAwesomeIcon icon={faBan} />  {t("IP Blacklist")}</h2></div>
                <div className="securityIpBlacklistContainer ">
                    <div className="securityIpBlacklistArea1 panel">
                        <form className="createUserForm">
                            <input onChange={(e) => this.setState({"newEntryIpAddress": e.target.value})} placeholder={t("IP Address")}></input>
                            <input onChange={(e) => this.setState({"newEntryReason": e.target.value})} placeholder={t("Reason")}></input>
                            <input onChange={(e) => this.setState({"newEntryBlockedDays": e.target.value})} type="number" placeholder={t("Blocked for Days")}></input>
                            <button className="buttonGreen"><FontAwesomeIcon onClick={(e) => this.handleAddIpBlock(e)} icon={faSave} /></button>
                        </form>
                    </div>
                    <div className="securityIpBlacklistArea2 panel">
                        <table>
                            <tr>
                                <th>{t("ID")}</th>
                                <th>{t("IP Address")}</th>
                                <th>{t("Reason")}</th>
                                <th>{t("Blocked until")}</th>
                                <th>{t("Date created")}</th>
                            </tr>
                            {blacklistItems.length > 0 && blacklistItems.map((item) => (
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.ipAddress}</td>
                                    <td>{item.reason}</td>
                                    <td>{item.blockedUntil}</td>
                                    <td>{item.dateCreated}</td>
                                    <td><button onClick={(e) => this.handleDeleteIpBlock(item)} className="buttonRed">{t("Delete")}</button></td>
                                </tr>        
                            ))}
                        </table>
                    </div>                                       
                </div>
            </>
        );
    }
}

export default withTranslation("translations")(AdminSecurityIpBlacklist);
