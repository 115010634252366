////////////////////////////////////////////////////
// RENDER COMPONENT
////////////////////////////////////////////////////
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from 'wc-toast'
import { sha256 } from 'js-sha256';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faPlayCircle, faUser, faSave } from "@fortawesome/free-solid-svg-icons";
import { checkAuthToken } from './checkAuthToken.js'
import queryString from "query-string"
import { withTranslation } from 'react-i18next';
import notify from "./notify"
import { TransverseLoading } from 'react-loadingg';
import qrcode from 'qrcode';
import { totp, generateKey, getKeyUri } from "otp-io";
import { hmac, randomBytes } from "otp-io/crypto";
import { QRCode } from "react-qr-code";
import { Buffer } from "buffer";

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const sha256Secret = process.env.REACT_APP_sha256Secret || "e41cc1c2d1a14b98e9987618db5cdda61247d7a3fe147828a6f8522bca155f6ee219056a6645394ac900877a3768bb4d4a5f9e13cedbf84f1a9ffb2fb0a0a0d8"
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"

const queryParams = queryString.parse(window.location.search)

////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}

class User extends Component {
	constructor(props) {
		super(props);
		this.state =
		{
			"items": [],
            "envVariables": {},
            "envVariablesArray": [],
            "logos": {},
            "DataisLoaded": false,
            "apiError": null,
            "test": {},
            "qrGenerated": false,
            "otpNewDeviceUrl": "",
            "userInput2FaDeviceName": "",
            "otpData": {},
            "otpDataisLoaded": false,
            "otpObject": {}
		}
	}

    ////////////////////////////////////////////////////
    // DEFINE FUNCTION componentDidMount
    ////////////////////////////////////////////////////
    async componentDidMount() {
        checkAuthToken()
        this.getUserData();
    }

    ////////////////////////////////////////////////////
    // DEFINE FUNCTION handleSubmit
    ////////////////////////////////////////////////////
    handleSubmit = item => event => {
        event.preventDefault();
        const { t } = this.props
 
        const username = localStorage.getItem("username")
        const oldPassword = event.target.elements[0].value
        const newPassword = event.target.elements[1].value
        const newPasswordConfirmation = event.target.elements[2].value
 
        const oldPasswordHash = sha256.hmac(sha256Secret, oldPassword)
        const newPasswordHash = sha256.hmac(sha256Secret, newPassword)
 
        if (newPassword !== newPasswordConfirmation) {
            notify("error", t("Passwords don't match"))
        }
        else if (newPassword === newPasswordConfirmation) {
            try {
                 notify('promise', { loading: `${t('Changing Password')}` }, {}, async () => {
                     try {
                         return fetch(`${sysgridApiUrl}/users/changePassword`,{
                             method: 'POST', // or 'PUT'
                             headers: {
                                 'Accept': 'application/json',
                                 'Content-Type': 'application/json',
                                 'Authorization': `${apiToken}`,
                                 "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                             },
                             body: JSON.stringify({
                                 "username": username,
                                 "oldPasswordHash": oldPasswordHash,
                                 "newPasswordHash": newPasswordHash,
                             })
                         })
                         .then((res) => {
                             if ( res.status != "200") {
                                 throw res.statusText
                             }
                             else {
                                 return res.json()
                             }
                         })
                         .then((json) => {
                             if(json.message) {
                                 return { "type": 'success', "message": `${t('Password changed')}` };
                             }
                             else {
                                 return { "type": 'error', "message": `${t('Password not changed')}\nError:\n${json.error}` };
                             }
                         })
                         .catch( err => {
                             this.setState({
                                 DataisLoaded: false,
                             })
                         })
                     } catch (e) {
                         console.log(e);
                         return { "type": 'error', "message": `${t('Password not changed')}\nError:\n${e}` };
                     }
                 });
            }
            catch (e) {
                console.log(e)
            }
        }
      }

     generateOtpQr = () => {

        const secret = generateKey(randomBytes, /* bytes: */ 20);

        const user = localStorage.getItem("username")
        const service = "sysgridCommander";
        const url = getKeyUri({
            type: "totp",
            secret,
            name: this.state.userInput2FaDeviceName,
            issuer: "sysgrid"
          });

        if(this.state.userInput2FaDeviceName == "") {
            notify("error", "Please fill in a Device Name")
        }
        else {
            this.setState({
                "qrGenerated": true,
                "otpNewDeviceUrl": url,
                "otpNewDeviceSecret": JSON.stringify(secret)
            })

            notify("success", "QR Code generated")
        }
        // v11.x and above
         
        qrcode.toDataURL(url, (err, imageUrl) => {
            if (err) {
              console.log('Error with QR');
              return;
            }
        });
    }

    test = async () => {
        // const currentSecret = {"bytes":"CoFRjxt8RGAW3JDGTHIl1aM1OPY="}
        // // Deserialize the secret key from the JSON string
        // const deserializedSecretKeyObject = currentSecret;
        // // Decode the base64 string to get the Buffer
        // const decodedBuffer = Buffer.from(deserializedSecretKeyObject.bytes, 'base64');

        // // Convert the Buffer to an object
        // const secretKeyObject = {
        //     bytes: decodedBuffer
        // };

        // // Now you can use reconstructedSecretKeyObject as your secret key object
        // const currentCode = await totp(hmac, {"secret": secretKeyObject});
        // console.log(currentCode);
    }

    objectToSecretKey = async (secretKeyObject) => {
        const secretKeyArray = await Array.from({ length: 20 }, (_, i) => secretKeyObject[i]);
        const decodedBuffer = await Buffer.from(secretKeyArray, 'base64');
        const uint8Array = await new Uint8Array(decodedBuffer);
        const secret = {
            bytes: await Array.from(uint8Array)
              .reduce((obj, byte, index) => {
                obj[index] = byte;
                return obj;
              }, {})
        };
        return({"bytes": uint8Array})
    }
     checkOtpKey = async () => {
        const secretKeyObject = JSON.parse(this.state.otpNewDeviceSecret).bytes
        const secret = await this.objectToSecretKey(secretKeyObject)
        const userCode = this.state.userInput2FaCode
        const currentCode = await totp(hmac, { secret });

        if (userCode == currentCode) {
            notify("success", "Code correct")
            this.secret2Database();
        }
        else {
            notify("error", "Code not correct")
        }
    }

    secret2Database = async () => {
        const username = localStorage.getItem("username")
        const userId = this.state.userData[0].id
        const secret = this.state.otpNewDeviceSecret
        const deviceName = this.state.userInput2FaDeviceName
        const description = this.state.userInput2FaDescription

        try {
            toast.loading("Activate 2FA", { duration: toastDuration})

            fetch(`${sysgridApiUrl}/otp/add`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${apiToken}`,
                    "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                },
                body: JSON.stringify({
                    "userId": userId,
                    "username": username,
                    "secret": secret,
                    "deviceName": deviceName,
                    "description": description
                })
            })
            .then((res) => {
                return res.json()
            })
            .then(res => {
                    toast.success(`${res.message}`, { duration: toastDuration})
                    
                    this.setState({
                        "qrGenerated": false,
                        "otpNewDeviceUrl": "",
                        "otpNewDeviceSecret": ""
                    })
                    this.getOtpData(userId)
            })
            .catch((err) => {
                err = JSON.parse(err)
                console.log(err)
                toast.error(err.err, { duration: toastDuration})
            })
        }
        catch (e) {
            console.log(e)
        }
    }

    
    deleteOtpEntry = async (entryId) => { 
        const userId = this.state.userData[0].id
  
        try {
            toast.loading("Activate 2FA", { duration: toastDuration})

            fetch(`${sysgridApiUrl}/otp/delete`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${apiToken}`,
                    "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                },
                body: JSON.stringify({
                    "userId": userId,
                    "entryId": entryId
                })
            })
            .then((res) => {
                return res.json()
            })
            .then(res => {
                toast.success(`${res.message}`, { duration: toastDuration})
                this.getOtpData(userId)
            })
            .catch((err) => {
                err = JSON.parse(err)
                console.log(err)
                toast.error(err.err, { duration: toastDuration})
            })
        }
        catch (e) {
            console.log(e)
        }
    }

          ////////////////////////////////////////////////////
          // DEFINE FUNCTION editUser
          ////////////////////////////////////////////////////
          editUser = item => event => {
             event.preventDefault();
             const userData = this.state.userData
             const userID = event.target.elements[0].value
             const username = event.target.elements[1].value
             const firstname = event.target.elements[2].value
             const lastname = event.target.elements[3].value
             const email = event.target.elements[4].value
             const roles = userData[0].roles
             const showIntro = event.target.elements[6].value
             const passwordHash = userData[0].passwordHash

             try {
                 toast.loading("Edit User Data", { duration: toastDuration})

                 fetch(`${sysgridApiUrl}/users/editUser`, {
                     method: 'POST', // or 'PUT'
                     headers: {
                         'Accept': 'application/json',
                         'Content-Type': 'application/json',
                         'Authorization': `${apiToken}`,
                         "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                     },
                     body: JSON.stringify({
                         "userID": userID,
                         "username": username,
                         "firstname": firstname,
                         "lastname": lastname,
                         "email": email,
                         "roles": roles,
                         "showIntro": showIntro,
                         "newPasswordHash": passwordHash,
                     })
                 })
                 .then((res) => {
                     return res.json()
                 })
                 .then(res => {
                         toast.success(`${res.message}`, { duration: toastDuration})
                 })
                 .catch((err) => {
                     err = JSON.parse(err)
                     console.log(err)
                     toast.error(err.err, { duration: toastDuration})
                 })
             }
             catch (e) {
                 console.log(e)
             }
           }


          ////////////////////////////////////////////////////
          // DEFINE FUNCTION getUserData
          ////////////////////////////////////////////////////
          getUserData = async () => {
              const username = localStorage.getItem("username")

              await fetch(`${sysgridApiUrl}/users/list`, {
                        mode: 'cors',
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `${apiToken}`,
                            "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                        },
                        body: JSON.stringify({
                            'username': `${username}`
                        })
                      })
                  .then((res) => {
                      if ( res.status != "200") {
                          throw res.statusText
                      }
                      else {
                          return res.json()
                      }
                  })
                  .then((json) => {

                    this.getOtpData(json[0].id);

                    this.setState({
                        userData: json,
                        DataisLoaded: true,
                        apiError: null
                    })
                  })
                  .catch( err => {
                      this.setState({
                          DataisLoaded: false,
                          apiError: err.toString()
                      })
                  })
          }

          
          ////////////////////////////////////////////////////
          // DEFINE FUNCTION getUserData
          ////////////////////////////////////////////////////
          getOtpData = async (userId) => {
            await fetch(`${sysgridApiUrl}/otp/list`, {
                      mode: 'cors',
                      method: 'POST',
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': `${apiToken}`,
                          "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                      },
                      body: JSON.stringify({
                          'userId': `${userId}`
                      })
                    })
                .then((res) => {
                    if ( res.status != "200") {
                        throw res.statusText
                    }
                    else {
                        return res.json()
                    }
                })
                .then((json) => {
                    this.setState({
                        otpData: json,
                        otpDataisLoaded: true,
                        apiError: null
                    })
                })
                .catch( err => {
                    this.setState({
                        DataisLoaded: false,
                        apiError: err.toString()
                    })
                })
        }

	render() {
        const { DataisLoaded, apiError, items, userData, qrGenerated, otpNewDeviceUrl, otpData, otpDataisLoaded} = this.state;
        const { t } = this.props

        if ((!otpDataisLoaded || !DataisLoaded) && (apiError !== null)) {
            return (
                <div>
                    <h1> Error while retrieving data from API:</h1>
                    <h2>{apiError}</h2>
                </div>
            )
        }

        if ((!otpDataisLoaded || !DataisLoaded) && (apiError === null)) {
            return (
                <div className="loaderContainer">
                    <div className="loaderArea1">
                        <TransverseLoading />
                    </div>
                </div>
            )
        }
	return (
        <>
            <wc-toast></wc-toast>
            <div className="panel"><h2 className={'headingLg'}>{t("Logged in as")}: <FontAwesomeIcon icon={faUser} /> {localStorage.getItem("username")}</h2></div>
            <div className="userContainer">
                <div className="userArea1 panel"><h2 className={'headingLg'}>{t("Change Password")}</h2></div>
                <div className="userArea2 panel">
                    <form onSubmit={this.handleSubmit()}>
                        <input type="password" placeholder={t("Current Password")}></input>
                        <input type="password" placeholder={t("New Password")}></input>
                        <input type="password" placeholder={t("New Password confirmation")}></input>
                        <button className="buttonGreen">{t("Change")}</button>
                    </form>
                </div>
                
                <div className="userArea3 panel"><h2 className={'headingLg'}>{t("Change Informations")}</h2></div>
                <div className="userArea4 panel">
                    <form className="editUserForm" onSubmit={this.editUser()}>
                        {userData.map((user) => (
                            <>
                                <label>{t("ID")}: <input disabled value={`${user.id}`}></input></label>
                                <label>{t("Username")}: <input disabled value={`${user.username}`}></input></label>
                                <label>{t("First Name")}: <input value={`${user.firstname}`} onChange={(e) => {this.setState({userData: [{id: `${user.id}`, username: `${user.username}`, firstname: e.target.value, lastname: `${user.lastname}`, email: `${user.email}`, passwordHash: `${user.passwordHash}`, roles: `${user.roles}`, showIntro: `${user.showIntro}` }]})}}></input></label>
                                <label>{t("Last Name")}: <input value={`${user.lastname}`} onChange={(e) => {this.setState({userData: [{id: `${user.id}`, username: `${user.username}`, firstname: `${user.firstname}`, lastname: e.target.value, email: `${user.email}`, passwordHash: `${user.passwordHash}`, roles: `${user.roles}`, showIntro: `${user.showIntro}` }]})}}></input></label>
                                <label>{t("E-Mail")}: <input value={`${user.email}`} onChange={(e) => {this.setState({userData: [{id: `${user.id}`, username: `${user.username}`, firstname: `${user.firstname}`, lastname: `${user.lastname}`, email: e.target.value, passwordHash: `${user.passwordHash}`, roles: `${user.roles}`, showIntro: `${user.showIntro}` }]})}}></input></label>
                                <label>{t("Roles")}: <input disabled value={`${user.roles}`}></input></label>
                        <label>&nbsp;
                            <button className="buttonSave"><FontAwesomeIcon icon={faSave} /></button>
                        </label>
                            </>
                        ))}
                    </form>
                </div>

                <div className="userArea5 panel"><h2 className={'headingLg'}>{t("2FA Configuration")}</h2></div>
                <div className="userArea6 panel">
                    {qrGenerated &&
                        <>
                            <div className="qrCodeContainer">
                            <QRCode value={otpNewDeviceUrl} />
                            </div>
                            <input value={this.state.userInput2FaCode} onChange={(e) => this.setState({"userInput2FaCode": e.target.value})} placeholder="2FA Code"></input>
                            <button onClick={(e) => this.checkOtpKey()} className="buttonBlue">{t("Check 2FA Code")}</button>
                        </>
                    }

                    {!qrGenerated &&
                        <>
                            <input value={this.state.userInput2FaDeviceName} onChange={(e) => this.setState({"userInput2FaDeviceName": e.target.value})} placeholder="Device Name"></input>
                            <textarea value={this.state.userInput2FaDescription} onChange={(e) => this.setState({"userInput2FaDescription": e.target.value})} placeholder="Description"/>
                            <button onClick={(e) => this.generateOtpQr()} className="buttonBlue">{t("Add new")}</button>
                            <button onClick={(e) => this.test()} className="buttonBlue">{t("Test")}</button>
                        </>
                    }
                    <table>
                        <tr>
                            <th>{t("Device Name")}</th>
                            <th>{t("Description")}</th>
                            <th>{t("Date created")}</th>
                        </tr>
                        {otpData.map((otp) => (    
                            <tr>
                                <td>{otp.deviceName}</td>
                                <td>{otp.description}</td>
                                <td>{otp.dateCreated}</td>
                                <td><button onClick={(e) => this.deleteOtpEntry(otp.id)} className="buttonRed">{t("Delete")}</button></td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
        </>
	);
	}
	}

export default withTranslation("translations")(User);
