////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import styled, { createGlobalStyle } from 'styled-components'
// import { elastic as Menu } from 'react-burger-menu'
import { checkAuthToken } from './checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faInfoCircle, faDigitalTachograph, faHome, faShieldAlt, faCompactDisc, faPlayCircle, faCog, faSignOutAlt, faUser, faChevronLeft, faChevronRight, faBars, faSlidersH, faEllipsisH, faTv, faSearch, faFolder, faServer, faWrench, faBan} from "@fortawesome/free-solid-svg-icons";
import { faAppStore, faSafari } from '@fortawesome/free-brands-svg-icons'
import { Dropdown } from 'semantic-ui-react'
import { NotificationBadge } from '@patternfly/react-core';
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { useTranslation } from 'react-i18next';
import { checkRoles } from './checkRoles.js'
import roles from "./config/roles.json"
import { TransverseLoading } from 'react-loadingg';
import notify from "./notify"
import { checkShowIntro } from './checkShowIntro.js';
import Background from './components/Background';

// THIS IS THE SIDEBAR: https://www.npmjs.com/package/@randumbwilliam/react-pro-sidebar


////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const sha256Secret = process.env.REACT_APP_sha256Secret || "e41cc1c2d1a14b98e9987618db5cdda61247d7a3fe147828a6f8522bca155f6ee219056a6645394ac900877a3768bb4d4a5f9e13cedbf84f1a9ffb2fb0a0a0d8"
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"

const username = localStorage.getItem("username");
const refreshInterval = process.env.REACT_APP_refreshDataInterval || 5000
let showIntro = localStorage.getItem("showIntro");
const showIntroBox = localStorage.getItem("showIntroBox");

////////////////////////////////////////////////////
// DEFINE FUNCTION logout
////////////////////////////////////////////////////
function logout() {
    localStorage.clear()
    window.location.replace(`/`);
}

export default function Layout({ children, home }) {
    checkAuthToken()
    const { t, i18n } = useTranslation();
    
    const [collapsed, setCollapsed] = useState(false)
    const [toggled, setToggled] = useState(false)
    const [language, setLanguage] = useState("id");
    const [adminMenuOpened, setAdminMenuOpened] = useState(false);
    const [adminSecurityMenuOpened, setAdminSecurityMenuOpened] = useState(false);
    const [roleCheckAdmin, setRoleCheckAdmin] = useState(false);
    const [roleCheckAdminBackups, setRoleCheckAdminBackups] = useState(false);
    const [roleCheckAdminSecurity, setRoleCheckAdminSecurity] = useState(false);
    const [roleCheckAdminSecurityAide, setRoleCheckAdminSecurityAide] = useState(false);
    const [roleCheckAdminSecurityIpBlacklist, setRoleCheckAdminSecurityIpBlacklist] = useState(false);
    const [roleCheckAdminDashboard, setRoleCheckAdminDashboard] = useState(false);
    const [roleCheckAdminContainers, setRoleCheckAdminContainers] = useState(false);
    const [roleCheckAdminUsers, setRoleCheckAdminUsers] = useState(false);
    const [roleCheckAdminDomains, setRoleCheckAdminDomains] = useState(false);
    const [roleCheckAdminAppDataFolders, setRoleCheckAdminAppDataFolders] = useState(false);
    const [roleCheckAdminSystem, setRoleCheckAdminSystem] = useState(false);
    const [dataIsLoaded, setDataIsLoaded] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    ////////////////////////////////////////////////////
    // PREPARE NOTIFICATION VARIABLE
    ////////////////////////////////////////////////////
    let notifications=[]
    let notification=''
    const [notificationsState, setNotificationsState] = useState([]);
    const [notificationContentClass, setNotificationContentClass] = useState("hide");
    
    useEffect( async () => {
        setRoleCheckAdmin(await checkRoles(roles.admin.admin))
        setRoleCheckAdminBackups(await checkRoles(roles.admin.backups))
        setRoleCheckAdminSecurity(await checkRoles(roles.admin.security))
        setRoleCheckAdminSecurityAide(await checkRoles(roles.admin.securityAide))
        setRoleCheckAdminSecurityIpBlacklist(await checkRoles(roles.admin.securityIpBlacklist))
        setRoleCheckAdminDashboard(await checkRoles(roles.admin.dashboard))
        setRoleCheckAdminContainers(await checkRoles(roles.admin.containers))
        setRoleCheckAdminUsers(await checkRoles(roles.admin.users))
        setRoleCheckAdminDomains(await checkRoles(roles.admin.domains))
        setRoleCheckAdminAppDataFolders(await checkRoles(roles.admin.dataFolders))
        setRoleCheckAdminSystem(await checkRoles(roles.admin.system))
        checkAuthToken()
        setDataIsLoaded(true)
        setIsMobile(window.innerWidth < 900);

        const handleResize = () => {
            // Run your command here
            setIsMobile(window.innerWidth < 900);
        };
        // Attach event listener when component mounts
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        
    }, [])

    function getNotifications() {
        if(localStorage.getItem("notifications")) {
            setNotificationsState(JSON.parse("[" + localStorage.getItem("notifications") + "]"));
        }
        else {
            notifications=[]
            notification=[]
        }
    }


    function readNotification(id){

    }

    function handleCollapse() {
        if(collapsed == true) {
            setCollapsed(false)
        }
        else if(collapsed == false) {
            setCollapsed(true)
        }
    }

    function handleToggle() {
        if(toggled == true) {
            setToggled(false)
        }
        else if(toggled == false) {
            setToggled(true)
        }
    }

    function handleLangChange(evt) {
        const lang = evt.target.value;
        setLanguage(lang);
        i18n.changeLanguage(lang);
    };

    function triggerShowNotifications() {
        if(notificationContentClass == "hide") {
            setNotificationContentClass("")
        }
        else if (notificationContentClass == "") {
            setNotificationContentClass("hide")
        }
    }

    function renderNotificationIcon(type) {
        switch (type) {
            case 'loading':
                return "🕙";
                break;
            case 'success':
                return "✅";
                break;
            case 'error':
                return "❌";
                break;
            case 'promise':
                return "⏳";
                break;
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
    }

    async function exitIntro() {
        await fetch(`${sysgridApiUrl}/users/changeShowIntro`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
            },
            body: JSON.stringify({
                "username": username,
                "showIntro": false
            })
        })
        .then((res) => {
            return res.json()
        })
        .then((res) => {
            if (res.error) {
                notify("error", `Error ${res}`)
            }
            else if (res.message) {
                notify("success", t("Intro done"))
            }
            checkShowIntro()
            showIntro = localStorage.getItem("showIntro");

        })
        .catch((err) => {
            console.log("err")
            console.log(err)
            notify("error", `Error ${err}`)
        })
    }

    function switchShowIntroBox(value) {        
        localStorage.setItem("showIntroBox", value)
        if(value == "true") {
            notify("success", t("Intro Info Box activated"))
        }
        else {
            notify("success", t("Intro Info Box deactivated"))
        }
        window.location.reload(false);
    }
    ////////////////////////////////////////////////////
    // RENDER COMPONENT
    ////////////////////////////////////////////////////
    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");

    if (!dataIsLoaded || showIntro == null) {
        showIntro = localStorage.getItem("showIntro");
        return (
            <div className="loaderContainer">
                <div className="loaderArea1">
                    <TransverseLoading />
                </div>
            </div>
        )
    }
    else {
        showIntro = localStorage.getItem("showIntro");
        
        return (
        <>
        <Background />
            <wc-toast></wc-toast>
            
                <div className="layoutContainer">
                    <div className="layoutArea1mobile">
                        <button className="buttonBars" onClick={handleToggle} >
                        <FontAwesomeIcon icon={faBars} /></button>
                    </div>

                    <div className={toggled ? "layoutArea1" : "layoutArea1 hideOnMobile"}>
                        <ProSidebar onToggle={handleToggle} collapsed={collapsed} toggled={toggled} breakPoint="md">
                            <SidebarHeader>
                                <div className="sidebarHeaderHeight">
                                    <div className="logoLayout" align="middle"/>
                                </ div>
                            </SidebarHeader>
                            <SidebarContent>
                                <Menu popperArrow="true" iconShape="circle">
                                    <MenuItem className={splitLocation[1] === "" ? "sidebarActive menuHome" : "menuHome"} icon={<FontAwesomeIcon icon={faHome} />}>{t("Home")}<Link to="/" /></MenuItem>
                                    <MenuItem className={splitLocation[1] === "appstore" ? "sidebarActive menuAppStore" : "menuAppStore"} icon={<FontAwesomeIcon icon={faAppStore} />}>{t("App Store")}<Link to="/appstore" /></MenuItem>
                                    <MenuItem className={splitLocation[1] === "apps" ? "sidebarActive menuApps" : "menuApps"} icon={<FontAwesomeIcon icon={faTv} />}>{t("Apps")}<Link to="/apps" /></MenuItem>
                                    <MenuItem className={splitLocation[1] === "vpn" ? "sidebarActive menuVpn" : "menuVpn"} icon={<FontAwesomeIcon icon={faShieldAlt} />}>{t("VPN")}<Link to="/vpn" /></MenuItem>
                                    { roleCheckAdmin == true &&
                                        <SubMenu onClick={() => adminMenuOpened == true ? setAdminMenuOpened(false) : setAdminMenuOpened(true) } title={t("Admin")} className={splitLocation[1] === "admin" ? "sidebarActive menuAdmin" : "menuAdmin"} icon={<FontAwesomeIcon icon={faSlidersH} />}>
                                        { roleCheckAdminDashboard == true &&
                                            <MenuItem className={splitLocation[1] === "admin" && splitLocation[2] === "" ? "sidebarActive menuAdminDashboard" : "menuAdminDashboard"} icon={<FontAwesomeIcon icon={faDigitalTachograph} />}>{t("Dashboard")}<Link to="/admin/dashboard" /></MenuItem>
                                        }
                                        { roleCheckAdminBackups == true &&
                                            <MenuItem className={splitLocation[2] === "backups" ? "sidebarActive menuAdminBackups" : "menuAdminBackups"} icon={<FontAwesomeIcon icon={faServer} />}>{t("Backups")}<Link to="/admin/backups" /></MenuItem>
                                        }
                                        { roleCheckAdminDomains == true &&
                                            <MenuItem className={splitLocation[2] === "domains" ? "sidebarActive menuAdminDomains" : "menuAdminDomains"} icon={<FontAwesomeIcon icon={faSafari} />}>{t("Domains")}<Link to="/admin/domains" /></MenuItem>
                                        }
                                        { roleCheckAdminAppDataFolders == true &&
                                            <MenuItem className={splitLocation[2] === "appDataFolders" ? "sidebarActive menuAdminAppDataFolders" : "menuAdminAppDataFolders"} icon={<FontAwesomeIcon icon={faFolder} />}>{t("App Data Folders")}<Link to="/admin/appDataFolders" /></MenuItem>
                                        }
                                        { roleCheckAdminContainers == true &&
                                            <MenuItem className={splitLocation[2] === "containers" ? "sidebarActive menuAdminContainers" : "menuAdminContainers"} icon={<FontAwesomeIcon icon={faDocker} />}>{t("Containers")}<Link to="/admin/containers" /></MenuItem>
                                        }
                                        { roleCheckAdminSecurity == true &&
                                            <SubMenu onClick={() => adminSecurityMenuOpened == true ? setAdminSecurityMenuOpened(false) : setAdminSecurityMenuOpened(true) } title={t("Security")} className={splitLocation[1] === "security" ? "sidebarActive menuAdminSecurity" : "menuAdminSecurity"} icon={<FontAwesomeIcon icon={faShieldAlt} />}>
                                            { roleCheckAdminSecurityAide == true &&
                                                <MenuItem className={splitLocation[2] === "security" && splitLocation[3] === "aide" ? "sidebarActive menuAdminSecurityAide" : "menuAdminSecurityAide"} icon={<FontAwesomeIcon icon={faShieldAlt} />}>{t("AIDE")}<Link to="/admin/security/aide" /></MenuItem>
                                            }
                                            { roleCheckAdminSecurityIpBlacklist == true &&
                                                <MenuItem className={splitLocation[2] === "security" && splitLocation[3] === "ipBlacklist" ? "sidebarActive menuAdminSecurityIpBlacklist" : "menuAdminSecurityIpBlacklist"} icon={<FontAwesomeIcon icon={faBan} />}>{t("IP Blacklist")}<Link to="/admin/security/ipBlacklist" /></MenuItem>
                                            }
                                            </SubMenu>
                                        }
                                        { roleCheckAdminUsers == true &&
                                            <MenuItem className={splitLocation[2] === "users" ? "sidebarActive menuAdminUsers" : "menuAdminUsers"} icon={<FontAwesomeIcon icon={faUser} />}>{t("Users")}<Link to="/admin/users" /></MenuItem>
                                        }
                                        { roleCheckAdminSystem == true &&
                                            <MenuItem className={splitLocation[2] === "system" ? "sidebarActive menuAdminSystem" : "menuAdminSystem"} icon={<FontAwesomeIcon icon={faWrench} />}>{t("System")}<Link to="/admin/system" /></MenuItem>
                                        }
                                        </SubMenu>
                                    }
                                </Menu>
                            </SidebarContent>
                            <SidebarFooter>
                                {isMobile ?
                                    toggled ? <button className="buttonColored" onClick={() => {handleToggle()}}><FontAwesomeIcon icon={faChevronLeft} />{t("Collapse")}</button>: <button className="buttonColored collapseButton" onClick={() => {handleToggle()}}><FontAwesomeIcon icon={faChevronRight} /></button>
                                :
                                    collapsed ? <button className="buttonColored" onClick={() => {handleCollapse()}}><FontAwesomeIcon icon={faChevronLeft} /></button>: <button className="buttonColored collapseButton" onClick={() => {handleCollapse()}}><FontAwesomeIcon icon={faChevronRight} />{t("Collapse")}</button>
                                }
                            </SidebarFooter>
                        </ProSidebar>
                    </div>

                    <div className='layoutArea2'>
                    </div>

                    <div className='layoutArea3'>
                        <form method="GET" action="search" className="searchForm">
                            <input name="searchValue" className='layoutSearch' placeholder={t("Search...")}/><button className="layoutSearchButton"><FontAwesomeIcon icon={faSearch} /></button>
                        </form>
                    </div>

                    <div className='layoutArea4'>
                        <select className="layoutLangSelect" onChange={handleLangChange} value={i18n.language}>
                            <option value="en-EN">EN</option>
                            <option value="de-DE">DE</option>
                        </select>
                        &nbsp;
                        &nbsp;
                        {
                            showIntroBox == "false" 
                            ?
                            (<FontAwesomeIcon icon={faInfoCircle} onClick={() => switchShowIntroBox("true")}/>)
                            :
                            showIntroBox == "true" 
                            ?
                            (<FontAwesomeIcon icon={faInfoCircle} onClick={() => switchShowIntroBox("false")}/>)
                            :
                            showIntroBox == null 
                            ?
                            (<FontAwesomeIcon icon={faInfoCircle} onClick={() => switchShowIntroBox("true")}/>)
                            : 
                            null
                        }
                        <div className="notificationDropdown">
                            <NotificationBadge onClick={() => {getNotifications(); triggerShowNotifications()}} variant="unread" aria-label="First notifications" count="attention" />
                            {notificationContentClass ? "" :
                                <div className={`notificationDropdownContentContainer ${notificationContentClass} panel`}>
                                    {notificationsState.slice(-10).reverse().map((notification) => (
                                        <><li>
                                            <div className="notificationDropdownContentArea1">
                                                {notification.dateTime}
                                            </div>
                                            <div className="notificationDropdownContentArea2">
                                                {renderNotificationIcon(notification.type)}{notification.message}
                                            </div>
                                        </li></>
                                    ))}
                                </div>
                            }
                        </div>
                        <div className="profileDropdown">
                            <FontAwesomeIcon icon={faUser} />
                            <div className="profileDropdownContent panel">
                                <span>Logged in as:</span><br />
                                    <FontAwesomeIcon icon={faUser} /><span>&nbsp;&nbsp;{username}</span><br /><br />
                                    <Link to="/User"><button className='buttonGreen'>{t('Profile')}  <FontAwesomeIcon icon={faCog} /></button></Link>&nbsp;&nbsp;
                                    <button className='buttonRed' onClick={(e) => logout()}>Logout  <FontAwesomeIcon icon={faSignOutAlt} /></button>
                            </div>
                        </div>
                    </div>

                    <div className='layoutArea5'>
                        <div className='layoutArea5Area1'>
                                {children}
                        </div>
                    </div>
                </div>
        </>
        // </StyledOffCanvas>
        )
    }
}
