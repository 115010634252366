////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
const axios = require('axios').default;

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const apiToken = process.env.REACT_APP_apiToken || "123"

////////////////////////////////////////////////////
// DEFINE FUNCTION checkShowIntro
////////////////////////////////////////////////////
export async function checkShowIntro() {
	const username = localStorage.getItem("username");

    if(username) {
        try {
            const data = await fetch(`${sysgridApiUrl}/users/list`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Origin",
                "Access-Control-Allow-Origin": "*"
                },
                body: JSON.stringify({"username": username})
            })
            .then((res) => {
                if ( res.status != "200") {
                    throw res.statusText
                }
                else {
                    return res.json()
                }
            })
            .then((json) => {
                    localStorage.setItem('showIntro', json[0].showIntro);
            })
            .catch( err => {
                console.log(err)
            })
            return data
        } catch (error) {
        console.error(error);
        }
    }

    // await fetch(`${sysgridApiUrl}/login/checkShowIntro`, {
	// 	redirect: 'follow',
	// 	headers: {
	// 		'X-Authorization': `Bearer ${apiToken}`,
	// 		'Content-Type': 'application/json',
	// 		'Access-Control-Allow-Origin': '*',
	// 		'Access-Control-Allow-Headers': '*',
	// 		'Access-Control-Expose-Headers': '*',
	// 	},
	//   method: 'POST',
	//   body:  JSON.stringify({"username": username, "authToken": authToken}),
	// })
    // .then(data => data.json())
    // .then(data => {
    //     if ( data.message ) {
	// 		window.location.replace(`/`);
    //     }
    //     else if ( !data.message && data.authToken) {
    //         if(data.authToken === authToken) {
    //             //nothing
    //         }
    //     }
	// 	else if (data.error) {
	// 		console.log(data.error)
	// 	}
    // })
}
