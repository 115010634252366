////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../Layout";
import { toast } from 'wc-toast'
import { checkAuthToken } from '../checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faPlayCircle, faCog, faTrash, faFolder} from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';
import notify from "../notify.js"
import { stopContainer, startContainer, deleteContainer } from '../containerControll';
import { TransverseLoading } from 'react-loadingg';
import { checkRoles } from '../checkRoles.js'
import roles from "../config/roles.json"
import i18n from '../i18n';
import { IntroBoxHeading, IntroBoxContent } from '../components/IntroBox'

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const refreshInterval = process.env.REACT_APP_refreshDataInterval || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"
const t = i18n.t;

const deleteDataFolder = async (projectID, templateName) =>  {
    if(window.confirm(t("Do you really want to delete this Data Folder?"))) {
        try {
            const result = await fetch(`${sysgridApiUrl}/projects/deleteData`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Authorization': `${apiToken}`,
                    "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "projectID": `${projectID}`,
                    "templateName": `${templateName}`,
                })
            })
            console.log(({
                "projectID": `${projectID}`,
                "templateName": `${templateName}`,
            }))
            const logMessage = await result
            notify("success", t("App Data deleted"))
        } catch (e) {
            console.log(e)
        }
    }
    else {
        notify("error", t("App Data NOT deleted"))
    }
};

////////////////////////////////////////////////////
// DEFINE REACT COMPONENT
////////////////////////////////////////////////////
class AdminAppDataFolders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            DataisLoaded: false,
            apiError: null,
            setIntervalId: "",
            roleCheck: false
        };
    }

    ////////////////////////////////////////////////////
    // COMPONENT DID AND WILL MOUNT
    ////////////////////////////////////////////////////
    async componentDidMount() {
        checkAuthToken()
        // need to make the initial call to getData() to populate
        // data right away
        this.setState({
            roleCheck: await checkRoles(roles.admin.containers)
        })
        this.getData();
        // Now we need to make it run at a specified interval
            var setIntervalId = setInterval(this.getData, refreshInterval); // runs every 5 seconds.
            this.setState({
                setIntervalId: setIntervalId,
            })
    }

    componentWillUnmount() {
        clearInterval(this.state.setIntervalId)
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getData
    ////////////////////////////////////////////////////
    getData = async () => {
        await fetch(`${sysgridApiUrl}/projects/dataFolders`, {
                  mode: 'cors',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `${apiToken}`,
                      "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                  }
                })
                .then((res) => {
                    if ( res.status != "200") {
                        throw res.statusText
                    }
                    else {
                        return res.json()
                    }
                })
                .then((json) => {
                    this.setState({
                        items: json,
                        DataisLoaded: true,
                        apiError: null
                    })
                })
                .catch( err => {
                    this.setState({
                        DataisLoaded: false,
                        apiError: err.toString()
                    })
                })
    }

    ////////////////////////////////////////////////////
    // RENDER COMPONENT
    ////////////////////////////////////////////////////
    render() {
        const { items, DataisLoaded, apiError, roleCheck} = this.state;
        const { t } = this.props


        if (roleCheck == true) {
            if (!DataisLoaded && (apiError !== null)) {
                return (
                    <div>
                    <h1> {t("Error while retrieving data from API")}:</h1>
                    <h2>{apiError}</h2>
                    </div>
                )
            }

            if (!DataisLoaded && (apiError === null)) {
                return (
                    <div className="loaderContainer">
                    <div className="loaderArea1">
                    <TransverseLoading />
                    </div>
                    </div>
                )
            }
            return (
                <>
                    <wc-toast></wc-toast>
                    <IntroBoxHeading>
                        {t("Informations about this Menupoint")}
                    </IntroBoxHeading>
                    <IntroBoxContent>
                        {t("Here you find a list with the Data Folders for an App and you are able to delete them if necessary. A Datafolder contains all the User data from an App. For example: Database Files, Uploaded Files and so on. Once deleted, a datafolder is unrecoverable.")}
                    </IntroBoxContent>

                    <div className="panelRed">
                        <h2 className={'headingLg'}><FontAwesomeIcon icon={faFolder} />  {t("App Data Folders")}</h2>
                    </div>
                        {items.map((item) => (
                            <>
                                    <div className="dataFolderContainer panel">
                                        <div className="dataFoldersArea1">
                                            <img className="dataFolderLogo" src={`data:image/png;base64,${item.logo}`} alt=""/>
                                        </div>
                                        <div className="dataFoldersArea2">
                                            {item.dataFolder}
                                        </div>
                                        <div className="dataFoldersArea3">
                                            <table>
                                                <tr>
                                                    <td>
                                                        App ID:
                                                    </td>
                                                    <td>
                                                        {item.projectID}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        App Name:
                                                    </td>
                                                    <td>
                                                        {item.templateName}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="dataFoldersArea4">
                                            <button className={`buttonRed containerProjectsButton`} onClick={e => deleteDataFolder(item.projectID, item.templateName)}>{ window.screen.width < 900 ? <FontAwesomeIcon icon={faTrash} /> : t("Delete")}</button>
                                        </div>
                                    </div>
                            </>
                        ))}
                </>

        );
    }
    else {
        return(
            <>
                <h1>No permission</h1>
            </>
        )
    }
}
}

export default withTranslation("translations")(AdminAppDataFolders);
