import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import notify from './notify';

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl = process.env.REACT_APP_dockerApiUrl || 'http://localhost:9002/dockerApi';
const apiToken = process.env.REACT_APP_apiToken || '123';

// Initialize i18n
i18n.use(initReactI18next).init();

////////////////////////////////////////////////////
// DEFINE CONTAINER CONTROLL FUNCTIONS
////////////////////////////////////////////////////
const stopContainer = async (Id) => {
  const { t } = i18n;

  if (window.confirm(t('Do you really want to stop this Container?'))) {
    const url = `${dockerApiUrl}/containers/${Id}/stop`;

    notify('promise', { loading: `${t('Container will be stopped')}: ${Id}` }, {}, async () => {
      try {
        const result = await fetch(`${url}`, {
          method: "POST",
          headers: {
            'Authorization': `${apiToken}`,
            'Access-Control-Request-Headers': 'Authorization, Content-Type, Accept',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        const logMessage = await result;
        return { type: 'success', message: `${t('Container stopped')}: ${Id}` };
      } catch (e) {
        console.log(e);
        return { type: 'error', message: `${t('Container not stopped')}: ${Id}\nError:\n${e}` };
      }
    });
  } else {
    notify('error', t('Container not stopped'));
  }
};

const startContainer = async (Id) => {
  const { t } = i18n;

  if (window.confirm(t('Do you really want to start this Container?'))) {
    const url = `${dockerApiUrl}/containers/${Id}/start`;

    notify('promise', { loading: `${t('Container will be started')}: ${Id}` }, {}, async () => {
      try {
        const result = await fetch(`${url}`, {
          method: "POST",
          headers: {
            'Authorization': `${apiToken}`,
            'Access-Control-Request-Headers': 'Authorization, Content-Type, Accept',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        const logMessage = await result;
        return { type: 'success', message: `${t('Container started')}: ${Id}` };
      } catch (e) {
        console.log(e);
        return { type: 'error', message: `${t('Container not started')}: ${Id}\nError:\n${e}` };
      }
    });
  } else {
    notify('error', t('Container not started'));
  }
};

const deleteContainer = async (Id) => {
  const { t } = i18n;

  if (window.confirm(t('Do you really want to delete this Container?'))) {
    const url = `${dockerApiUrl}/containers/${Id}`;

    notify('promise', { loading: `${t('Container will be deleted')}: ${Id}` }, {}, async () => {
      try {
        const result = await fetch(`${url}`, {
          method: "DELETE",
          headers: {
            'Authorization': `${apiToken}`,
            'Access-Control-Request-Headers': 'Authorization, Content-Type, Accept',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        const logMessage = await result;
        if(logMessage.status == 409) {
          return { type: 'error', message: `${t('Please stop the container first')}: ${Id}` };
        }
        else if(logMessage.status == 204) {
          return { type: 'success', message: `${t('Container deleted')}: ${Id}` };
        }
      } catch (e) {
        console.log(e);
        return { type: 'error', message: `${t('Container not deleted')}: ${Id}\nError:\n${e}` };
      }
    });
  } else {
    notify('error', t('Container not deleted'));
  }
};

export { stopContainer, startContainer, deleteContainer };
