////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Home from './Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Install from "./Install";
import Search from "./Search";
import Login from "./Login";
import Container from "./Container";
import AdminAppDataFolders from "./admin/DataFolders";
import AdminContainers from "./admin/Containers";
import AppStore from "./AppStore";
import Apps from "./Apps";
import Admin from "./admin/index";
import AdminBackups from "./admin/Backups";
import AdminUsers from "./admin/Users";
import VPN from "./VPN";
import UsersEdit from "./admin/UsersEdit";
import AdminDomains from "./admin/Domains";
import AdminSystem from "./admin/System";
import AdminSecurityAide from "./admin/SecurityAide";
import AdminSecurityAideLogs from "./admin/SecurityAideLogs";
import AdminSecurityIpBlacklist from "./admin/SecurityIpBlacklist";
import User from "./User";
import Layout from "./Layout";
import { createGlobalStyle } from 'styled-components';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { checkShowIntro } from './checkShowIntro.js'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

////////////////////////////////////////////////////
// IMPORT CSS FILES
////////////////////////////////////////////////////
require('./darkmode.css')
require('./lightmode.css')
require('./general.css')


////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const username = localStorage.getItem("username");
const authToken = localStorage.getItem('authToken');
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const apiToken = process.env.REACT_APP_apiToken || "123"

////////////////////////////////////////////////////
// DEFINE THE TWO OPTIONS OF COMPONENTS TO RENDER
////////////////////////////////////////////////////
const showError = (errorMessage) => {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <React.StrictMode>
                <div className="installContainer">
                    <div className="installModal">
                        <div className="installModalInner">
                            <div className="installModalInnerInner">
                                <h1>Error</h1>
                                <div className="spacer20px"></div>
                                <FontAwesomeIcon icon={faExclamationTriangle} size="6x"/>
                                <div className="spacer20px"></div>
                                <table>
                                    <tr>
                                        <td>Error Code:</td>
                                        <td>{JSON.parse(errorMessage).code}</td>
                                    </tr>
                                    <tr>
                                        <td>Address:</td>
                                        <td>{JSON.parse(errorMessage).address}</td>
                                    </tr>
                                    <tr>
                                        <td>Port:</td>
                                        <td>{JSON.parse(errorMessage).port}</td>
                                    </tr>
                                    <tr>
                                        <td>Complete Error:</td>
                                        <td>{errorMessage}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>                        
                </div>                        
            </React.StrictMode>
        </I18nextProvider>,

        document.getElementById('root')
    )
}

const showLogin = () => {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <React.StrictMode>
                <Login token={authToken}/>
            </React.StrictMode>
        </I18nextProvider>,

        document.getElementById('root')
    )
}

const showInstall = () => {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <React.StrictMode>
                    <Install/>
            </React.StrictMode>
        </I18nextProvider>,

        document.getElementById('root')
    )
}

const showSite = () => {
    ReactDOM.render(
      <I18nextProvider i18n={i18n}>
          <React.StrictMode>
              <BrowserRouter>
                  <Layout home>
                        <Routes>
                            <Route path="/" element={<Home />}></Route>
                            <Route path="install" element={<Install />}></Route>
                            <Route path="search" element={<Search />}></Route>
                            <Route path="container" element={<Container />}></Route>
                            <Route path="appstore" element={<AppStore />}></Route>
                            <Route path="apps" element={<Apps />}></Route>
                            <Route path="vpn" element={<VPN />}></Route>
                            <Route path="/admin" element={<Admin />}></Route>
                            <Route path="/admin/backups" element={<AdminBackups />}></Route>
                            <Route path="/admin/appDataFolders" element={<AdminAppDataFolders />}></Route>
                            <Route path="/admin/containers" element={<AdminContainers />}></Route>
                            <Route path="/admin/security/aide" element={<AdminSecurityAide />}></Route>
                            <Route path="/admin/security/aide/logs" element={<AdminSecurityAideLogs />}></Route>
                            <Route path="/admin/security/ipBlacklist" element={<AdminSecurityIpBlacklist />}></Route>
                            <Route path="admin/users" element={<AdminUsers />}></Route>
                            <Route path="admin/users/edit" element={<UsersEdit />}></Route>
                            <Route path="admin/domains" element={<AdminDomains />}></Route>
                            <Route path="admin/system" element={<AdminSystem />}></Route>
                            <Route path="user" element={<User />}></Route>
                      </Routes>
                  </Layout>
              </BrowserRouter>
          </React.StrictMode>
      </I18nextProvider>,
          document.getElementById('root')
    );
}

////////////////////////////////////////////////////
// DEFINE checkAuthToken FUNCTION WHICH WILL DECIDE
// WHICH OF THE TWO COMPONENTS WILL RENDER
////////////////////////////////////////////////////
const checkAuthToken = async () => {
    await fetch(`${sysgridApiUrl}/login/checkAuthToken`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `${apiToken}`,
        "Access-Control-Request-Headers": "Authorization, Content-Type, Origin",
        "Access-Control-Allow-Origin": "*"

        },
        body: JSON.stringify({"username": username, "authToken": authToken})
    })
    .then(data => data.json())
    .then(data => {
        if ( data.installDone == false && data.error.code != "ECONNREFUSED" ) {
            showInstall()
        }
        else if ( data.error ) {
            showError(JSON.stringify(data.error))
        }
        else if ( data.message && data.installDone == true ) {
            showLogin()
        }
        else if ( !data.message && data.authToken) {
            if(data.authToken == authToken) {
                showSite()
            }
        }
    })
}


////////////////////////////////////////////////////
// RUN CHECK AUTH TOKEN
////////////////////////////////////////////////////
async function main() {
    await checkShowIntro()
    await checkAuthToken()
}

main()
////////////////////////////////////////////////////
// DUNNO WHAT THIS IS... GOOGLE IT LATER
////////////////////////////////////////////////////
// If you want to start measuring performance in your Home, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
