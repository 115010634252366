import React, { useEffect, useRef } from 'react';
import { Stage, useTick } from '@inlet/react-pixi';
import * as PIXI from 'pixi.js';
import { KawaseBlurFilter } from '@pixi/filter-kawase-blur';
import { createNoise2D } from 'simplex-noise';
import hsl from 'hsl-to-hex';
import debounce from 'lodash.debounce';
import '../background.css';

const animationSpeed = 0.001; // How quickly the noise/self-similar random values step through time // DEFAULT: 0.002
const maxOrbsAmount = 5;

const Background = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const app = new PIXI.Application({
      view: canvasRef.current,
      resizeTo: window,
      transparent: true,
      backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--layout-background-color'), // Use the hex color code
    });

    // Return a random number within a range
    function random(min, max) {
      return Math.random() * (max - min) + min;
    }
    const noise2D = createNoise2D();

    // Map a number from one range to another
    function map(n, start1, end1, start2, end2) {
      return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
    }

    // ColorPalette class
    class ColorPalette {
      constructor() {
        this.setColors();
        this.setCustomProperties();
      }

      setColors() {
        const baseColor = getComputedStyle(document.documentElement).getPropertyValue('--gradient-color-15');
        const gradientColor1 = getComputedStyle(document.documentElement).getPropertyValue('--gradient-color-1');
        const gradientColor2 = getComputedStyle(document.documentElement).getPropertyValue('--gradient-color-2');

        // Pick a random hue somewhere between 220 and 360
        this.hue = ~~random(220, 360);
        this.complimentaryHue1 = this.hue + 30;
        this.complimentaryHue2 = this.hue + 60;
        // Define a fixed saturation and lightness
        this.saturation = 95;
        this.lightness = 50;

        // Define a base color
        this.baseColor = hsl(this.hue, this.saturation, this.lightness);
        // Define a complimentary color, 30 degrees away from the base
        this.complimentaryColor1 = hsl(this.complimentaryHue1, this.saturation, this.lightness);
        // Define a second complimentary color, 60 degrees away from the base
        this.complimentaryColor2 = hsl(this.complimentaryHue2, this.saturation, this.lightness);

        // Store the color choices in an array so that a random one can be picked later
        this.colorChoices = [baseColor, gradientColor1, gradientColor2];
      }

      randomColor() {
        // Pick a random color
        return this.colorChoices[~~random(0, this.colorChoices.length)].replace('#', '0x');
      }

      setCustomProperties() {
        // Set CSS custom properties so that the colors defined here can be used throughout the UI
        document.documentElement.style.setProperty('--hue', this.hue);
        document.documentElement.style.setProperty('--hue-complimentary1', this.complimentaryHue1);
        document.documentElement.style.setProperty('--hue-complimentary2', this.complimentaryHue2);
      }
    }

  class Orb {
    constructor(fill = 0x000000) {
      // Bounds = the area an orb is "allowed" to move within
      this.bounds = this.setBounds();
      // Initialise the orb's { x, y } values to a random point within its bounds
      this.x = random(this.bounds['x'].min, this.bounds['x'].max);
      this.y = random(this.bounds['y'].min, this.bounds['y'].max);
  
      // How large the orb is vs its original radius (this will modulate over time)
      this.scale = 1;
  
      // What color is the orb?
      this.fill = fill;
  
      // The original radius of the orb, set relative to window height
      this.radius = random(window.innerHeight / 6, window.innerHeight / 3);
  
      // Starting points in "time" for the noise/self-similar random values
      this.xOff = random(0, 1000);
      this.yOff = random(0, 1000);
      // How quickly the noise/self-similar random values step through time
      this.inc = animationSpeed;
  
      // PIXI.Graphics is used to draw 2D primitives (in this case, a circle) to the canvas
      this.graphics = new PIXI.Graphics();
      this.graphics.alpha = 0.825;
  
      // 250ms after the last window resize event, recalculate orb positions.
      window.addEventListener(
        'resize',
        debounce(() => {
          this.bounds = this.setBounds();
        }, 250)
      );
    }
  
    setBounds() {
      // How far from the { x, y } origin can each orb move
      const maxDist = window.innerWidth < 1000 ? window.innerWidth / 3 : window.innerWidth / 5;
      // The { x, y } origin for each orb (the bottom right of the screen)
      const originX = window.innerWidth / 1.25;
      const originY = window.innerHeight / 1.375;
  
      // Allow each orb to move x distance away from its x/y origin
      return {
        x: {
          min: originX - maxDist,
          max: originX + maxDist,
        },
        y: {
          min: originY - maxDist,
          max: originY + maxDist,
        },
      };
    }
  
    update() {
      // Self-similar "pseudo-random" or noise values at a given point in "time"
      const xNoise = noise2D(this.xOff, this.xOff);
      const yNoise = noise2D(this.yOff, this.yOff);
      const scaleNoise = noise2D(this.xOff, this.yOff);
  
      // Map the xNoise/yNoise values (between -1 and 1) to a point within the orb's bounds
      this.x = map(xNoise, -1, 1, this.bounds['x'].min, this.bounds['x'].max);
      this.y = map(yNoise, -1, 1, this.bounds['y'].min, this.bounds['y'].max);
      // Map scaleNoise (between -1 and 1) to a scale value somewhere between half of the orb's original size and 100% of its original size
      this.scale = map(scaleNoise, -1, 1, 0.5, 1);
  
      // Step through "time"
      this.xOff += this.inc;
      this.yOff += this.inc;
    }

    render() {
      // Update the PIXI.Graphics position and scale values
      this.graphics.clear();
      this.graphics.beginFill(this.fill);
      this.graphics.drawCircle(0, 0, this.radius);
      this.graphics.endFill();
      this.graphics.scale.set(this.scale);
      this.graphics.position.set(this.x, this.y);
  
      // Return the PIXI.Graphics object for rendering
      return this.graphics;
    }
  }
  

app.stage.filters = [new KawaseBlurFilter(30, 10, true)];

// Create color palette
const colorPalette = new ColorPalette();

// Create orbs
const orbs = [];

for (let i = 0; i < maxOrbsAmount; i++) {
    const orb = new Orb(colorPalette.randomColor());

    app.stage.addChild(orb.graphics);

    orbs.push(orb);
}

// Animate!
if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
    app.ticker.add(() => {
    orbs.forEach((orb) => {
        orb.update();
        orb.render();
    });
    });
} else {
    orbs.forEach((orb) => {
    orb.update();
    orb.render();
    });
}


return () => {
    // Clean up any resources if needed
  };
}, []);

return <canvas ref={canvasRef} className="orb-canvas" />;
};

export default Background;