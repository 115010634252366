////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import Toggle from "react-toggle";
import { checkAuthToken } from '../checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker, faAppStore, faSafari } from '@fortawesome/free-brands-svg-icons'
import { faServer, faFolder, faUser, faInfoCircle, faBell, faTv} from "@fortawesome/free-solid-svg-icons";
import { Popup } from 'semantic-ui-react'
import { toast } from 'wc-toast'
import { withTranslation } from 'react-i18next';

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const newsUrl = process.env.newsApiUrl || "https://test-wordpress.grid.sysgrid.de/"
const apiToken = process.env.REACT_APP_apiToken

function createID(length) {
    var result           = '';
    var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
 charactersLength));
   }
   return result;
}


const notify = (type, message, options) => {
    switch (type) {
        case 'loading': toast.loading(message, options)
            break;
        case 'success': toast.success(message, options)
            break;
        case 'error': toast.error(message, options)
            break;
        case 'promise': toast.promise(message, options)
            break;
    }
    let notifications
    if(localStorage.getItem('notifications')) {
        notifications = localStorage.getItem('notifications')
        let id=createID(6)
        notifications = notifications + ',' + `{ "type": "${type}", "id": "${id}", "message": "${message}", "readStatus": false}`
    }
    else {
        let id=createID(6)
        notifications = `{ "type": "${type}", "id": "${id}", "message": "${message}", "readStatus": false}`
    }
    localStorage.setItem("notifications", notifications);
}

function createNewNotification() {
	notify("success", "Container started", { duration: toastDuration})
}

////////////////////////////////////////////////////
// DEFINE REACT COMPONENTS
////////////////////////////////////////////////////
class Admin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            DataisLoaded: false,
            apiError: null,
        };
    }
	////////////////////////////////////////////////////
	// COMPONENT DID AND WILL MOUNT
	////////////////////////////////////////////////////
	componentDidMount() {
		checkAuthToken()
	}


	////////////////////////////////////////////////////
    // RENDER COMPONENT
    ////////////////////////////////////////////////////

	render() {
        const { news, DataisLoaded, apiError } = this.state;
        const { t } = this.props

		return (
	        <>
            <wc-toast></wc-toast>
                <div class="homeContainer">

                <Link  className='homeArea1 panel' to='/admin/backups'>
                    <div class="homeArea1Area1">
                        <Popup
                            className="infoPopup"
                            content={t("Here you can create App Backups and Recover Backups for specific Apps.")}
                            mouseEnterDelay={500}
                            mouseLeaveDelay={500}
                            on='hover'
                            trigger={<FontAwesomeIcon icon={faInfoCircle} />}
                        />
                    </div>
                    <div class="homeArea1Area2">
                        <FontAwesomeIcon size="3x" icon={faServer} />
                        <span className="areaArea3" >{t('Backups')}</span>
                    </div>
                </Link >

                <Link  className='homeArea2 panel' to='/admin/domains'>
                    <div class="homeArea2Area1">
                        <Popup
                        className="infoPopup"
                        content={t('This are the Domains which should be available when installing a new App. A Domain is the Webaddress under which a Website/Webapp is reachable. For example: sysgrid.de A Subdomain is the part before the domain. For example: cloud.sysgrid.de. In this Case "cloud" is the Subdomain and "sysgrid.de" is the Domain.')}
                        mouseEnterDelay={500}
                        mouseLeaveDelay={500}
                        on='hover'
                        trigger={<FontAwesomeIcon icon={faInfoCircle} />}
                        />
                    </div>
                    <div class="homeArea2Area2">
                        <FontAwesomeIcon size="3x" icon={faSafari} />
                        <span className="areaArea3" >{t('Domains')}</span>
                    </div>
                </Link >


                <Link  className='homeArea3 panel' to='/admin/appDataFolders'>
                    <div class="homeArea3Area1">
                        <Popup
                        className="infoPopup"
                        content={t("Here you find a list with the Data Folders for an App and you are able to delete them if necessary. A Datafolder contains all the User data from an App. For example: Database Files, Uploaded Files and so on. Once deleted, a datafolder is unrecoverable.")}
                        mouseEnterDelay={500}
                        mouseLeaveDelay={500}
                        on='hover'
                        trigger={<FontAwesomeIcon icon={faInfoCircle} />}
                        />
                    </div>
                    <div class="homeArea3Area2">
                        <FontAwesomeIcon size="3x" icon={faFolder} />
                        <span className="areaArea3" >{t('App Data Folders')}</span>
                    </div>
                </Link >

                <Link  className='homeArea4 panel' to='/admin/containers'>
                    <div class="homeArea4Area1">
                        <Popup
                        className="infoPopup"
                        content={t("The Apps and Databases are running in Containers on the Host Server. Here you find an overview of all running Containers with there technical name, and you are able to Start or Stop them, aswell as delete them.")}
                        mouseEnterDelay={500}
                        mouseLeaveDelay={500}
                        on='hover'
                        trigger={<FontAwesomeIcon icon={faInfoCircle} />}
                        />
                    </div>
                    <div class="homeArea4Area2">
                        <FontAwesomeIcon size="3x" icon={faDocker} />
                        <span className="areaArea3" >{t('Containers')}</span>
                    </div>
                </Link >

                <Link className='homeArea5 panel' to='/admin/users'>
                    <div class="homeArea5Area1">
                        <Popup
                        className="infoPopup"
                        content={t("Here you are able to create new Users, Edit existing ones, set new Passwords for Users or edit the User Roles.")}
                        mouseEnterDelay={500}
                        mouseLeaveDelay={500}
                        on='hover'
                        trigger={<FontAwesomeIcon icon={faInfoCircle} />}
                        />
                    </div>

                    <div class="homeArea5Area2">
                        <FontAwesomeIcon size="3x" icon={faUser} />
                        <span className="areaArea3">{t('Users')}</span>
                    </div>
                </Link >
				  </div>
	        </>
		);
	}
}

export default withTranslation("translations")(Admin);
