
////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React from "react";
import { Link } from "react-router-dom";
import { useRef } from 'react';
import { toast } from 'wc-toast'
import { checkAuthToken } from './checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAppStore } from '@fortawesome/free-brands-svg-icons'
import { faInfoCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import ToggleButton from 'terra-toggle-button';
import { withTranslation } from 'react-i18next';
import notify from "./notify.js"
import { TransverseLoading } from 'react-loadingg';
import i18next from 'i18next';
import { IntroBoxHeading, IntroBoxContent } from './components/IntroBox'
import Tooltip from './components/Tooltip';

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const refreshInterval = process.env.REACT_APP_refreshDataInterval || 5000
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"


////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}

class AppStore extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            envVariables: {},
            envVariablesArray: [],
            categorys: [],
            selectedFilterCategory: "allCategorys",
            domains: [],
            logos: {},
            DataisLoaded: false,
            apiError: null,
            test: {},
            setIntervalId: "",
            showVars: false,
            appDescriptionClass: localStorage.getItem("appDescriptionClass"),
            envCheckBoxes: {}
        };
        this.myRef = React.createRef();

    }


    ////////////////////////////////////////////////////
	// COMPONENT DID AND WILL MOUNT
	////////////////////////////////////////////////////
    componentDidMount() {
        checkAuthToken()
        // need to make the initial call to getData() to populate
        // data right away
        this.getData();
        this.getDomains();
        // Now we need to make it run at a specified interval
        var setIntervalId = setInterval(this.getData, refreshInterval); // runs every 5 seconds.
        this.setState({
            setIntervalId: setIntervalId
        })
    }

    componentWillUnmount() {
        clearInterval(this.state.setIntervalId)
    }


    ////////////////////////////////////////////////////
	// DEFINE FUNCTION getData
	////////////////////////////////////////////////////
	getData = async () => {
        await fetch(`${sysgridApiUrl}/templates`, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
            }
        })
            .then((res) => {
                if ( res.status != "200") {
                    throw res.statusText
                }
                else {
                    return res.json()
                }
            })
            .then(async (json) => {
                //json to array for every object in object enVariables
                var envVariablesArray = json2array(json.envVariables)
                var categorys = []
                
                for (let i = 0; i < envVariablesArray.length; i++) {
                    if (envVariablesArray[i]["# categoryName"] != undefined && !categorys.includes(envVariablesArray[i]["# categoryName"])) {
                        categorys.push(envVariablesArray[i]["# categoryName"]);
                    }
                    if (envVariablesArray[i]["# imageName"] != undefined) {
                        var tags = await this.getDockerHubTags(envVariablesArray[i]["# imageName"]);
                        if (tags && tags.length > 0) {
                            envVariablesArray[i]["# tagNames"] = tags;
                        }
                    }
                }
                console.log(envVariablesArray)
                this.setState({
                    items: json.templates,
                    envVariablesArray: json2array(json.envVariables),
                    envVariables: json.envVariables,
                    categorys: categorys,
                    logos: json.logos,
                    DataisLoaded: true
                })

            })
            .catch( err => {
                this.setState({
                    DataisLoaded: false,
                })
            })
            this.setState({
                    rolloutStarted: false,
                    rolloutError: false,
            })
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getDomains
    ////////////////////////////////////////////////////
    getDomains = async () => {
        await fetch(`${sysgridApiUrl}/domains/list`, {
                  mode: 'cors',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `${apiToken}`,
                      "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                  }
                })
                .then((res) => {
                    if ( res.status != "200") {
                        throw res.statusText
                    }
                    else {
                        return res.json()
                    }
                })
                .then((json) => {
                    this.setState({
                        domains: json,
                        DataisLoaded: true,
                        apiError: null
                    })
                })
                .catch( err => {
                    this.setState({
                        DataisLoaded: false,
                        apiError: err.toString()
                    })
                })
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getDockerHubTags
    ////////////////////////////////////////////////////
    getDockerHubTags = async (imageName) => {
        return await fetch(`${sysgridApiUrl}/templates/getTags`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
            },
            body: JSON.stringify({
                "imageName": imageName
            })
        })
        .then((res) => {
            if (res.status !== 200) {
                throw res.statusText;
            } else {
                return res.json();
            }
        })
        .then((json) => {
            const amd64Tags = json.results
                .filter(result => result.images[0].architecture === "amd64")
                .map(result => result.name);
            return amd64Tags;
        })
        .catch(err => {
            console.log(err);
        });
    };
    
    

    ////////////////////////////////////////////////////
    // DEFINE FUNCTION updateStore
    ////////////////////////////////////////////////////
    updateStore = async () => {
        const { t } = this.props

        notify("promise", {loading: `${t("Update AppStore")}`}, {}, async() => {
            return await fetch(`${sysgridApiUrl}/templates/updatestore`, {
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${apiToken}`,
                    "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                }
                })
                .then((res) => {
                    if ( res.status != "200") {
                        throw res.statusText
                    }
                    else {
                        return res.json()
                    }
                })
                .then(res => {
                    if ( res.error ) {
                        return({type: "error", message: `${t("Error Updating Store")}\nError:\n${res.error}`})
                    }
                    else if ( res.message ) {
                        this.getData()
                        return({type: "success", message: `${t("Store updated sucessfully")}`})
                    }
                })
                .catch((err) => {
                    console.log(err)
                    return({type: "error", message: `${t("Error Installing App")}\nError:\n${err}`})
                })
        })       
    }

    ////////////////////////////////////////////////////
	// DEFINE FUNCTION handleSubmit
	////////////////////////////////////////////////////
    handleSubmit = item => event => {
        event.preventDefault();
        var jsonVariables = {}
        var jsonResult = {}
        var fetchURL=`${sysgridApiUrl}/templates`

        let projectID = ""

        const { t } = this.props

        for (var element of event.target.elements) {
            var key = element.name
            var value = element.value
            if (key == "projectID") {
                projectID = value
            }
        }

        notify("promise", {loading: `${t("Install App")}: ${projectID} (${item})`}, {}, () => {
            if (event.nativeEvent.submitter.name == "install") {
                var fetchURL=`${sysgridApiUrl}/templates`
            }
            else if (event.nativeEvent.submitter.name == "update") {
                var fetchURL=`${sysgridApiUrl}/templates/update`
            }

            if(event.nativeEvent.submitter.name == "install" || event.nativeEvent.submitter.name == "update") {
                for (var element of event.target.elements) {
                    var key = element.name
                    var value = element.value

                    if(element.className === "input" && key.startsWith("value_")){
                        if(this.state.envCheckBoxes[item] && this.state.envCheckBoxes[item][["checkbox__" + key.split("__")[1]]] && this.state.envCheckBoxes[item]["checkbox__" + key.split("__")[1]] == true) {
                            if( element.name.toLowerCase().includes("vpn_domain") ){
                                for(let domain of this.state.domains){
                                    if( element.value.split('.').slice(-2).join('.').toLowerCase() == domain.secondLevelDomain.toLowerCase() + "." + domain.topLevelDomain.toLowerCase() ) {
                                        const errorMessage = t("VPN Domain and Top Level Domain must not be the same as an Domain configured in the System")
                                        return({type: "error", message: errorMessage})
                                    }
                                }
                            }
                            jsonVariables[`${key}`] = value.toString()
                        }
                    }
                    else if (element.className === "input" && !key.startsWith("value_")) {
                        var value = element.value
                        jsonVariables[`${key}`] = value.toString()
                    }
                    else if (element.className === "input" && !key.startsWith("# templateName")) {
                        var value = element.value
                        jsonVariables[`${key}`] = value.toString()
                    }
                    else if (element.className === "checkbox") {
                        var key = element.name
                        var value = element.value
                        jsonVariables[`${key}`] = this.state.envCheckBoxes[item] && this.state.envCheckBoxes[item][["checkbox__" + key.split("__")[1]]] && this.state.envCheckBoxes[item]["checkbox__" + key.split("__")[1]] == true
                    }
                    else if (element.className === "templatesFormSubdomain") {
                            var subdomain = element.value
                            var domain = ""
                            var key = element.name

                            for (var element of event.target.elements) {
                                if (element.className === "templatesFormDomain") {
                                    domain = element.value
                                }
                            }
                            let value
                            if(subdomain == ""){
                                value = domain

                            }
                            else {
                                value = subdomain + "." + domain
                            }

                            jsonVariables[`${key}`] = value
                    }
                    else if (element.className === "templatesFormAppVersion") {
                        var tagName = element.value
                        var key = element.name

                        jsonVariables[`${key}`] = tagName
                        console.log("tagName")
                        console.log(tagName)
                    }
                }
                jsonVariables["# templateName"] = item
                jsonVariables["# appVersion"] = this.state.envVariables[`${item}`]["# appVersion"]
                jsonVariables["# categoryName"] = this.state.envVariables[`${item}`]["# categoryName"]
                jsonVariables["# categoryColor"] = this.state.envVariables[`${item}`]["# categoryColor"]

                jsonResult[`${item}`] = jsonVariables

                try {
                    return fetch(`${fetchURL}`, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `${apiToken}`,
                            "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                        },
                        body: JSON.stringify({
                            "templateName": item,
                            "envVariables": jsonVariables,
                            "logo": this.state.logos[`${item}`]
                        })
                    })
                    .then((res) => {
                        return res.json()
                    })
                    .then(res => {
                        if ( res.error ) {
                            return({type: "error", message: `${t("Error Installing App")}: ${item}\nError:\n${res.error}`})
                        }
                        else if ( res.message ) {
                            return({type: "success", message: `${t("App installed")}: ${projectID} (${item})`})
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        return({type: "error", message: `${t("Error Installing App")}: ${projectID} (${item})\nError:\n${err}`})
                    })
                }
                catch (e) {
                    console.log(e)
                    return({type: "error", message: `${t("Error Installing App")}: ${projectID} (${item})\nError:\n${e}`})
                }
            }
        })
    }

    ////////////////////////////////////////////////////
	// DEFINE FUNCTION triggerShowAppDescription
	////////////////////////////////////////////////////
    triggerShowAppDescription = () => {
        const appDescriptionClass = localStorage.getItem("appDescriptionClass")

        if(appDescriptionClass == null) {
            localStorage.setItem("appDescriptionClass", "hide")
            this.setState({"appDescriptionClass": "hide"})
        }
        else if(appDescriptionClass == "hide") {
            localStorage.setItem("appDescriptionClass", "")
            this.setState({"appDescriptionClass": ""})
        }
        else if (appDescriptionClass == "") {
            localStorage.setItem("appDescriptionClass", "hide")
            this.setState({"appDescriptionClass": "hide"})
        }

    }

    ////////////////////////////////////////////////////
  	// showVars Function
  	////////////////////////////////////////////////////
    // showVars = () => {
    //     if(this.state.showVars) {
    //       this.myRef.style="display: none"
    //         this.setState({
    //           showVars: false
    //         })
    //     }
    //     else if(!this.state.showVars) {
    //       this.myRef.style="display: block"
    //       this.setState({
    //         showVars: true
    //       })        }
    // }

    ////////////////////////////////////////////////////
   	// RENDER COMPONENT
   	////////////////////////////////////////////////////
    render() {
        const { DataisLoaded, apiError, items, categorys, domains, appDescriptionClass } = this.state;
        const { t } = this.props

        if (!DataisLoaded && (apiError !== null)) {
            return (
                <div>
                    <h1> Error while retrieving data from API:</h1>
                    <h2>{apiError}</h2>
                </div>
            )
        }

        if (!DataisLoaded && (apiError === null)) {
            return (
                <div className="loaderContainer">
                    <div className="loaderArea1">
                        <TransverseLoading />
                    </div>
                </div>
            )
        }

        return (
            <>
                <wc-toast></wc-toast>
                <IntroBoxHeading>
                    {t("Informations about this Menupoint")}
                </IntroBoxHeading>
                <IntroBoxContent>
                    {t("This is your AppStore, from where you can install new Software/Webapps with one click.")}
                </IntroBoxContent>

                <div className="headingPanelWithButton">
                    <h2 className={'headingLg'}><FontAwesomeIcon icon={faAppStore} />  {t("App Store")}</h2>

                    {
                        appDescriptionClass == "" 
                        ?
                        (<button className="buttonBlue" onClick={() => this.triggerShowAppDescription()}>Hide App Description</button>)
                        :
                        appDescriptionClass == "hide"  
                        ?
                        (<button className="buttonBlue" onClick={() => this.triggerShowAppDescription()}>Show App Description</button>)
                        :
                        appDescriptionClass == null 
                        ?
                        (<button className="buttonBlue" onClick={() => this.triggerShowAppDescription()}>Hide App Description</button>)
                        : 
                        null
                    }
                </div>
                <div className="spacer1rem"></div>
                <div className="panel">
                    {t("Filter by Category")}
                    <select name="categoryFilter" id="categoryFilter" onChange={(e) => {
                            this.setState({"selectedFilterCategory": e.target.value});
                        }
                    }>
                        <option value="allCategorys">{t("All")}</option>

                        {categorys.map((category) => (
                            <option value={category}>{category}</option>
                        ))}
                    </select>
                    &nbsp; &nbsp;
                    {t("Search")}
                    <input placeholder="Search" onChange={(e) => {this.setState({"searchTerm": e.target.value})}}></input>
                    <button className="buttonBlue floatRight" onClick={() => this.updateStore()}><FontAwesomeIcon icon={faSyncAlt} /> Update Store</button>
                </div>

                <div className="templatesContainer">
                    {items.map((item) => {
                        if (this.state.selectedFilterCategory === this.state.envVariables[item]['# categoryName'] && (this.state.searchTerm == undefined || item.toLowerCase().includes(this.state.searchTerm.toLowerCase()))) {
                            return(
                                <>
                                    <div className="templatesArea1 panel">
                                        <div className="templatesArea1Area1">
                                            <div className="badge" style={{backgroundColor: `${this.state.envVariables[item]['# categoryColor']}`, color: `${this.state.envVariables[item]['# categoryTextColor']}`}}>{this.state.envVariables[item]['# categoryName']}</div>
                                        </div>
                                        <div className="templatesArea1Area2">
                                            <img className="templateLogo" src={`data:image/png;base64,${this.state.logos[`${item}`]}`} alt=""/>
                                        </div>
                                        <div className="templatesArea1Area3">
                                            <h3>{item}</h3>
                                        </div>
                                        <div className="templatesArea1Area4">
                                            <p className={`${appDescriptionClass}`}>{this.state.envVariables[`${item}`]["# appDescription" + "_" + i18next.language] ? <FontAwesomeIcon icon={faInfoCircle} /> : ""}&nbsp;
                                            {this.state.envVariables[`${item}`]["# appDescription" + "_" + i18next.language]}</p>
                                        </div>
                                        <button className="buttonGreen buttonInstall"  onClick={() => document.getElementById(`${item}`).className = "modal"}>{t("Install")}</button>
                                    </div>
                                    <div id={item} className="hide">
                                        <div className="modalInner">
                                            <form spellcheck="false" className="templatesForm" onSubmit={this.handleSubmit(`${item}`)}>
                                                <div className="spacer20px"></div>

                                                <h2>{item} {t("Configuration")}</h2>

                                                <div className="spacer20px"></div>
                                                <p>{this.state.envVariables[`${item}`]["# appDescription" + "_" + i18next.language] ? <FontAwesomeIcon icon={faInfoCircle} /> : ""}&nbsp;
                                                {this.state.envVariables[`${item}`]["# appDescription" + "_" + i18next.language]}</p>

                                                <div className="spacer20px"></div>
                                                {Object.keys(this.state.envVariables[`${item}`]).map((keyName, i) => (
                                                    (keyName.toLowerCase().split("__")[0] === "domain" ?
                                                        <>
                                                                {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                    <>
                                                                        <Tooltip style="display: inline">
                                                                            {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                        </Tooltip>
                                                                    </>
                                                                    :
                                                                    <></>
                                                                }
                                                                <input className="templatesFormSubdomain" name={`${keyName}`} placeholder="Subdomain"/>
                                                                .<select key={`${item}_${keyName}`} name={`${keyName}`} className="templatesFormDomain">
                                                                    {domains.map((domain) => (
                                                                        <option key={`${item}_${keyName}`} name={`${keyName}`} value={domain.secondLevelDomain != "" ? domain.secondLevelDomain + "." + domain.topLevelDomain : domain.topLevelDomain} className="templatesFormDomainDropDownContent">{domain.secondLevelDomain}.{domain.topLevelDomain}</option>
                                                                    ))}
                                                                </select>
                                                        </>
                                                    :
                                                        keyName.toLowerCase().startsWith("checkbox__") ?
                                                            <>
                                                                {!keyName.toLowerCase().startsWith("#") ?
                                                                    <>
                                                                        <div>
                                                                            {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                                <>
                                                                                    <Tooltip style="display: inline">
                                                                                        {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                                    </Tooltip>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                            }
                                                                            <label htmlFor={`${item}_${keyName}`}>
                                                                                {keyName.replace("checkbox__", "").replace(/_/g, " ")}: 
                                                                            </label>
                                                                            <input onClick={
                                                                                () => {
                                                                                    this.setState(prevState => ({
                                                                                        envCheckBoxes: {
                                                                                            ...prevState.envCheckBoxes,
                                                                                            [item]: {
                                                                                                ...prevState.envCheckBoxes[item],
                                                                                                [keyName]: this.state.envCheckBoxes[item] && this.state.envCheckBoxes[item][keyName] ? false : true
                                                                                            } 
                                                                                        }
                                                                                    }))
                                                                                }
                                                                            } 
                                                                            value={this.state.envCheckBoxes[item] && this.state.envCheckBoxes[item][keyName] || false}
                                                                            checked={this.state.envCheckBoxes[item] && this.state.envCheckBoxes[item][keyName] || false}
                                                                            type="checkbox" key={`${item}_${keyName}`} name={`${keyName}`} className="checkbox" placeholder={keyName.replace(/_/g, " ")}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                :
                                                                <></>
                                                                }
                                                            </>
                                                    :
                                                        this.state.envCheckBoxes[item] && this.state.envCheckBoxes[item][["checkbox__" + keyName.split("__")[1]]] && this.state.envCheckBoxes[item]["checkbox__" + keyName.split("__")[1]] == true && keyName.toLowerCase().startsWith("value_") ?
                                                        <>
                                                            {!keyName.toLowerCase().startsWith("#") ?
                                                                (keyName.toLowerCase().split("__")[2].endsWith("domain") && !keyName.toLowerCase().split("__")[2].endsWith("vpn_domain") ?
                                                                <>
                                                                        {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                            <>
                                                                                <Tooltip style="display: inline">
                                                                                    {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                                </Tooltip>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                        <input className="templatesFormSubdomain" name={`${keyName}`} placeholder="Subdomain"/>
                                                                        .<select key={`${item}_${keyName}`} name={`${keyName}`} className="templatesFormDomain">
                                                                            {domains.map((domain) => (
                                                                                <option key={`${item}_${keyName}`} name={`${keyName}`} value={domain.secondLevelDomain != "" ? domain.secondLevelDomain + "." + domain.topLevelDomain : domain.topLevelDomain} className="templatesFormDomainDropDownContent">{domain.secondLevelDomain}.{domain.topLevelDomain}</option>
                                                                            ))}
                                                                        </select>
                                                                    </>
                                                                :
                                                                    <>
                                                                        <div>
                                                                            {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                                <>
                                                                                    <Tooltip style="display: inline">
                                                                                        {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                                    </Tooltip>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                </>
                                                                            }
                                                                            <input key={`${item}_${keyName}`} name={`${keyName}`} className="input" placeholder={keyName.split("__")[2].replace(/_/g, " ")}/>
                                                                        </div>
                                                                    </>
                                                                )
                                                            :
                                                            <></>
                                                            }
                                                        </>
                                                    :
                                                    this.state.envVariables[`${item}`][`${keyName}`] == "%RANDOM%" ?
                                                        <><input type="hidden" key={`${item}_${keyName}`} name={`${keyName}`} className="input" placeholder={keyName.replace(/_/g, " ")} value={"%RANDOM%"}/></>
                                                    :
                                                    keyName.toLowerCase() == "app_version" && this.state.envVariables[`${item}`][`# tagNames`] ?
                                                        <>
                                                                {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                    <>
                                                                        <Tooltip style="display: inline">
                                                                            {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                        </Tooltip>
                                                                    </>
                                                                    :
                                                                    <></>
                                                                }
                                                                <select key={`${item}_${keyName}`} name={`${keyName}`} className="templatesFormAppVersion">
                                                                    {this.state.envVariables[`${item}`][`# tagNames`].map((tagName) => (
                                                                        <option key={`${item}_${keyName}`} name={`${keyName}`} value={tagName} className="templatesFormAppVersionContent">{tagName}</option>
                                                                    ))}
                                                                </select>
                                                        </>
                                                    :
                                                    !keyName.toLowerCase().startsWith("value_") ?
                                                        <>
                                                            {!keyName.toLowerCase().startsWith("#") ?
                                                                <>
                                                                    <div>
                                                                        {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                            <>
                                                                                <Tooltip style="display: inline">
                                                                                    {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                                </Tooltip>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                            <input key={`${item}_${keyName}`} name={`${keyName}`} className="input" placeholder={keyName.replace(/_/g, " ")} value={this.state.envVariables[`${keyName}`]}/>
                                                                    </div>
                                                                </>
                                                            :
                                                            <></>
                                                            }
                                                        </>
                                                    :
                                                    <></>
                                                    )
                                                ))}
                                                <br/>
                                                <button name="install" className="buttonGreen">{t('Install')}</button>
                                                {/* <button name="update" className="buttonBlue">{t('Reconfigure')}</button> */}
                                                <button className="buttonRed"  onClick={(e) => {e.preventDefault(); document.getElementById(`${item}`).className = document.getElementById(`${item}`).className.replace("modal", "hide")} }>{t("Close")}</button>
                                            </form>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        else if( this.state.selectedFilterCategory == "allCategorys" && (this.state.searchTerm == undefined || item.toLowerCase().includes(this.state.searchTerm.toLowerCase()))) {
                            return(
                                <>
                                    <div className="templatesArea1 panel">
                                        <div className="templatesArea1Area1">
                                            <div className="badge" style={{backgroundColor: `${this.state.envVariables[item]['# categoryColor']}`, color: `${this.state.envVariables[item]['# categoryTextColor']}`}}>{this.state.envVariables[item]['# categoryName']}</div>
                                        </div>
                                        <div className="templatesArea1Area2">
                                            <img className="templateLogo" src={`data:image/png;base64,${this.state.logos[`${item}`]}`} alt=""/>
                                        </div>
                                        <div className="templatesArea1Area3">
                                            <h3>{item}</h3>
                                        </div>
                                        <div className="templatesArea1Area4">
                                            <p className={`${appDescriptionClass}`}>{this.state.envVariables[`${item}`]["# appDescription" + "_" + i18next.language] ? <FontAwesomeIcon icon={faInfoCircle} /> : ""}&nbsp;
                                            {this.state.envVariables[`${item}`]["# appDescription" + "_" + i18next.language]}</p>
                                        </div>
                                        <button className="buttonGreen buttonInstall"  onClick={() => document.getElementById(`${item}`).className = "modal"}>{t("Install")}</button>
                                    </div>
                                    <div id={item} className="hide">
                                        <div className="modalInner">
                                        <form spellcheck="false" className="templatesForm" onSubmit={this.handleSubmit(`${item}`)}>
                                            <div className="spacer20px"></div>

                                            <h2>{item} {t("Configuration")}</h2>
                                            <span>{t("App Version")}: {this.state.envVariables[`${item}`]["# appVersion"]}</span>
                                            <div className="spacer20px"></div>

                                            <p>{this.state.envVariables[`${item}`]["# appDescription" + "_" + i18next.language] ? <FontAwesomeIcon icon={faInfoCircle} /> : ""}&nbsp;
                                            {this.state.envVariables[`${item}`]["# appDescription" + "_" + i18next.language]}</p>
                                            <div className="spacer20px"></div>

                                                {Object.keys(this.state.envVariables[`${item}`]).map((keyName, i) => (
                                                    (keyName.toLowerCase().split("__")[0] === "domain" ?
                                                        <>
                                                            {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                <>
                                                                    <Tooltip style="display: inline">
                                                                        {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                    </Tooltip>
                                                                </>
                                                                :
                                                                <></>
                                                            }
                                                            <input className="templatesFormSubdomain" name={`${keyName}`} placeholder="Subdomain"/>
                                                            .<select key={`${item}_${keyName}`} name={`${keyName}`} className="templatesFormDomain">
                                                                {domains.map((domain) => (
                                                                    <option key={`${item}_${keyName}`} name={`${keyName}`} value={domain.secondLevelDomain != "" ? domain.secondLevelDomain + "." + domain.topLevelDomain : domain.topLevelDomain} className="templatesFormDomainDropDownContent">{domain.secondLevelDomain}.{domain.topLevelDomain}</option>
                                                                ))}
                                                            </select>
                                                        </>
                                                    :
                                                        keyName.toLowerCase().startsWith("checkbox__") ?
                                                            <>
                                                                {!keyName.toLowerCase().startsWith("#") ?
                                                                    <>
                                                                        <div>
                                                                            {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                                <>
                                                                                    <Tooltip style="display: inline">
                                                                                        {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                                    </Tooltip>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                            }
                                                                            <label htmlFor={`${item}_${keyName}`}>
                                                                                {keyName.replace("checkbox__", "").replace(/_/g, " ")}: 
                                                                            </label>
                                                                            <input onClick={
                                                                                () => {
                                                                                    this.setState(prevState => ({
                                                                                        envCheckBoxes: {
                                                                                            ...prevState.envCheckBoxes,
                                                                                            [item]: {
                                                                                                ...prevState.envCheckBoxes[item],
                                                                                                [keyName]: this.state.envCheckBoxes[item] && this.state.envCheckBoxes[item][keyName] ? false : true
                                                                                            } 
                                                                                        }
                                                                                    }))
                                                                                }
                                                                            } 
                                                                            value={this.state.envCheckBoxes[item] && this.state.envCheckBoxes[item][keyName] || false}
                                                                            checked={this.state.envCheckBoxes[item] && this.state.envCheckBoxes[item][keyName] || false}
                                                                            type="checkbox" key={`${item}_${keyName}`} name={`${keyName}`} className="checkbox" placeholder={keyName.replace(/_/g, " ")}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                :
                                                                <></>
                                                                }
                                                            </>
                                                    :
                                                        this.state.envCheckBoxes[item] && this.state.envCheckBoxes[item][["checkbox__" + keyName.split("__")[1]]] && this.state.envCheckBoxes[item]["checkbox__" + keyName.split("__")[1]] == true && keyName.toLowerCase().startsWith("value_") ?
                                                        <>
                                                            {!keyName.toLowerCase().startsWith("#") ?
                                                                (keyName.toLowerCase().split("__")[2].endsWith("domain") && !keyName.toLowerCase().split("__")[2].endsWith("vpn_domain") ?
                                                                    <>
                                                                        {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                            <>
                                                                                <Tooltip style="display: inline">
                                                                                    {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                                </Tooltip>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                        <input className="templatesFormSubdomain" name={`${keyName}`} placeholder="Subdomain"/>
                                                                        .<select key={`${item}_${keyName}`} name={`${keyName}`} className="templatesFormDomain">
                                                                            {domains.map((domain) => (
                                                                                <option key={`${item}_${keyName}`} name={`${keyName}`} value={domain.secondLevelDomain != "" ? domain.secondLevelDomain + "." + domain.topLevelDomain : domain.topLevelDomain} className="templatesFormDomainDropDownContent">{domain.secondLevelDomain}.{domain.topLevelDomain}</option>
                                                                            ))}
                                                                        </select>
                                                                    </>
                                                                :
                                                                    <>
                                                                        <div>
                                                                            {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                                <>
                                                                                    <Tooltip style="display: inline">
                                                                                        {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                                    </Tooltip>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                </>
                                                                            }
                                                                            <input key={`${item}_${keyName}`} name={`${keyName}`} className="input" placeholder={keyName.split("__")[2].replace(/_/g, " ")}/>
                                                                        </div>
                                                                    </>
                                                                )
                                                            :
                                                            <></>
                                                            }
                                                        </>
                                                    :
                                                        this.state.envVariables[`${item}`][`${keyName}`] == "%RANDOM%" ?
                                                            <><input type="hidden" key={`${item}_${keyName}`} name={`${keyName}`} className="input" placeholder={keyName.replace(/_/g, " ")} value={"%RANDOM%"}/></>
                                                    :
                                                        keyName.toLowerCase() == "app_version" && this.state.envVariables[`${item}`][`# tagNames`] ?
                                                        <>
                                                                {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                    <>
                                                                        <Tooltip style="display: inline">
                                                                            {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                        </Tooltip>
                                                                    </>
                                                                    :
                                                                    <></>
                                                                }
                                                                <select key={`${item}_${keyName}`} name={`${keyName}`} className="templatesFormAppVersion">
                                                                    {this.state.envVariables[`${item}`][`# tagNames`].map((tagName) => (
                                                                        <option key={`${item}_${keyName}`} name={`${keyName}`} value={tagName} className="templatesFormAppVersionContent">{tagName}</option>
                                                                    ))}
                                                                </select>
                                                        </>
                                                    :
                                                        !keyName.toLowerCase().startsWith("value_") ?
                                                            <>
                                                                {!keyName.toLowerCase().startsWith("#") ?
                                                                    <>
                                                                        <div>
                                                                            {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`] !== undefined ?
                                                                                <>
                                                                                    <Tooltip style="display: inline">
                                                                                        {this.state.envVariables[`${item}`][`# variableDescription_${i18next.language}_${keyName}`]}
                                                                                    </Tooltip>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                            }
                                                                                <input key={`${item}_${keyName}`} name={`${keyName}`} className="input" placeholder={keyName.replace(/_/g, " ")} value={this.state.envVariables[`${keyName}`]}/>
                                                                        </div>
                                                                    </>
                                                                :
                                                                <></>
                                                                }
                                                            </>
                                                    :
                                                        <></>
                                                    )
                                                ))}
                                                <div className="spacer20px"></div>
                                                <button name="install" className="buttonGreen">{t('Install')}</button>
                                                <button name="update" className="buttonBlue">{t('Reconfigure')}</button>
                                                <button className="buttonRed"  onClick={(e) => {e.preventDefault(); document.getElementById(`${item}`).className = document.getElementById(`${item}`).className.replace("modal", "hide")} }>{t("Close")}</button>
                                            </form>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        
                    })}
                </div>
            </>
    );
}
}

export default withTranslation("translations")(AppStore);
