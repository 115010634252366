////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React from "react";
import { Link } from "react-router-dom";
import { useRef } from 'react';
import { toast } from 'wc-toast'
import { checkAuthToken } from './checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSafari } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faTv, faShieldAlt , faExternalLinkAlt, faPlay, faStop, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';
import ToggleButton from 'terra-toggle-button';
import notify from "./notify.js"
import i18n from './i18n';
import { TransverseLoading } from 'react-loadingg';
import { deleteContainer } from './containerControll';
import { IntroBoxHeading, IntroBoxContent } from './components/IntroBox'
import cheatActivatedSoundFile from './media/cheatActivated.mp3'; // Replace with the correct path
import ConfettiExplosion from 'react-confetti-explosion'
const { displayWidth, displayHeight } = window
var os = require('os');

const isReachable = require('is-reachable');


////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/dockerApi"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const refreshInterval = process.env.REACT_APP_refreshDataInterval || 5000
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"
const t = i18n.t;

////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}

// const deleteContainer = async (Id, projectID, templateName) => {
//   const url = `${apiReverseproxyUrl}/containers/${Id}?force=true`
//
//   try {
//     const result = await fetch(url, {
//                                       method: 'DELETE', // or 'PUT'
//                                       headers: {
//                                           'Authorization': `${apiToken}`,
//                                           "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",                                          "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",                                          'Accept': 'application/json',
//                                           'Content-Type': 'application/json'
//                                       }
//                                   })
//     const logMessage = await result
//     console.log(logMessage)
//     notify("success", t("Container deleted"))
//   } catch (e) {
//     console.log(e)
//   }
// };



const deleteProjectData = async (projectID, templateName) =>  {
    try {
        const result = await fetch(`${sysgridApiUrl}/projects/deleteData`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "projectID": `${projectID}`,
                "templateName": `${templateName}`,
            })
        })
        const logMessage = await result
        return({type: "success", message: `${t("App deleted")}: ${projectID}`})
    } catch (e) {
        console.log(e)
        return({type: "error", message: `${t("App not deleted")}: ${projectID}\nError:\n${e}`})
    }
};

const projectStart = async (projectID, templateName) =>  {
    if(window.confirm(t("Do you really want to stop this app?"))) {
        notify("promise", {loading: `${t("App will be started")}: ${projectID}`}, {}, async () => {
            try {
                const result = await fetch(`${sysgridApiUrl}/project/start`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Authorization': `${apiToken}`,
                        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "projectID": `${projectID}`,
                        "templateName": `${templateName}`,
                    })
                })
                const logMessage = await result
                return({type: "success", message: `${t("App started")}: ${projectID}`})
            } catch (e) {
                console.log(e)
                return({type: "error", message: `${t("App not started")}: ${projectID}\nError:\n${e}`})
            }
        })
    }
    else {
        notify("error", t("App not started"))
    }
};
    
const projectRestart = async (projectID, templateName) =>  {
    if(window.confirm(t("Do you really want to stop this app?"))) {
        notify("promise", {loading: `${t("App will be restarted")}: ${projectID}`}, {}, async () => {
            try {
                const result = await fetch(`${sysgridApiUrl}/project/restart`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Authorization': `${apiToken}`,
                        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "projectID": `${projectID}`,
                        "templateName": `${templateName}`,
                    })
                })
                const logMessage = await result
                return({type: "success", message: `${t("App restarted")}: ${projectID}`})
            } catch (e) {
                console.log(e)
                return({type: "error", message: `${t("App not restarted")}: ${projectID}\nError:\n${e}`})
            }
        })
    }
    else {
        notify("error", t("App not restarted"))
    }
};

const projectStop = async (projectID, templateName) =>  {
    if(window.confirm(t("Do you really want to stop this app?"))) {
        notify("promise", {loading: `${t("App will be stopped")}: ${projectID}`}, {}, async () => {
            try {
                const result = await fetch(`${sysgridApiUrl}/project/stop`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Authorization': `${apiToken}`,
                        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "projectID": `${projectID}`,
                        "templateName": `${templateName}`,
                    })
                })
                const logMessage = await result
                return({type: "success", message: `${t("App stopped")}: ${projectID}`})
            } catch (e) {
                console.log(e)
                return({type: "error", message: `${t("App not stopped")}: ${projectID}\nError:\n${e}`})
            }
        })
    }
    else {
        notify("error", t("App not stopped"))
    }
};

class Apps extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            databaseItems: [],
            dockerItems: [],
            domainsResponseStatusCodes: [],
            DataisLoaded: false,
            templateItems: [],
            templateItemsIsLoaded: false,
            DomainsStatusCodesDataisLoaded: false, 
            apiError: null,
            rolloutStarted: false,
            setIntervalId: "",
            deleteDataStates: {},
            compactMode: false,
            forceUpdateMode: false,
            pressedKeys: "",
            confettiOn: false
        };

 
        const cheatActivatedSound = new Audio(cheatActivatedSoundFile); // Replace with your actual audio file

        const handleKeyPress = (event) => {
            // Check if the pressed keys match "forceupdate"
            const { key } = event;
            
            // Update the pressedKeys state with the new key
            this.setState((prevState) => ({
              pressedKeys: prevState.pressedKeys + key.toLowerCase(),
            }));

            // Check if the pressedKeys sequence matches "forceupdate"
            if (this.state.pressedKeys.toLowerCase().endsWith('forceupdate') && key.toLowerCase() == "e") {
                this.setState({ forceUpdateMode: true });
                this.setState({ pressedKeys: "" });
                cheatActivatedSound.play();
              notify("special", 'CHEAT ACTIVATED: Force Update Mode')
              var defaults = {
                spread: 360,
                ticks: 50,
                gravity: 0,
                decay: 0.94,
                startVelocity: 30,
                colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']
              };
              
              const confettiOn = () => {
                this.setState({ "confettiOn": true });
              }
              
              const confettiOff = () => {
                this.setState({ "confettiOn": false });
              }
              setTimeout(confettiOn, 0);
              setTimeout(confettiOff, 1000);
            }
        };
        window.addEventListener('keypress', handleKeyPress);
    }


    getContainers() {
        fetch(`${dockerApiUrl}/containers/json?all=true`,{
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
            },
        })
        .then((res) => {
            if ( res.status != "200") {
                throw res.statusText
            }
            else {
                return res.json()
            }
        })
        .then((allContainers) => {
            this.setState({
                dockerItems: allContainers,
            })
        })
        .catch( err => {
            this.setState({
                DataisLoaded: false,
            })
        })
    }


    ////////////////////////////////////////////////////
    // COMPONENT DID AND WILL MOUNT
    ////////////////////////////////////////////////////
    componentDidMount() {
        checkAuthToken()
        // need to make the initial call to getData() to populate
        // data right away
        this.getData();
        // Now we need to make it run at a specified interval
        var setIntervalId = setInterval(this.getData, refreshInterval); // runs every 5 seconds.
        this.setState({
            setIntervalId: setIntervalId
        })
        this.setState({
            compactMode: localStorage.getItem("appsCompactMode") || "false"
        })
    }

    componentWillUnmount() {
        clearInterval(this.state.setIntervalId)
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getData
    ////////////////////////////////////////////////////
	getData = async () => {
        await fetch(`${sysgridApiUrl}/projects`, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((res) => {
            if ( res.status != "200") {
                throw res.statusText
            }
            else {
                return res.json()
            }
        })
        .then((array) => {
            //json to array for every object in object enVariables
            this.setState({
                databaseItems: array,
                DataisLoaded: true
            })
        })
        .catch( err => {
            this.setState({
                DataisLoaded: false,
                apiError: err.toString()
            })
        })
        this.setState({
                rolloutStarted: false,
                rolloutError: false,
        })

        await fetch(`${dockerApiUrl}/containers/json?all=true`, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((res) => {
            if ( res.status != "200") {
                throw res.statusText
            }
            else {
                return res.json()
            }
        })
        .then((allContainers) => {

            this.setState({
                dockerItems: allContainers,
            })
        })
        .catch( err => {
            console.log(err)
        })

        let domainsResponseStatusCodes = [];
        this.state.databaseItems.forEach(async (project) => {
            try {
                await Promise.all(project.publicDomains.map(async (domain) => {
                    try {
                        // const response = await fetch(`https://${domain.replace(/"/g, "")}`, { mode: 'no-cors' });
                        const response = await isReachable(`https://${domain.replace(/"/g, "")}`);
                        domainsResponseStatusCodes.push({"domain": domain, "status": response}); // Return a custom value for error status
                    } catch (error) {
                        console.error("Error checking HTTP status:", error);
                        // You can handle errors as needed, for example, return a specific status code or message.
                        domainsResponseStatusCodes.push({"domain": domain, "status": -1}); // Return a custom value for error status
                    }
                }))
                this.setState({
                    domainsResponseStatusCodes: domainsResponseStatusCodes,
                    DomainsStatusCodesDataisLoaded: true,
                })
            } catch (error) {
                this.setState({
                    DomainsStatusCodesDataisLoaded: true,
                })
                console.error("Error checking HTTP status:", error);
            }
        })
    
        await fetch(`${sysgridApiUrl}/templates/`, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((res) => {
            if ( res.status != "200") {
                throw res.statusText
            }
            else {
                return res.json()
            }
        })
        .then((array) => {
            //json to array for every object in object enVariables
            this.setState({
                templateItems: array,
                templateItemsIsLoaded: true
            })
        })
        .catch( err => {
            this.setState({
                DataisLoaded: false,
                apiError: err.toString()
            })
        })
        this.setState({
                rolloutStarted: false,
                rolloutError: false,
        })
        // Check if 'down' property exists in appStatus, assuming it should be 'exited'
    }


    ////////////////////////////////////////////////////
    // RENDER COMPONENT
    ////////////////////////////////////////////////////
    render() {
        const { databaseItems, DataisLoaded, templateItems, templateItemsIsLoaded, DomainsStatusCodesDataisLoaded, apiError } = this.state;
        const { t } = this.props

        if ((!DataisLoaded || !DomainsStatusCodesDataisLoaded || !templateItemsIsLoaded) && (apiError !== null) ) {
            return (
                <div>
                    <h1> {t("Error while retrieving data from API")}:</h1>
                    <h2>{apiError}</h2>
                </div>
            )
        }
        if ((!DataisLoaded || !DomainsStatusCodesDataisLoaded || !templateItemsIsLoaded) && (apiError === null)) {
            return (
                <div className="loaderContainer">
                    <div className="loaderArea1">
                        <TransverseLoading />
                    </div>
                </div>
            )
        }

        const getAppStatus = (projectID, templateName) => {
            var allContainers = []
            var allContainersStates = []
            let appStatus = {
                "created": 0,
                "running": 0,
                "restarting": 0,
                "exited": 0,
                "paused": 0,
                "dead": 0
            }

            if (this.state.dockerItems.length > 0) {
                for (var container of this.state.dockerItems) {
                    if (projectID !== undefined && container.Labels["com.docker.compose.project"] !== undefined && `${projectID}`.replaceAll(".", "").replaceAll(" ", "").toLowerCase() + "_" + `${templateName}`.replaceAll(" ", "").toLowerCase() === container.Labels["com.docker.compose.project"].toLowerCase()) {
                        if (container.State.toLowerCase() === 'created') {
                            appStatus.created += 1;
                        }
                        if (container.State.toLowerCase() === 'running') {
                            appStatus.running += 1;
                        }
                        if (container.State.toLowerCase() === 'restarting') {
                            appStatus.restarting += 1;
                        }
                        if (container.State.toLowerCase() === 'exited') {
                            appStatus.exited += 1;
                        }
                        if (container.State.toLowerCase() === 'paused') {
                            appStatus.paused += 1;
                        }
                        if (container.State.toLowerCase() === 'dead') {
                            appStatus.dead += 1;
                        }
                    }
                }
            }


        
            // Check if 'down' property exists in appStatus, assuming it should be 'exited'
            if (appStatus.created > 0 || appStatus.exited > 0 || appStatus.paused > 0 || appStatus.dead > 0) {
                return (
                    <>
                        <tr className="projectsAppStatus">
                            <td className="statusDotTd"><div className={`statusDotRed`}></div></td>{t("not running")}
                        </tr>
                    </>
                );
            }
            else if (appStatus.restarting > 0) {
                return (
                    <>
                        <tr className="projectsAppStatus">
                            <td className="statusDotTd"><div className={`statusDotYellow`}></div></td>{t("restarting")}
                        </tr>
                    </>
                );
            }
            else if (appStatus.running > 0) {
                return (
                    <>
                        <tr className="projectsAppStatus">
                            <td className="statusDotTd"><div className={`statusDotGreen`}></div></td>{t("running")}
                        </tr>
                    </>
                );
            }
            return null; // Return null if condition is not met
        }
        

        const getContainer = (projectID, templateName) => {
            var allContainers = []
            var allContainersStates = []
            
            if (this.state.dockerItems.length > 0) {
                for (var container of this.state.dockerItems) {
                    if (projectID !== undefined && container.Labels["com.docker.compose.project"] !== undefined && `${projectID}`.replaceAll(".","").replaceAll(" ","").toLowerCase() + "_" + `${templateName}`.replaceAll(" ","").toLowerCase() === container.Labels["com.docker.compose.project"].toLowerCase()) {
                        allContainers.push(container);
                    }
                }
            }
            return(
                <>
                    <table className="projectsContainerlistTable">
                        {allContainers.map((container) => (
                            <tr className="projectsContainerlistContainer">
                                {(container.State.toLowerCase() === 'created' &&
                                    <td className="statusDotTd"><div className={`statusDotRed`}></div></td>
                                )}
                                {(container.State.toLowerCase() === 'running' &&
                                    <td className="statusDotTd"><div className={`statusDotGreen`}></div></td>
                                )}
                                {(container.State.toLowerCase() === 'restarting' &&
                                    <td className="statusDotTd"><div className={`statusDotYellow`}></div></td>
                                )}
                                {(container.State.toLowerCase() === 'exited' &&
                                    <td className="statusDotTd"><div className={`statusDotRed`}></div></td>
                                )}
                                {(container.State.toLowerCase() === 'paused' &&
                                    <td className="statusDotTd"><div className={`statusDotYellow`}></div></td>
                                )}
                                {(container.State.toLowerCase() === 'dead' &&
                                    <td className="statusDotTd"><div className={`statusDotBlack`}></div></td>
                                )}
                                <td>
                                    <Link to={`/container?containerID=${container.Id}&templateName=${templateName}&projectID=${projectID}`}><a>{container.Names[0].substring(1)}</a></Link>
                                </td>
                            </tr>
                        ))}
                    </table>
                </>
            )
        }


        const getWebinterfaceStatuses = (publicDomains) => {
            return (
                <>
                        <table className="projectsDomainsStatusTable">
                            {this.state.domainsResponseStatusCodes.map((domain) => {
                                if(domain.domain !== "" && publicDomains.includes(domain.domain)){
                                    return(
                                        <tr className="projectsDomainsStatus" key={domain.domain}>
                                            <td className="statusDotTd">
                                                <div className={`${domain.status ? 'statusDotGreen' : 'statusDotRed'}`}></div>
                                            </td>
                                            <Link target="_blank" to={`https://${domain.domain.replace(/"/g, "")}`}><a>{domain.domain.replace(/"/g, "")}</a></Link>
                                        </tr>
                                    )
                                }
                            })}
                        </table>
                </>
            );
        };
        

        const deleteProject = (composeProject, projectID, templateName, deleteData) => {
            if(window.confirm(t("Do you really want to delete this app?"))) {
                notify("promise", {loading: `${t("App will be deleted")}: ${projectID}`}, {}, async () => {
                    try {
                        const result = await fetch(`${sysgridApiUrl}/projects/delete`, {
                            method: 'POST', // or 'PUT'
                            headers: {
                                'Authorization': `${apiToken}`,
                                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "projectID": `${projectID}`,
                                "templateName": `${templateName}`,
                                "deleteData": deleteData || false
                            })
                        })
                        const logMessage = await result
                        return({type: "success", message: `${t("App deleted")}: ${projectID}`})
                    } catch (e) {
                        console.log(e)
                        return({type: "error", message: `${t("App not deleted")}: ${projectID}\nError:\n${e}`})
                    }
                })
            }
            else {
                notify("error", t("App not deleted!"))
            }
        }

        const changeDeleteDataState = (projectID, templateName) => {
            let deleteDataStates = this.state.deleteDataStates

            if(Object.keys(deleteDataStates).length < 1 ) {
                deleteDataStates[`${projectID}_${templateName}`] = true
            }
            else if (deleteDataStates[`${projectID}_${templateName}`] == undefined) {
                deleteDataStates[`${projectID}_${templateName}`] = true
            }
            else if (deleteDataStates[`${projectID}_${templateName}`] == true) {
                deleteDataStates[`${projectID}_${templateName}`] = false
            }
            else if (deleteDataStates[`${projectID}_${templateName}`] == false) {
                deleteDataStates[`${projectID}_${templateName}`] = true
            }
            this.setState(prevstate => ({
                ...prevstate,
                deleteDataStates: deleteDataStates
            }))
        }

        const triggerCompactMode = (e) => {
            e.preventDefault()
            if(this.state.compactMode == "false"){
                this.setState({compactMode: "true"})
                localStorage.setItem("appsCompactMode", "true")
            }
            else if(this.state.compactMode == "true"){
                this.setState({compactMode: "false"})
                localStorage.setItem("appsCompactMode", "false")
            }
        }

        const handleUpdateApp = (projectID, templateName) => {
            var fetchURL=`${sysgridApiUrl}/templates/update`
            notify("promise", {loading: `${t("Update App")}: ${projectID} (${templateName})`}, {}, () => {
                try {
                    return fetch(`${fetchURL}`, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `${apiToken}`,
                            "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                        },
                        body: JSON.stringify({
                            "projectID": projectID,
                            "templateName": templateName.replace(/"/g, ""),
                        })
                    })
                    .then((res) => {
                        return res.json()
                    })
                    .then(res => {
                        if ( res.error ) {
                            return({type: "error", message: `${t("Error updating App")}: ${projectID} (${templateName})\nError:\n${res.error}`})
                        }
                        else if ( res.message ) {
                            return({type: "success", message: `${t("App updated")}: ${projectID} (${templateName})`})
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        return({type: "error", message: `${t("Error Installing App")}: ${projectID} (${templateName})\nError:\n${err}`})
                    })
                }
                catch (e) {
                    console.log(e)
                    return({type: "error", message: `${t("Error Installing App")}: ${projectID} (${templateName})\nError:\n${e}`})
                }
            })
        }

        return (
            <>
                <wc-toast></wc-toast>
                <IntroBoxHeading>
                    {t("Informations about this Menupoint")}
                </IntroBoxHeading>
                <IntroBoxContent>
                    {t("Apps which you installed over the AppStore, will be displayed here.")}
                </IntroBoxContent>
                {this.state.confettiOn && <ConfettiExplosion hight={window.innerHeight} width={window.innerWidth}/>}
                <div className="headingPanelWithButton">
                    <h2 className={'headingLg'}><FontAwesomeIcon icon={faTv} />  {t("Apps")}</h2>
                    <button className="buttonBlue" onClick={(e) => triggerCompactMode(e)}>{this.state.compactMode == "true" ? "Deactivate Compact Mode" : "Activate Compact Mode"}</button>
                </div>
                {this.state.compactMode == "true" ? 
                    (
                        <>
                            <div className="projectsCompactContainer">
                                {databaseItems.length > 0 && databaseItems.map((item) => (
                                    <div className="projectsCompactArea1 panel">
                                        <div className="projectsCompactArea1Area1">
                                            <img className="templateLogo" src={`data:image/png;base64,${item.logo}`} alt=""/>
                                        </div>
                                        <div className="projectsCompactArea1Area2">
                                            <h3>{item.projectID && item.projectID.replace(/^"(.*)"$/, '$1')}</h3>
                                            <span>{item.templateName && item.templateName.replace(/^"(.*)"$/, '$1')}</span>
                                        </div>
                                        <div className="projectsCompactArea1Area5">
                                            {item.publicDomains.map((publicDomain) => {
                                                return (
                                                        <a href={`https://${publicDomain && publicDomain.replace(/^"(.*)"$/, '$1')}`} target="_blank"><button className="buttonGreen"><FontAwesomeIcon icon={faSafari} /></button></a>
                                                    )
                                            })}
                                            {item.vpnDomains.length > 0 && item.vpnDomains[0] != "" && item.vpnDomains.map((vpnDomain) => {
                                                return (
                                                    <a href={`http://${vpnDomain && vpnDomain.replace(/^"(.*)"$/, '$1')}`} target="_blank"><button className="buttonGreen"><FontAwesomeIcon icon={faShieldAlt} />   <FontAwesomeIcon icon={faSafari} /></button></a>
                                                )
                                            })}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <div className="projectsContainer">
                                {databaseItems.length > 0 && databaseItems.map((item) => (
                                    <div className="projectsArea1 panel">
                                        <div className="projectsArea1Area1">
                                            <img className="templateLogo" src={`data:image/png;base64,${item.logo}`} alt=""/>
                                        </div>
                                        <div className="projectsArea1Area2">
                                            <h3>{item.projectID && item.projectID.replace(/^"(.*)"$/, '$1')}</h3>
                                        </div>
                                        <div className="projectsArea1Area3">
                                            <span><strong>{t("App Name")}:</strong>&nbsp;{item.templateName && item.templateName.replace(/^"(.*)"$/, '$1')}</span>
                                            <span><strong>{t("App Status")}:</strong>&nbsp;{getAppStatus(item.projectID && item.projectID.replace(/^"(.*)"$/, '$1'), item.templateName && item.templateName.replace(/^"(.*)"$/, '$1'))}</span>
                                            <ToggleButton className="toggleButtonText" isAnimated closedButtonText={t("Webinterface Status")}>
                                                {getWebinterfaceStatuses(item.publicDomains)}
                                            </ToggleButton>
                                            {console.log(item.vpnDomains.length)}
                                            {item.vpnDomains.length > 0 && item.vpnDomains[0] != "" &&
                                                <>
                                                    <ToggleButton className="toggleButtonText" isAnimated closedButtonText={t('VPN Domains')}>
                                                        <table className="projectsDomainsStatusTable">
                                                        {item.vpnDomains.map((vpnDomain) => {
                                                            return (
                                                                <>
                                                                    <tr className="projectsDomainsStatus" key={vpnDomain}>
                                                                        <td className="statusDotTd">
                                                                            {vpnDomain.replace(/"/g, "")}
                                                                        </td>
                                                                    </tr>
                                                                </> 
                                                            )
                                                        })}
                                                        </table>
                                                    </ToggleButton>
                                                </>
                                            }
                                            <ToggleButton className="toggleButtonText" isAnimated closedButtonText={t('App Container')}>
                                                <div className="projectsArea1Area4">
                                                    {getContainer(item.projectID && item.projectID.replace(/^"(.*)"$/, '$1'), item.templateName && item.templateName.replace(/^"(.*)"$/, '$1'))}
                                                </div>
                                            </ToggleButton>
                                            <ToggleButton className="toggleButtonText" isAnimated closedButtonText={t('More')}>
                                                <div className="projectsArea1Area5">
                                                    <div style={{ width: "100%" }}>
                                                        <button className={`buttonGreen containerProjectsButton`} onClick={e => projectStart(item.projectID && item.projectID.replace(/^"(.*)"$/, '$1') ,item.templateName && item.templateName.replace(/^"(.*)"$/, '$1'))}><FontAwesomeIcon icon={faPlay} /></button>
                                                        <button className={`buttonBlue containerProjectsButton`} onClick={e => projectRestart(item.projectID && item.projectID.replace(/^"(.*)"$/, '$1') ,item.templateName && item.templateName.replace(/^"(.*)"$/, '$1'))}><FontAwesomeIcon icon={faSyncAlt } /></button>
                                                        <button className={`buttonRed containerProjectsButton`} onClick={e => projectStop(item.projectID && item.projectID.replace(/^"(.*)"$/, '$1') ,item.templateName && item.templateName.replace(/^"(.*)"$/, '$1'))}><FontAwesomeIcon icon={faStop} /></button>
                                                    </div>
                                                    <div>
                                                    <span>{t("Installed Version")}: {item.appVersion && item.appVersion.replace(/\"/g, "")}</span>
                                                        <br/>
                                                        <span>{t("Available Version")}: {templateItems["envVariables"][item.templateName.replace(/"/g, "")]["# appVersion"]}</span>
                                                        <br/>
                                                        <button onClick={() => handleUpdateApp(item.projectID && item.projectID.replace(/^"(.*)"$/, '$1') ,item.templateName)} disabled={this.state.forceUpdateMode || (item.appVersion && !(templateItems["envVariables"][item.templateName.replace(/"/g, "")]["# appVersion"] <= item.appVersion.replace(/\"/g, ""))) ? false : true} className="buttonBlue">{t("Update App")}</button>
                                                    </div>
                                                    <div className="projectsArea1Area5CheckboxArea" onClick={e => changeDeleteDataState(item.projectID, item.templateName)}>
                                                        <input type="checkbox" id={`${item.projectID && item.projectID.replace(/^"(.*)"$/, '$1')}_${item.templateName && item.templateName.replace(/^"(.*)"$/, '$1')}`} checked={this.state.deleteDataStates[`${item.projectID}_${item.templateName}`]} />
                                                        <label>{t("Delete App Data")}</label>
                                                    </div>
                                                    <button className={`buttonRed containerProjectsButton`} onClick={e => deleteProject(item.projectID && item.projectID.replace(/^"(.*)"$/, '$1') + "_" + item.templateName && item.templateName.replace(/^"(.*)"$/, '$1'), item.projectID && item.projectID.replace(/^"(.*)"$/, '$1'), item.templateName && item.templateName.replace(/^"(.*)"$/, '$1'), this.state.deleteDataStates[`${item.projectID}_${item.templateName}`])}>{t("Delete")}</button>
                                                </div>
                                            </ToggleButton>
                                        </div>
                                        <div className="projectsArea1Area5">
                                            {item.publicDomains.map((publicDomain, index) => (
                                                <a key={`publicDomain_${index}`} href={`https://${publicDomain.replace(/^"(.*)"$/, '$1')}`} target="_blank">
                                                <button className="buttonGreen">{t("Open App")}</button>
                                                </a>
                                            ))}
                                            {item.vpnDomains.length > 0 && item.vpnDomains[0] !== "" && item.vpnDomains.map((vpnDomain, index) => (
                                                <a key={`vpnDomain_${index}`} href={`http://${vpnDomain.replace(/^"(.*)"$/, '$1')}`} target="_blank">
                                                <button className="buttonGreen">
                                                    <FontAwesomeIcon icon={faShieldAlt} /> {t("Open App in VPN")}
                                                </button>
                                                </a>
                                            ))}
                                            </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )
                }
            </>
        );
}
}

export default withTranslation("translations")(Apps);
