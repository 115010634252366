////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import Toggle from "react-toggle";
import { checkAuthToken } from './checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker, faAppStore } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faPlayCircle, faCog, faInfoCircle, faBell, faTv, faUser} from "@fortawesome/free-solid-svg-icons";
import { Popup } from 'semantic-ui-react'
import { toast } from 'wc-toast'
import { withTranslation } from 'react-i18next';
import notify from "./notify";
import { TransverseLoading } from 'react-loadingg';
import { IntroBoxHeading, IntroBoxContent } from './components/IntroBox'
import { NotificationBadge } from '@patternfly/react-core';

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const newsUrl = process.env.newsApiUrl || "https://test.debrit.de/"
const apiToken = process.env.REACT_APP_apiToken || "123"
const showIntro = localStorage.getItem("showIntro");

////////////////////////////////////////////////////
// DEFINE REACT COMPONENTS
////////////////////////////////////////////////////
class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            DataisLoaded: false,
            apiError: null,
        };
    }
	////////////////////////////////////////////////////
	// COMPONENT DID AND WILL MOUNT
	////////////////////////////////////////////////////npm clean
	componentDidMount() {
		checkAuthToken()
        this.getData();
	}


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getData
    ////////////////////////////////////////////////////
    getData = async () => {
        await fetch(`${newsUrl}/wp-json/wp/v2/posts`, {
                  mode: 'cors',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `${apiToken}`,
                  }
                })
                            .then((res) => {
                if ( res.status != "200") {
                    throw res.statusText
                }
                else {
                    return res.json()
                }
            })
            .then((json) => {
                this.setState({
                    news: json,
                    DataisLoaded: true,
                    apiError: null
                })
            })
            .catch( err => {
                this.setState({
                    DataisLoaded: false,
                    apiError: err.toString()
                })
            })
    }


	////////////////////////////////////////////////////
    // RENDER COMPONENT
    ////////////////////////////////////////////////////

	render() {
        const { news, DataisLoaded, apiError } = this.state;
        const { t, i18n } = this.props

        if (!DataisLoaded && (apiError !== null)) {
            return (
                <div>
                    <h1> Currently no news are available</h1>
                    <h2> Error while retrieving data from API:</h2>
                    <h2>{apiError}</h2>
                </div>
            )
        }

        if (!DataisLoaded && (apiError === null)) {
            return (
                <div className="loaderContainer">
                    <div className="loaderArea1">
                        <TransverseLoading />
                    </div>
                </div>
            )
        }

		return (
	        <>
            <wc-toast></wc-toast>
                <IntroBoxHeading>
                    {t("Willkommen")} - {t("Informations about the Menupoints")}
                </IntroBoxHeading>
                <IntroBoxContent>
                    {t("This is Home Page, where you will see an overview of Informations, like last Installed Apps, News from the sysgrid Headquarters and more.")}
                    <br/>
                    <select className="layoutLangSelect" onChange={() => notify("success", t("Do this in the Menubar above to change the language") )} value={i18n.language}>
                        <option value="en-EN">EN</option>
                        <option value="de-DE">DE</option>
                    </select>
                    {t("Here you can set your preffered Language.")}
                    <br/>
                    &nbsp;
                    &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle}/>
                    &nbsp;
                    &nbsp;
                    {t("This Icon will turn the Intro Info Boxes on and off.")}
                    <br/>
                    <NotificationBadge variant="unread" aria-label="First notifications" count="attention" />
                    {t("Here you can see the latest notifications which were shown on the System your are opened the Sysgrid Commander.")}
                    <br/>
                    &nbsp;
                    &nbsp;
                    <FontAwesomeIcon icon={faUser}/>
                    &nbsp;
                    &nbsp;
                    {t("Here you can view and edit your own User Informations and also Logout.")}
                </IntroBoxContent>
                
                <div class="homeContainer">
                      <Link  className='homeArea1 panel' to='/appstore'>
                              <div class="homeArea1Area1">
                                  <Popup
                                    className="infoPopup"
                                    content={t("This is your AppStore, from where you can install new Software/Webapps with one click.")}
                                    mouseEnterDelay={500}
                                    mouseLeaveDelay={500}
                                    on='hover'
                                    trigger={<FontAwesomeIcon icon={faInfoCircle} />}
                                  />
                              </div>
                              <div class="homeArea1Area2">
                                  <FontAwesomeIcon size="3x" icon={faAppStore} />
                                  <span className="areaArea3" >{t('AppStore')}</span>
                              </div>
                      </Link >

                      <Link className='homeArea2 panel' to='/apps'>
                              <div class="homeArea2Area1">
                                  <Popup
                                    className="infoPopup"
                                    content={t("Apps which you installed over the AppStore, will be displayed here.")}
                                    mouseEnterDelay={500}
                                    mouseLeaveDelay={500}
                                    on='hover'
                                    trigger={<FontAwesomeIcon icon={faInfoCircle} />}
                                  />
                              </div>

                              <div class="homeArea2Area2">
                                  <FontAwesomeIcon size="3x" icon={faTv} />
                                  <span className="areaArea3">{t('Apps')}</span>
                              </div>
                      </Link >

                      <Link className='homeArea3 panel' to='/User'>
                              <div class="homeArea3Area1">
                                  <Popup
                                    className="infoPopup"
                                    content={t("Here you can see the latest notifications which were shown on the System your are opened the Sysgrid Commander.")}
                                    mouseEnterDelay={500}
                                    mouseLeaveDelay={500}
                                    on='hover'
                                    trigger={<FontAwesomeIcon icon={faInfoCircle} />}
                                  />
                              </div>
                              <div class="homeArea3Area2">
                                  <FontAwesomeIcon size="3x" icon={faUser} />
                                  <span className="areaArea3">{t('Profile')}</span>
                              </div>
                      </Link >

                      <Link className='homeArea4 panel' to='/admin'>
                              <div class="homeArea4Area1">
                                  <Popup
                                    className="infoPopup"
                                    content={t("This is your Admin Area where you can create Users and Backups and where you can see and configure more details about the System.")}
                                    mouseEnterDelay={500}
                                    mouseLeaveDelay={500}
                                    on='hover'
                                    trigger={<FontAwesomeIcon icon={faInfoCircle} />}
                                  />
                              </div>
                              <div class="homeArea4Area2">
                                  <FontAwesomeIcon size="3x" icon={faCog} />
                                  <span className="areaArea3">{t('Admin')}</span>
                              </div>
                      </Link >
                </div>

                <div class="homeNewsContainer">
                    <div className='homeNewsArea1 panel'>
                        <h2>News</h2>
                    </div>
                    <div className='homeNewsArea2'>
                        {news.map((newsItem) => (
                            <>
                                <div className="homeNewsArea2Panel panel">
                                    <p className="homeNewsArea2Meta">{newsItem.date.replace(/T/g, ' ')}</p>
                                    <h2>{newsItem.title.rendered}</h2>
                                    <div dangerouslySetInnerHTML={{__html: `${newsItem.content.rendered}`}} />
                                </div>
                            </>
                        ))}
                    </div>
                </div>
	        </>
		);
	}
}

export default withTranslation("translations")(Home);
