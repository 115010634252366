////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React from "react";
import { Link } from "react-router-dom";
import { useRef } from 'react';
import { toast } from 'wc-toast'
import { checkAuthToken } from '../checkAuthToken.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faCompactDisc, faTv, faCog, faExternalLinkAlt, faServer } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';
import ToggleButton from 'terra-toggle-button';
import notify from "../notify.js"
import i18n from '../i18n';
import { TransverseLoading } from 'react-loadingg';
import { deleteContainer } from '../containerControll';
import { IntroBoxHeading, IntroBoxContent } from '../components/IntroBox'

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const refreshInterval = process.env.REACT_APP_refreshDataInterval || 5000
const toastDuration = process.env.REACT_APP_toastDuration || 5000
const apiToken = process.env.REACT_APP_apiToken || "123"
const t = i18n.t;

////////////////////////////////////////////////////
// DEFINE FUNCTIONS
////////////////////////////////////////////////////
function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
        result.push(json[key]);
    });
    return result;
}

// const deleteContainer = async (Id, projectID, templateName) => {
//   const url = `${apiReverseproxyUrl}/containers/${Id}?force=true`
//
//   try {
//     const result = await fetch(url, {
//                                       method: 'DELETE', // or 'PUT'
//                                       headers: {
//                                           'Authorization': `${apiToken}`,
//                                           "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",                                          "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",                                          'Accept': 'application/json',
//                                           'Content-Type': 'application/json'
//                                       }
//                                   })
//     const logMessage = await result
//     console.log(logMessage)
//     notify("success", t("Container deleted"))
//   } catch (e) {
//     console.log(e)
//   }
// };

class Backups extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            databaseItems: [],
            dockerItems: [],
            DataisLoaded: false,
            apiError: null,
            rolloutStarted: false,
            setIntervalId: "",
            deleteDataStates: {},
            allBackups: []
        };
    }



    ////////////////////////////////////////////////////
    // COMPONENT DID AND WILL MOUNT
    ////////////////////////////////////////////////////
    componentDidMount() {
        checkAuthToken()
        // need to make the initial call to getData() to populate
        // data right away
        this.getData();
        this.getAllBackups();
        // Now we need to make it run at a specified interval
        // var setIntervalId = setInterval(this.getData, refreshInterval); // runs every 5 seconds.
        // this.setState({
        //     setIntervalId: setIntervalId
        // })
    }

    getAllData = async () => {
        await this.getData();
        await this.getAllBackups();
    }

    componentWillUnmount() {
        clearInterval(this.state.setIntervalId)
    }


    ////////////////////////////////////////////////////
    // DEFINE FUNCTION getData
    ////////////////////////////////////////////////////
	getData = async () => {
        await fetch(`${sysgridApiUrl}/projects`, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((res) => {
            if ( res.status != "200") {
                throw res.statusText
            }
            else {
                return res.json()
            }
        })
        .then((array) => {
            //json to array for every object in object enVariables
            this.setState({
                databaseItems: array,
                DataisLoaded: true
            })
        })
        .catch( err => {
            this.setState({
                DataisLoaded: false,
                apiError: err.toString()
            })
        })
        this.setState({
                rolloutStarted: false,
                rolloutError: false,
        })
    }

      ////////////////////////////////////////////////////
      // DEFINE FUNCTION createBackup
      ////////////////////////////////////////////////////
      getAllBackups = async () => {
        var fetchURL=`${sysgridApiUrl}/backups/list`

        await fetch(fetchURL, {
            headers: {
                'Authorization': `${apiToken}`,
                "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((res) => {
            if ( res.status != "200") {
                throw res.statusText
            }   
            else {
                return res.json()
            }
        })
        .then((array) => {
            //json to array for every object in object enVariables
            this.setState({allBackups: array})
            this.setState({
                AllBackupDataIsLoaded: true,
            })
        })
        .catch( err => {
            this.setState({
                AllBackupDataIsLoaded: false,
                apiError: err.toString()
            })
        })
      }


    ////////////////////////////////////////////////////
	// DEFINE FUNCTION createBackup
	////////////////////////////////////////////////////
    createBackup = item => event => {
        event.preventDefault();
        var jsonVariables = {}
        var jsonResult = {}
        var fetchURL=`${sysgridApiUrl}/backups/create`
        const { t } = this.props
 
        for (var element of event.target.elements) {
            if (element.className.includes("input")) {
                var key = element.name
                var value = element.value
                jsonVariables[`${key}`] = value
            }
        }
        jsonResult[`${item}`] = jsonVariables
 
        try {
            notify("promise", { loading: t("Creating Backup") }, {}, async () => {
                try {
                    const result = await fetch(`${fetchURL}`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `${apiToken}`,
                            'Access-Control-Request-Headers': 'Authorization, Content-Type, Accept',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "projectID": jsonVariables.projectID,
                            "templateName": jsonVariables.templateName,
                            "backupName": jsonVariables.backupName,
                            "comment": jsonVariables.comment,
                        })
                    });
            
                    const responseData = await result.json();
            
                    if (responseData.error) {
                        return { type: "error", message: `${responseData.error}` };
                    } else if (responseData.message) {
                        this.getAllData();
                        return { type: "success", message: `${t("Backup created with ID: ")}${responseData.backupID}` };
                    }
                } catch (error) {
                    return { type: "error", message: `${error}` };
                }
            });
            
        }
        catch (e) {
            console.log(e)
        }
      }


    ////////////////////////////////////////////////////
    // RENDER COMPONENT
    ////////////////////////////////////////////////////
    render() {
        const { databaseItems, DataisLoaded, AllBackupDataIsLoaded, apiError } = this.state;
        const { t } = this.props

        if (!DataisLoaded && !AllBackupDataIsLoaded && (apiError !== null)) {
            return (
                <div>
                    <h1> {t("Error while retrieving data from API")}:</h1>
                    <h2>{apiError}</h2>
                </div>
            )
        }
        if (!DataisLoaded && !AllBackupDataIsLoaded && (apiError === null)) {
            return (
                <div className="loaderContainer">
                    <div className="loaderArea1">
                        <TransverseLoading />
                    </div>
                </div>
            )
        }
        
        ////////////////////////////////////////////////////
        // DEFINE FUNCTION restoreBackups
        ////////////////////////////////////////////////////
        const restoreBackup = (backupID) => {
            var fetchURL=`${sysgridApiUrl}/backups/restore`

            if(window.confirm(t("Do you really want to restore this App from Backup with ID: ") + backupID)) {
                fetch(`${fetchURL}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `${apiToken}`,
                        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                    },
                    body: JSON.stringify({
                        "id": backupID
                    })
                })
                .then((res) => {
                    return res.json()
                })
                .then(res => {
                    if ( res.error ) {
                        notify("error", `${res.error}`)
                    }
    
                    else if ( res.message ) {
                    notify("success", t("Backup successfully restored from Backup with ID: ") + backupID)
                    this.getAllData();
                    }
                })
                .catch((err) => {
                    err = JSON.parse(err)
                    console.log(err)
                    notify("error", `${err.err}`)
                })
            }
            else {
                notify("error", t("Backup NOT restored"))
            }
        }

        ////////////////////////////////////////////////////
        // DEFINE FUNCTION deleteBackups
        ////////////////////////////////////////////////////
        const deleteBackup = (backupID) => {
            var fetchURL=`${sysgridApiUrl}/backups/delete`

            if(window.confirm(t("Do you really want to DELETE the Backup with ID: ") + backupID)) {
                fetch(`${fetchURL}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `${apiToken}`,
                        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                    },
                    body: JSON.stringify({
                        "id": backupID
                    })
                })
                .then((res) => {
                    return res.json()
                })
                .then(res => {
                    if ( res.error ) {
                        notify("error", `${res.error}`)
                    }
    
                    else if ( res.message ) {
                    notify("success", t("Backup successfully deleted"))
                    this.getAllData();
                    }
                })
                .catch((err) => {
                    err = JSON.parse(err)
                    console.log(err)
                    notify("error", `${err.err}`)
                })
            }
            else {
                notify("error", t("Backup NOT deleted"))
            }
        }

        
        ////////////////////////////////////////////////////
        // DEFINE FUNCTION downloadBackup
        ////////////////////////////////////////////////////
        const downloadBackup = (backupID, projectID, templateName) => {
            var fetchURL=`${sysgridApiUrl}/backups/download/${backupID}`
            notify("success", t("Download of Backup successfully started"))

                fetch(`${fetchURL}`, {
                    method: 'GET', // or 'PUT'
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `${apiToken}`,
                        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
                    }
                })
                .then((res) => {
                    return res.blob()
                })
                .then(blob => {
                    if ( blob.error ) {
                        notify("error", `${blob.error}`)
                    }
                    else  {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `${backupID}_${projectID}_${templateName}.tar.gz`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }
                })
                .catch((err) => {
                    console.log(err)
                    err = JSON.parse(err)
                    notify("error", `${err.err}`)
                })
        }

        ////////////////////////////////////////////////////
        // DEFINE FUNCTION getBackups
        ////////////////////////////////////////////////////
        const getBackups = (projectID, templateName) => {
            var allBackups = this.state.allBackups
            var relevantBackups = []

            for (var backup of allBackups) {
                if (backup.projectID.replace(/"/g, "") === projectID.replace(/"/g, "") && backup.templateName.replace(/"/g, "") === templateName.replace(/"/g, "")) {
                    relevantBackups.push(backup);
                }
            }
            return(
                <>
                    <table className="backupsListTable">
                        <tr>
                        <th>{t("ID")}</th>
                        <th>{t("Name")}</th>
                        <th>{t("Comment")}</th>
                        <th>{t("Date")}</th>
                        </tr>
                        {relevantBackups.map((backup) => (
                            <tr className="projectsContainerlistContainerx">
                                    <td>{backup.id}</td>
                                    <td>{backup.backupName}</td>
                                    <td>{backup.comment}</td>
                                    <td>{backup.dateCreated}</td>
                                    <td>
                                        <button className="buttonBlue miniButton" onClick={e => restoreBackup(backup.id)}>Restore</button>
                                        <button className="buttonBlue miniButton" onClick={e => downloadBackup(backup.id, backup.projectID.replace(/"/g, ""), backup.templateName.replace(/"/g, ""))}>Download</button>
                                        <button className="buttonRed miniButton" onClick={e => deleteBackup(backup.id)}>Delete</button>
                                    </td>
                            </tr>
                        ))}
                    </table>
                </>
            )
        }

        return (
            <>
                <wc-toast></wc-toast>
                <IntroBoxHeading>
                    {t("Informations about this Menupoint")}
                </IntroBoxHeading>
                <IntroBoxContent>
                    {t("Here you can create App Backups and Recover Backups for specific Apps.")}
                </IntroBoxContent>

                <div className="panel"><h2 className={'headingLg'}><FontAwesomeIcon icon={faTv} />  {t("Apps")}</h2></div>
                    {databaseItems.length > 0 && databaseItems.map((item) => (
                        <>
                            <div className="backupsContainer panel">
                                        <div className="backupsArea1">
                                            <img className="backupsLogo" src={`data:image/png;base64,${item.logo}`} alt=""/>
                                        </div>
                                        <div className="backupsArea2">
                                            <h3>{item.projectID}</h3>
                                        </div>
                                        <div className="backupsArea3">
                                            <span><strong>{t("App Name")}:</strong>&nbsp;{item.templateName}</span>
                                            <span><strong>{t("Domain")}:</strong>&nbsp;<a href={`https://${item.domains}`}>{`${item.domains}`}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></a></span>
                                        </div>
                                        <div className="backupsArea4">
                                            <div className="backupsArea4CreateBackup">
                                                <ToggleButton className="toggleButtonSmall" isAnimated closedButtonText={t('Create Backup')}>
                                                        <form className="backupForm" onSubmit={this.createBackup(`${item}`)}>
                                                            <input readOnly className="input backupsForm backupsFormHidden" name="projectID" value={item.projectID}/>
                                                            <input readOnly className="input backupsForm backupsFormHidden" name="templateName" value={item.templateName}/>
                                                            <input className="input backupsForm" name="backupName" placeholder={t("Backup Name")}/>
                                                            <input className="input backupsForm" name="comment" placeholder={t("Backup Comment")}/>
                                                            <button name="createBackup" className="buttonBlue">{t('Create Backup')}</button>
                                                        </form>
                                                </ToggleButton>
                                            </div>
                                            &nbsp;
                                            &nbsp;
                                            <div className="backupsArea4RestoreBackup">
                                                <ToggleButton className="toggleButtonSmall" isAnimated closedButtonText={t('Restore Backup')}>
                                                    {getBackups(item.projectID, item.templateName)}
                                                </ToggleButton>
                                            </div>
                                        </div>
                                        
                            </div>
                        </>
                    ))}
            </>

    );
}
}

export default withTranslation("translations")(Backups);
