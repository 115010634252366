////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const dockerApiUrl= process.env.REACT_APP_dockerApiUrl || "http://localhost:9002/apiReverseproxy"
const sysgridApiUrl = process.env.REACT_APP_sysgridApiUrl || "http://localhost:9002/sysgridApi"
const apiToken = process.env.REACT_APP_apiToken || "123"

////////////////////////////////////////////////////
// DEFINE FUNCTION checkAuthToken
////////////////////////////////////////////////////

export async function checkAuthToken() {
	const username = localStorage.getItem("username");
	const authToken = localStorage.getItem('authToken');

	try {
      const data = await fetch(`${sysgridApiUrl}/login/checkAuthToken`, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
		  'Authorization': `${apiToken}`,
		  "Access-Control-Request-Headers": "Authorization, Content-Type, Origin",
		  "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify({"username": username, "authToken": authToken})
      })

      if ( data.message ) {
		window.location.replace(`/`);
      }
      else if ( !data.message && data.authToken) {
          if(data.authToken === authToken) {
              //nothing
          }
		  else if(data.authToken !== authToken) {
            localStorage.clear()
            window.location.replace(`/`);
		  }
      }
	  else if (data.error) {
	  	  window.location.replace(`/`);
		  console.log(data.error)
      }
    } catch (error) {
	  window.location.replace(`/`);
      console.error(error);
    }

    // await fetch(`${sysgridApiUrl}/login/checkAuthToken`, {
	// 	redirect: 'follow',
	// 	headers: {
	// 		'X-Authorization': `Bearer ${apiToken}`,
	// 		'Content-Type': 'application/json',
	// 		'Access-Control-Allow-Origin': '*',
	// 		'Access-Control-Allow-Headers': '*',
	// 		'Access-Control-Expose-Headers': '*',
	// 	},
	//   method: 'POST',
	//   body:  JSON.stringify({"username": username, "authToken": authToken}),
	// })
    // .then(data => data.json())
    // .then(data => {
    //     if ( data.message ) {
	// 		window.location.replace(`/`);
    //     }
    //     else if ( !data.message && data.authToken) {
    //         if(data.authToken === authToken) {
    //             //nothing
    //         }
    //     }
	// 	else if (data.error) {
	// 		console.log(data.error)
	// 	}
    // })
}
